import { Component, OnInit, Input } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FsService } from 'src/app/service/fs.service';
import { Invoices } from 'src/app/shared/dbinterfaces';

@Component({
  selector: 'app-submitted-case-invoice-form',
  templateUrl: './submitted-case-invoice-form.component.html',
  styleUrls: ['./submitted-case-invoice-form.component.css']
})
export class SubmittedCaseInvoiceFormComponent implements OnInit {
  constructor(private route: ActivatedRoute, private fsf: FsService, private router: Router) {}

  @Input() currentIndex: number;
  @Input() clientId: string;
  @Input() currentInvoice: Invoices;
  @Input() caseId: string;

  ngOnInit() {}

  moveInvoiceToInbox() {
    this.fsf.moveDocFromCaseToInbox(this.clientId, this.caseId, this.currentInvoice.id);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
