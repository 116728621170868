<div class="mat-h1">Import Json</div>
<div fxLayout="row" fxLayoutAlign="space-between center">
  <div *ngIf="this.errors.length == 0">
    <textarea id="Text" cols="100" rows="50"></textarea>
  </div>
  <div *ngIf="this.errors.length != 0">
    <h6>Fann ekki eftirfarandi Þjónustuaðila:</h6>

    <div *ngFor="let error of this.errors">{{ error }}<br /></div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-raised-button type="button" color="warning" (click)="cancel()">Hætta við</button>

  <button mat-raised-button type="button" color="primary" (click)="confirm()">Staðfesta</button>
</div>
