import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';
import { FsService } from '../../../service/fs.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Issuer } from 'src/app/shared/dbinterfaces';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-new-issuer',
  templateUrl: './new-issuer.component.html',
  styleUrls: ['./new-issuer.component.css']
})
export class NewIssuerComponent implements OnInit {
  IssuerForm: FormGroup;

  // Form state
  loading = false;
  success = false;
  ktregex = '^[0-9]{6}-[0-9]{4}';

  existingIssuers: Issuer[];
  existingIssuersObs: Observable<any>;
  existingIssuersSub: Subscription;

  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private fsf: FsService, public dialogRef: MatDialogRef<NewIssuerComponent>) {}

  ngOnInit() {
    this.IssuerForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      servicetype: ['', Validators.required],
      issuerid: ['', Validators.compose([this.issuerIdValidator.bind(this), Validators.required, Validators.pattern('^[0-9]{6}-[0-9]{4}')])]
    });
    this.existingIssuersObs = this.fsf.getIssuersCollection();
    this.existingIssuersSub = this.existingIssuersObs.subscribe(res => {
      this.existingIssuers = res;
    });
  }

  issuerIdValidator(control: AbstractControl) {
    if (control.value) {
      for (let index = 0; index < this.existingIssuers.length; index++) {
        const element = this.existingIssuers[index];
        if (element.issuerid == control.value) {
          return { exists: true };
        }
      }
    }
  }

  async submitHandler() {
    this.loading = true;
    let formValue = this.IssuerForm.value;
    formValue.name = String(formValue.name).trim();
    formValue.servicetype = String(formValue.servicetype).trim();
    formValue.email = String(formValue.email).trim();
    try {
      await this.fsf.newIssuer(formValue);
      this.success = true;
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    if (this.loading === false && this.success === true) {
      this.dialogRef.close();
    }
  }
}
