import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UnusableFormComponent } from '../../components/unusable-form/unusable-form.component';
import { FsService } from 'src/app/service/fs.service';
import { Invoices } from 'src/app/shared/dbinterfaces';

@Component({
  selector: 'app-view-unusable-invoice',
  templateUrl: './view-unusable-invoice.component.html',
  styleUrls: ['./view-unusable-invoice.component.css']
})
export class ViewUnusableInvoiceComponent implements OnInit, OnDestroy {

  @ViewChild(UnusableFormComponent)
  private UnusableFormComponent:(UnusableFormComponent)

  clientId: string;
  invoiceId: string;
  currentIndex: number;
  maxIndex: number;
  currentInvoice: Invoices;

  Docs: Invoices[];

  invoiceCollection: Observable<any>;
invoiceCollectionSub: Subscription;
public formMarginTop: string;

  constructor(private route: ActivatedRoute, private fsf: FsService, private router: Router ) {

  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(){
      this.formMarginTop =  String(window.pageYOffset + 50 + 'px')
  }

  ngOnInit() {     this.clientId = this.route.snapshot.paramMap.get('p1');
  this.invoiceId = this.route.snapshot.paramMap.get('p2')



  this.invoiceCollection = this.fsf.getUnusable(this.clientId)
  this.invoiceCollectionSub = this.invoiceCollection.subscribe(res => {
    this.Docs = res;
    this.maxIndex = this.Docs.length;
    if (this.maxIndex == 0) {
      this.router.navigateByUrl('/viewclient/' + this.clientId);
    }
    if (this.currentIndex == this.maxIndex ) {
      this.currentIndex = this.currentIndex - 1 
    }
    if(this.Docs[this.Docs.findIndex(x => x.id == this.invoiceId)] == undefined) {
      this.currentInvoice = this.Docs[this.currentIndex]
    }
    else {
      this.currentInvoice = this.Docs[this.Docs.findIndex(x => x.id == this.invoiceId)];
      this.currentIndex = this.Docs.indexOf(this.currentInvoice);
    }

  })}

  onIndexChange(newIndex) {
    this.currentIndex = newIndex;
    this.currentInvoice = this.Docs[this.currentIndex]
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.invoiceCollectionSub)this.invoiceCollectionSub.unsubscribe();
  }
}
