import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-invoice-controls',
  templateUrl: './invoice-controls.component.html',
  styleUrls: ['./invoice-controls.component.css']
})
export class InvoiceControlsComponent implements OnInit, OnDestroy {
  buttonNextDisabled: boolean;
  buttonPrevDisabled: boolean;
  @Input() Collection: Observable<any>;
  @Input() currentIndex: number;
  @Input() maxIndex: number;

  @Output() numberChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  nextInvoice() {
    this.buttonNextDisabled = true;
    setTimeout(() => {
      this.buttonNextDisabled = false;
    }, 500);

    this.currentIndex = this.currentIndex + 1;
    this.numberChange.emit(this.currentIndex);
  }

  prevInvoice() {
    this.buttonPrevDisabled = true;
    setTimeout(() => {
      this.buttonPrevDisabled = false;
    }, 500);

    this.currentIndex = this.currentIndex - 1;
    this.numberChange.emit(this.currentIndex);
  }

  nextdisabled() {
    if (this.buttonNextDisabled === true || this.currentIndex === this.maxIndex - 1) {
      return true;
    } else return false;
  }

  prevdisabled() {
    if (this.buttonPrevDisabled === true || this.currentIndex === 0) {
      return true;
    }
  }
  ngOnDestroy(): void {}
}
