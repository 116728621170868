import { Component, OnInit, OnDestroy } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { Invoices } from 'src/app/shared/dbinterfaces';
import { Subscription, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, OnDestroy {
  constructor(private fsf: FsService, private afs: AngularFirestore, private http: HttpClient) {}

  clientArray = [];

  clientsSub: Subscription;
  casesSub: Subscription;
  caseInvoicesSub: Subscription;
  newClientsSub: Subscription;

  counter: Observable<any>;

  ngOnInit() {
    this.counter = this.fsf.getHakkavelCount();
  }

  async StartHakkavel() {
    const worklist = this.fsf.getHakkavelWorkList();
    worklist.pipe(take(1)).subscribe(async (res: any) => {
      console.log(res);
      for (const item of res) {
        await this.hakkavel(item.clientId, item.docId, item.id);
      }
    });
  }

  hakkavel(clientId, docId, hakkavelDocId) {
    console.log('hakkavel started on:');
    console.log('clientid: ' + clientId);
    console.log('docid: ' + docId);
    console.log('hakkavelDocId: ' + hakkavelDocId);
    const serverurl = 'https://api.logko.is:5000/';
    const obs = this.fsf.getInboxInvoiceDoc(clientId, docId);
    obs.pipe(take(1)).subscribe((res: Invoices) => {
      const data = { Url: res.downloadUrl };
      const pdfUrl = JSON.stringify(data);
      const sub = this.sendPdf(serverurl, pdfUrl);
      sub.pipe(take(1)).subscribe(res => {
        console.log(res);
        this.fsf.updateInboxInvoiceHakkavel(clientId, docId, res);
        const hakkavelupdate = { done: true };
        this.fsf.updateInvoiceInHakkavelWorkList(hakkavelDocId, hakkavelupdate);
        return 'done';
      });
    });
  }

  sendPdf(serverUrl, pdfUrl): Observable<any> {
    const httpOptions = { reportProgress: true, headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/json', 'Access-Control-Allow-Origin': '*' }) };
    return this.http.post<any>(serverUrl, pdfUrl, httpOptions);
  }

  countInvoices() {
    const test = this.afs.collection('hakkavel', ref => ref.where('done', '==', false)).valueChanges();
    test.pipe(take(1)).subscribe(res => {
      console.log(res);
    });
  }

  fixCaseInfo() {
    const clients = this.fsf.getAllClients('name');

    this.clientsSub = clients.pipe(take(1)).subscribe(res => {
      const allClients = <any>res;
      for (let index0 = 0; index0 < allClients.length; index0++) {
        this.clientArray.push({ clientId: allClients[index0].id, totalInvoices: 0, totalVatAmount: 0 });
      }

      for (let index1 = 0; index1 < allClients.length; index1++) {
        const clientId = allClients[index1].id;
        this.casesSub = this.fsf
          .getCases(clientId)
          .pipe(take(1))
          .subscribe(res => {
            const clientCases = res;
            for (let index2 = 0; index2 < clientCases.length; index2++) {
              const caseId = clientCases[index2].id;
              this.caseInvoicesSub = this.fsf
                .getCaseInvoices(clientId, caseId)
                .pipe(take(1))
                .subscribe((res: Array<any>) => {
                  const allInvoices = res;
                  let InvoiceCount = 0;
                  let VatAmount = 0;
                  for (let index3 = 0; index3 < allInvoices.length; index3++) {
                    const element = allInvoices[index3];
                    InvoiceCount = InvoiceCount + 1;
                    VatAmount = VatAmount + element.iskvat;
                  }
                  const updateData = { totalInvoices: InvoiceCount, totalVatAmount: VatAmount };
                  console.log(updateData);
                  console.log(clientId, caseId);
                  this.fsf.updateCase(clientId, caseId, updateData);
                  this.clientArray[index1].totalInvoices = this.clientArray[index1].totalInvoices + InvoiceCount;
                  this.clientArray[index1].totalVatAmount = this.clientArray[index1].totalVatAmount + VatAmount;
                });
            }
          });
      }
    });
    console.log('done');
  }

  resetClientInfo() {
    const clients = this.fsf.getAllClients('name');
    this.casesSub.unsubscribe();
    this.caseInvoicesSub.unsubscribe();
    this.clientsSub.unsubscribe();

    this.newClientsSub = clients.pipe(take(1)).subscribe(res => {
      const allClients = <any>res;
      for (let index = 0; index < allClients.length; index++) {
        const clientId = allClients[index].id;
        const updateData = { totalInvoices: 0, totalVatAmount: 0 };
        this.fsf.updateClientDoc(clientId, updateData);
      }
    });
    console.log('done');
    console.log(this.clientArray);
  }

  unsubscribe() {
    this.casesSub.unsubscribe();
    this.caseInvoicesSub.unsubscribe();
    this.clientsSub.unsubscribe();
    this.newClientsSub.unsubscribe();
    console.log('unsubscribed');
    console.log(this.clientArray);
  }

  fixClientInfo() {
    const clients = this.fsf.getAllClients('name');
    console.log(this.clientArray.length);

    for (let index = 0; index < this.clientArray.length; index++) {
      const element = this.clientArray[index];
      const clientId = element.clientId;
      console.log(clientId);
      console.log(element.totalInvoices);
      console.log(element.totalVatAmount);
      const Data = { totalInvoices: this.clientArray[index].totalInvoices, totalVatAmount: this.clientArray[index].totalVatAmount };
      console.log(Data);
      this.fsf.updateClientDoc(clientId, Data);
    }
    console.log('done');
  }

  ngOnDestroy() {
    if (this.casesSub) this.casesSub.unsubscribe();
    if (this.caseInvoicesSub) this.caseInvoicesSub.unsubscribe();
    if (this.clientsSub) this.clientsSub.unsubscribe();
    if (this.newClientsSub) this.newClientsSub.unsubscribe();
  }
}
