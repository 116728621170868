<mat-card style="margin-top:30px;">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlex="auto">
      <div fxLayout="row" fxLayoutAlign="start">
        <div *ngIf="TitleImageUrl"><img src="{{ TitleImageUrl }}" /></div>
        <h1 class="mat-h1" style="margin-left:15px; margin-top:5px">{{ Title }}</h1>
      </div>
    </div>
    <div fxFlex="grow" style="text-align:center">
      <mat-form-field style="width:50%">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Leit" />
      </mat-form-field>
    </div>
    <div *ngIf="button" style="margin-top:25px">
      <button color="primary" mat-raised-button (click)="buttonClick()" [disabled]="button.invalid">
        <mat-icon>{{ button.icon }}</mat-icon
        >{{ button.buttontext }}
      </button>
    </div>
  </div>

  <mat-table #table [dataSource]="dataSource" matSort class="animate">
    <ng-container matColumnDef="{{ item.key }}" *ngFor="let item of (displayedColumns | keyvalue)">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ item.value }} </mat-header-cell>

      <mat-cell *matCellDef="let element; let i = index">
        <span *ngIf="isNumber(element[item.key]); else elseBlock"> {{ element[item.key] | number: '1.0-0':'is' }} </span>
        <ng-template #elseBlock> {{ element[item.key] }} </ng-template>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumnsData"></mat-header-row>
    <mat-row (click)="this.rowFunc(row)" *matRowDef="let row; columns: displayedColumnsData; let i = index" ngClass="{'highlight': selectedRowIndex == row.id}"></mat-row> </mat-table
  ><mat-paginator [pageSizeOptions]="sizeOptions" showFirstLastButtons></mat-paginator
></mat-card>
