import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { GapiService } from 'src/app/service/gapi.service';
import { FsService } from 'src/app/service/fs.service';
import { Client } from 'src/app/shared/dbinterfaces';
import * as moment from 'moment';

@Component({
  selector: 'app-view-invalid',
  templateUrl: './view-invalid.component.html',
  styleUrls: ['./view-invalid.component.css']
})
export class ViewInvalidComponent implements OnInit, AfterViewInit, OnDestroy {
  // shared
  clientId: string;
  clientObs: Observable<any>;
  clientObsSub: Subscription;
  client: Client;

  // faults observable
  faults;
  faultsObs: Observable<any>;
  faultsObsSub: Subscription;

  // Default emailText
  defaultEmailText: String;
  defaultEmailTextObs: Observable<any>;
  defaultEmailTextObsSub: Subscription;

  // First table
  invalidTitle = 'Óskráð';
  displayedColumns: { [key: string]: string } = {
    path: 'Skrá'
  };
  invalidCollection: Observable<any>;
  invalidCollectionSub: Subscription;
  sizeOptions = ['5', '100'];

  // Second table
  invalid2Title = 'Tilbúið til sendingar';
  displayedColumns2: { [key: string]: string } = {
    issuer: 'Seljandi',
    invoicenumber: 'Reikningsnúmer',
    faults: 'Gallar'
  };
  shouldButtonBeDisabled: boolean = true;
  readyForGmailCollection: Observable<any>;
  readyforGmailCollectionSub: Subscription;
  arrayForDraftEmail: Array<any>;
  sizeOptions2 = ['5', '100'];

  // Third table
  invalid3Title = 'Sent';
  displayedColumns3: { [key: string]: string } = {
    issuer: 'Seljandi',
    invoicenumber: 'Reikningsnúmer',
    faults: 'Gallar',
    sentTime: 'Sent',
    replyBeforeDate: 'Frestur'
  };

  dataprocessingSentCollection: Observable<any>;
  dataprocessingSentCollectionSub: Subscription;
  arrayForSentEmail: Array<any>;
  sizeOptions3 = ['5', '100'];

  // Fourth table
  invalid4Title = 'Gagnaöflun - Tókst';
  displayedColumns4: { [key: string]: string } = {
    issuer: 'Seljandi',
    invoicenumber: 'Reikningsnúmer',
    faults: 'Gallar',
    sentTime: 'Sent',
    receivedTime: 'Móttekið'
  };

  dataprocessingSuccessfullCollection: Observable<any>;
  dataprocessingSuccessfullCollectionSub: Subscription;
  arrayFordataprocessingSuccessfullCollection: Array<any>;
  sizeOptions4 = ['5', '100'];

  // Fifth table
  invalid5Title = 'Gagnaöflun - Mistókst';
  displayedColumns5: { [key: string]: string } = {
    issuer: 'Skrá',
    invoicenumber: 'Reikningsnúmer',
    faults: 'Gallar',
    sentTime: 'Sent',
    cancelTime: 'Hætt við'
  };

  dataprocessingUnsuccessfullCollection: Observable<any>;
  dataprocessingUnsuccessfullCollectionSub: Subscription;
  arrayFordataprocessingUnsuccessfullCollection: Array<any>;
  sizeOptions5 = ['5', '100'];

  // optional button

  // Email collective Object
  object;
  array: Array<string> = new Array();

  constructor(private route: ActivatedRoute, public router: Router, public gapi: GapiService, private fsf: FsService) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');

    //Get client -- check if umbod exists and change shouldbuttonbedisabled
    this.clientObs = this.fsf.getClient(this.clientId);
    this.clientObsSub = this.clientObs.subscribe(res => {
      this.client = res;
    });

    // Get faults
    this.faultsObs = this.fsf.getFaultsCollection();
    this.faultsObsSub = this.faultsObs.subscribe(res => {
      this.faults = res;
    });

    // get default text for emails
    this.defaultEmailTextObs = this.fsf.getDefaultEmailText();
    this.defaultEmailTextObsSub = this.defaultEmailTextObs.subscribe(res => {
      this.defaultEmailText = res.defaulttext;
    });

    //setup First table
    this.invalidCollection = this.fsf.getInvoicesFromDatacollection(this.clientId, 'InfoNeeded');
    this.invalidCollectionSub = this.invalidCollection.subscribe(res => {
    });

    // Get data for Second table
    this.readyForGmailCollection = this.fsf.getInvoicesFromDatacollection(this.clientId, 'ReadyForGmail');
    this.readyforGmailCollectionSub = this.readyForGmailCollection.subscribe(res => {
      this.arrayForDraftEmail = res;
    });

    // Get data for third table
    this.dataprocessingSentCollection = this.fsf.getInvoicesFromDatacollectionSent(this.clientId, 'DatacollectionSent');
    this.dataprocessingSentCollectionSub = this.readyForGmailCollection.subscribe(res => {
      this.arrayForSentEmail = [];
      var dupelist = [];
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        if (dupelist.indexOf(element.issuer) == -1) {
          this.arrayForSentEmail.push({ issuer: element.issuer, invoicenumber: element.invoicenumber, faults: element.faults, sentTime: element.sentTime, replyBeforeDate: element.replyBeforeDate });
          dupelist.push(element.issuer);
        }
      }
    });

    // Get data for fourth table
    this.dataprocessingSuccessfullCollection = this.fsf.getInvoicesFromDatacollection(this.clientId, 'DatacollectionSuccessfull');
    this.dataprocessingSuccessfullCollectionSub = this.dataprocessingSuccessfullCollection.subscribe(res => {
      this.arrayFordataprocessingSuccessfullCollection = res;
    });

    // Get data for fifth table
    this.dataprocessingUnsuccessfullCollection = this.fsf.getInvoicesFromDatacollection(this.clientId, 'DatacollectionUnsuccessfull');
    this.dataprocessingUnsuccessfullCollectionSub = this.dataprocessingUnsuccessfullCollection.subscribe(res => {
      this.arrayFordataprocessingUnsuccessfullCollection = res;
    });

    // Gather Observable of clients with ID
  }

  ngAfterViewInit() {}

  table1rowFunc($event) {
    this.router.navigateByUrl('/viewinvalidinvoice/' + this.clientId + '/' + $event.id);
  }

  table2rowFunc($event) {
    this.router.navigateByUrl('/viewdatacollectioninvoice/' + this.clientId + '/' + $event.id);
  }

  table3rowFunc($event) {
    this.router.navigateByUrl('/viewdatacollectionsentinvoice/' + this.clientId + '/' + $event.id);
  }

  table4rowFunc($event) {
    // this.router.navigateByUrl('/viewdatacollectionsentinvoice/' + this.clientId + '/' + $event);
  }

  table5rowFunc($event) {
    // this.router.navigateByUrl('/viewdatacollectionsentinvoice/' + this.clientId + '/' + $event);
  }

  //optional button function
  draftEmails() {
    const umbod = this.client.umbod;
    const clientName = this.client.name;

    this.object = {};

    for (let index = 0; index < this.arrayForDraftEmail.length; index++) {
      const element = this.arrayForDraftEmail[index];
      this.array.push(element.issuer);
    }

    const noDupesArray = this.removeDups(this.array);
    let issueremail = '';

    for (let index = 0; index < noDupesArray.length; index++) {
      const element = noDupesArray[index];
      //  const issuer = element
      //  const issueremail = element.issueremail
      const body = this.createBody(element);
      for (var i = 0; i < this.arrayForDraftEmail.length; i++) {
        if (this.arrayForDraftEmail[i].issuer == element) {
          issueremail = this.arrayForDraftEmail[i].issueremail;
        }
      }

      this.gapi.createDraft(body, issueremail, this.callback, clientName, umbod);
    }
    this.fsf.updateClientNumbers(this.clientId, 'datacollectionReadyForGmail_counter', -Number(this.arrayForDraftEmail.length));
    this.fsf.updateClientNumbers(this.clientId, 'datacollectionSent_counter', Number(this.arrayForDraftEmail.length));
  }

  callback() {}

  get button(): Object {
    if (this.client != undefined && this.arrayForDraftEmail) {
      if (this.client.umbod && this.arrayForDraftEmail.length > 0) this.shouldButtonBeDisabled = false;
    } else {
      this.shouldButtonBeDisabled = true;
    }

    const button = { buttontext: ' Draft á Gmail', icon: 'email', invalid: this.shouldButtonBeDisabled };
    return button;
  }

  createBody(issuername: string) {
    const sentTime = moment(new Date()).format('DD.MM.YYYY');
    const nextMonth = this.addMonths(new Date(), 1);
    const replyShouldBeBefore = moment(nextMonth).format('DD.MM.YYYY');
    const text = this.defaultEmailText;
    var invoices = 'reikningar: \n';
    for (let index = 0; index < this.arrayForDraftEmail.length; index++) {
      const element = this.arrayForDraftEmail[index];
      if (element.issuer == issuername) {
        var faultIcelandic = '';
        for (let index2 = 0; index2 < element.faults.length; index2++) {
          const elemen2 = element.faults[index2];
          faultIcelandic += element.faultsicelandic[index2] + '  ';
        }
        invoices += '\n' + element.invoicenumber + ' - ' + faultIcelandic;
      }

      this.fsf.setDataCollectionEmailAsSent(this.clientId, this.arrayForDraftEmail[index].id, sentTime, replyShouldBeBefore);
    }
    const result = text + invoices;
    return result;
  }

  removeDups(array) {
    var outArray = [];
    array.sort();
    outArray.push(array[0]);
    for (var n in array) {
      if (outArray[outArray.length - 1] != array[n]) {
        outArray.push(array[n]);
      }
    }
    return outArray;
  }

  flatten(range) {
    var results = [];
    var row, column;

    for (row = 0; row < range.length; row++) {
      for (column = 0; column < range[row].length; column++) {
        results.push(range[row][column]);
      }
    }
    return results;
  }

  addMonths(date, count) {
    if (date && count) {
      var m,
        d = (date = new Date(+date)).getDate();

      date.setMonth(date.getMonth() + count, 1);
      m = date.getMonth();
      date.setDate(d);
      if (date.getMonth() !== m) date.setDate(0);
    }
    return date;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.clientObsSub) this.clientObsSub.unsubscribe();
    if (this.invalidCollectionSub) this.invalidCollectionSub.unsubscribe();
    if (this.readyforGmailCollectionSub) this.readyforGmailCollectionSub.unsubscribe();
    if (this.faultsObsSub) this.faultsObsSub.unsubscribe;
    if (this.dataprocessingSentCollectionSub) this.dataprocessingSentCollectionSub.unsubscribe();
    if (this.defaultEmailTextObsSub) this.defaultEmailTextObsSub.unsubscribe();
    if (this.dataprocessingSuccessfullCollectionSub) this.dataprocessingSuccessfullCollectionSub.unsubscribe();
    if (this.dataprocessingUnsuccessfullCollectionSub) this.dataprocessingUnsuccessfullCollectionSub.unsubscribe();
  }
}
