import { Component, OnInit, OnDestroy } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { PDFDocumentFactory, PDFDocumentWriter, StandardFonts, drawText } from 'pdf-lib';
import { AngularFirestore } from '@angular/fire/firestore';
import { Invoices } from 'src/app/shared/dbinterfaces';

@Component({
  selector: 'app-case-pdf-info',
  templateUrl: './case-pdf-info.component.html',
  styleUrls: ['./case-pdf-info.component.css']
})
export class CasePdfInfoComponent implements OnInit, OnDestroy {
  clientId: string;
  caseId: string;
  disableButton: boolean;

  caseDoc;
  caseDocObs: Observable<any>;
  caseDocSub: Subscription;

  clientDoc;
  clientDocObs: Observable<any>;
  clientDocSub: Subscription;

  caseInvoicesDoc;
  caseInovoicesDocObs: Observable<any>;
  caseInvoicesDocSub: Subscription;

  totalAmountVAT: number;
  invoiceCount: number;

  // subscriptions
  createPdfObs: Observable<any>;
  createPdfSub: Subscription;

  getDownloadUrlObs: Observable<any>;
  getDownloadUrlSub: Subscription;

  getDownloadUrl2Obs: Observable<any>;
  getDownloadUrl2Sub: Subscription;

  constructor(private fsf: FsService, private afs: AngularFirestore, private route: ActivatedRoute, private router: Router, private fns: AngularFireFunctions, private storage: AngularFireStorage) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.caseId = this.route.snapshot.paramMap.get('p2');

    this.clientDocObs = this.fsf.getClient(this.clientId);
    this.clientDocSub = this.clientDocObs.subscribe(res => {
      this.clientDoc = res;
    });

    this.caseDocObs = this.fsf.getCase(this.clientId, this.caseId);
    this.caseDocSub = this.caseDocObs.subscribe(res => {
      this.caseDoc = res;
    });

    this.caseInovoicesDocObs = this.fsf.getSortedCaseInvoices(this.clientId, this.caseId);
    this.caseInvoicesDocSub = this.caseInovoicesDocObs.subscribe(res => {
      this.caseInvoicesDoc = res;
      this.invoiceCount = res.length;
      this.totalAmountVAT = 0;
      for (let index = 0; index < this.caseInvoicesDoc.length; index++) {
        const element = this.caseInvoicesDoc[index];
        this.totalAmountVAT = element.iskvat + this.totalAmountVAT;
      }
    });
  }

  async createPdf() {
    this.disableButton = true;
    const pdfsPerSplit = 200;
    let finalArray = [];
    let finalPdfsNumber: number;

    for (let index = 0; index < this.caseInvoicesDoc.length; index++) {
      const element = this.caseInvoicesDoc[index];
      if (element.refnumber == 'Auto') {
        finalArray.push(element);
      }
    }

    if (this.hasDecimal(Number(finalArray.length / pdfsPerSplit))) {
      const pdfsNumber = Math.floor(finalArray.length / pdfsPerSplit);
      finalPdfsNumber = pdfsNumber + 1;
    } else {
      finalPdfsNumber = Number(finalArray.length / pdfsPerSplit);
    }
    if (finalPdfsNumber == 0) {
      const data = { allInvoicesPdfUrl: 'none' };
      this.fsf.updateCaseDoc(this.clientId, this.caseId, data);
    } else {
      let workingOnCombiningInvoicesArray = new Array();
      for (let index = 0; index < finalPdfsNumber; index++) {
        if (finalPdfsNumber - 1 == index) {
          const processedInvoices = index * pdfsPerSplit;
          const remaining = finalArray.length - processedInvoices;
          workingOnCombiningInvoicesArray.push({ index: index, current: 0, total: remaining });
        } else {
          workingOnCombiningInvoicesArray.push({ index: index, current: 0, total: pdfsPerSplit });
        }
      }

      this.fsf.setWorkingOnCombiningInvoices(this.clientId, this.caseId, workingOnCombiningInvoicesArray);

      for (let index = 0; index < finalPdfsNumber; index++) {
        const firstIndex = index * pdfsPerSplit;

        const finalIndex = firstIndex + pdfsPerSplit;
        this.callCloudFunction(finalArray.slice(firstIndex, finalIndex), index);
      }
    }
  }

  hasDecimal(num) {
    return !!(num % 1);
  }

  callCloudFunction(array: Array<Invoices>, Index: number) {
    const callable = this.fns.httpsCallable('createPdf');
    const Obs = callable({ clientName: this.clientDoc.name, clientId: this.clientId, caseId: this.caseId, docs: array, index: Index });
    const Sub = Obs.subscribe(res => {});
  }

  async prepareUmsokn() {
    const pdfDoc = PDFDocumentFactory.create();
    const ref = this.storage.ref('/defaults/default.pdf');
    const formatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0 });

    // used Variables
    const clientName = this.clientDoc.name;
    const clientIdNumber = this.clientDoc.idnumber;
    const address1 = this.clientDoc.address1;
    const address2 = this.clientDoc.address2;
    const address3 = this.clientDoc.address3;
    const country = this.clientDoc.country;
    const invoiceCountString = String(formatter.format(this.invoiceCount));
    const vatAmountString = String(formatter.format(this.totalAmountVAT));
    const periodstart = this.caseDoc.periodstart;
    const periodend = this.caseDoc.periodend;
    const [helveticaRef, helveticaFont] = pdfDoc.embedStandardFont(StandardFonts.Helvetica);
    this.getDownloadUrl2Obs = ref.getDownloadURL();
    this.getDownloadUrl2Sub = this.getDownloadUrl2Obs.subscribe(res => {
      fetch(res).then(async result => {
        const buffer = await result.arrayBuffer();
        const array = await new Uint8Array(buffer);
        const DonorPdfDoc = await PDFDocumentFactory.load(array);
        const Pages = await DonorPdfDoc.getPages();
        pdfDoc.addPage(Pages[0]);
        const pages = pdfDoc.getPages();
        const page = pages[0];
        page.addFontDictionary('Helvetica', helveticaRef);
        const contentStream = pdfDoc.createContentStream(
          drawText(helveticaFont.encodeText(clientName), {
            x: 65,
            y: 665,
            size: 10,
            font: 'Helvetica',
            colorRgb: [0, 0, 0]
          }),
          drawText(helveticaFont.encodeText(clientIdNumber), {
            x: 315,
            y: 665,
            size: 10,
            font: 'Helvetica',
            colorRgb: [0, 0, 0]
          }),
          drawText(helveticaFont.encodeText(address1 + ' - ' + address3), {
            x: 65,
            y: 640,
            size: 10,
            font: 'Helvetica',
            colorRgb: [0, 0, 0]
          }),
          drawText(helveticaFont.encodeText(address2), {
            x: 315,
            y: 640,
            size: 10,
            font: 'Helvetica',
            colorRgb: [0, 0, 0]
          }),
          drawText(helveticaFont.encodeText(country), {
            x: 405,
            y: 640,
            size: 10,
            font: 'Helvetica',
            colorRgb: [0, 0, 0]
          }),
          drawText(helveticaFont.encodeText(invoiceCountString), {
            x: 485,
            y: 550,
            size: 10,
            font: 'Helvetica',
            colorRgb: [0, 0, 0]
          }),
          drawText(helveticaFont.encodeText(periodstart), {
            x: 383,
            y: 503,
            size: 10,
            font: 'Helvetica',
            colorRgb: [0, 0, 0]
          }),
          drawText(helveticaFont.encodeText(periodend), {
            x: 495,
            y: 503,
            size: 10,
            font: 'Helvetica',
            colorRgb: [0, 0, 0]
          }),
          drawText(helveticaFont.encodeText(vatAmountString), {
            x: 485,
            y: 480,
            size: 10,
            font: 'Helvetica',
            colorRgb: [0, 0, 0]
          })
        );
        page.addContentStreams(pdfDoc.register(contentStream));
        const metadata = {
          contentType: 'application/pdf'
        };
        const pdfBytes = PDFDocumentWriter.saveToBytes(pdfDoc);
        const bucket = this.storage.upload('/' + this.clientDoc.name + '/cases/' + this.caseId + ' - umsokn.pdf', pdfBytes, metadata);
        bucket.then(res => {
          const url = res.ref.getDownloadURL();
          url.then(res => {
            const data = { applicationPdfUrl: res };
            this.fsf.updateCase(this.clientId, this.caseId, data);
          });
        });
      });
    });
  }

  dlExcel() {
    var name = this.clientDoc.name + ' - ' + this.caseDoc.periodstart + ' - ' + this.caseDoc.periodend;
    var excelData = [];
    for (var i = 0, len = this.caseInvoicesDoc.length; i < len; i++) {
      excelData[i] = {
        Söluaðili: this.caseInvoicesDoc[i].issuer,
        notkun: 'Endurselt til viðskiptavina',
        Dagsetning: this.caseInvoicesDoc[i].date,
        'Reikningsnr..': this.caseInvoicesDoc[i].invoicenumber,
        Heildarfjárhæð: Number(this.caseInvoicesDoc[i].amount),
        'Heildarfjárhæð Mynt': this.caseInvoicesDoc[i].currency,
        'VSK fjárhæð': Number(this.caseInvoicesDoc[i].vatamount),
        'VSK mynt': this.caseInvoicesDoc[i].vatcurrency,
        'VSK ISK': Number(this.caseInvoicesDoc[i].iskvat)
      };
    }

    this.fsf.createXlsx(excelData, name);
  }

  confirmClosed() {
    this.fsf.moveCaseToClosed(this.clientId, this.caseId);
  }

  ngOnDestroy() {
    if (this.caseDocSub) this.caseDocSub.unsubscribe();
    if (this.clientDocSub) this.clientDocSub.unsubscribe();
    if (this.createPdfSub) this.createPdfSub.unsubscribe();
    if (this.getDownloadUrlSub) this.getDownloadUrlSub.unsubscribe();
    if (this.getDownloadUrl2Sub) this.getDownloadUrl2Sub.unsubscribe();
    if (this.caseInvoicesDocSub) this.caseInvoicesDocSub.unsubscribe();
  }
}
