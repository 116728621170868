<mat-card>
  <mat-card-header>
    <h1>Staðfestar upplýsingar</h1>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="row" fxLayoutAlign="end center">
      <button type="button" mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent>
    <button type="button" mat-menu-item (click)="moveInvoiceToInbox()"><mat-icon>all_inbox</mat-icon>Færa í inbox</button>
    <button type="button" mat-menu-item (click)="moveInvoiceToDatacollection()"><mat-icon>mail_outline</mat-icon>Færa í gagnaöflun</button>

    <mat-divider></mat-divider>

    <button type="button" mat-menu-item (click)="confirmDeletePdfFromUnusable()"><mat-icon>delete</mat-icon>Eyða</button>
  </ng-template>
</mat-menu>
