<div class="container">
  <h2 class="mat-h2">Nýtt mál</h2>

  <form [formGroup]="newCaseForm" (ngSubmit)="submitHandler()">
    <mat-form-field class="item">
      <input
        matInput
        [min]="minDate"
        [max]="maxDate"
        [matDatepickerFilter]="startFilter"
        [matDatepicker]="startDatepicker"
        (dateChange)="dateChanged($event.value)"
        formControlName="periodstart"
        placeholder="Upphafsdagur"
      />
      <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatepicker startView="multi-year"></mat-datepicker>
      <div *ngIf="this.newCaseForm.controls['periodstart'].hasError('exists')"><mat-error>Umsókn á þessari dags. er þegar til</mat-error></div>
    </mat-form-field>

    <mat-form-field class="item">
      <input
        [min]="minDate"
        [max]="maxDate"
        matInput
        [matDatepickerFilter]="endFilter"
        [matDatepicker]="endDatepicker"
        formControlName="periodend"
        placeholder="Lokadagur"
      />
      <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatepicker startView="multi-year"></mat-datepicker>
    </mat-form-field>

    <div class="button-container">
      <button class="submit-button" mat-raised-button color="primary" type="submit" [disabled]="newCaseForm.invalid">
        Staðfesta
      </button>
    </div>
  </form>
</div>
