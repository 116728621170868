<div class="grid-container">
  <h1 class="mat-h1">Stjórnborð</h1>
  <mat-grid-list cols="2" rowHeight="350px">
    <mat-grid-tile [colspan]="2" [rowspan]="1">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
            Samtala Mála
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <div><app-card1></app-card1></div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="auto"><app-card2></app-card2></div>
  </div>
</div>
