import { Component, OnInit, Inject } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Issuer } from 'src/app/shared/dbinterfaces';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-json-import',
  templateUrl: './json-import.component.html',
  styleUrls: ['./json-import.component.css']
})
export class JsonImportComponent implements OnInit {
  constructor(private fsf: FsService, public dialogRef: MatDialogRef<JsonImportComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.caseId = data.caseId;
    this.clientId = data.clientId;
  }

  clientId: string;
  caseId: string;

  errors = [];

  issuerDoc: Issuer[];
  issuerDocObs: Observable<any>;
  issuerDocSub: Subscription;

  ngOnInit() {
    this.issuerDocObs = this.fsf.getIssuersCollection();
    this.issuerDocSub = this.issuerDocObs.subscribe(res => (this.issuerDoc = res));
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    const element = document.getElementById('Text') as HTMLInputElement;
    const json = JSON.parse(element.value);
    this.importJson(json);
  }

  importJson(json: Array<any>) {
    this.errors = [];

    for (let index = 0; index < json.length; index++) {
      json[index].downloadUrl = 'https://firebasestorage.googleapis.com/v0/b/taxreturniceland.appspot.com/o/defaults%2FINVOICE%20STORED%20OFFLINE.pdf?alt=media&token=a597db66-b7c9-4d81-b2bb-df3b00b7ed4c';
      json[index].path = '/defaults/INVOICE STORED OFFLINE.pdf';
      json[index].date = moment(json[index].date).format('DD.MM.YYYY');
      json[index].currency = json[index].vatcurrency;
      json[index].iskvat = Math.floor(Number(json[index].iskvat));
    }

    for (let index = 0; index < json.length; index++) {
      const element = json[index];
      let found = false;
      for (let i = 0; i < this.issuerDoc.length; i++) {
        if (this.issuerDoc[i].name == element.issuer) {
          found = true;
          break;
        }
      }
      if (found == false) {
        this.errors.push(element.issuer);
      }
    }
    if (this.errors.length == 0) {
      for (let index = 0; index < json.length; index++) {
        const element = json[index];
        this.fsf.addInvoiceToCase(this.clientId, this.caseId, element);
      }
      this.dialogRef.close();
    }
  }
}
