import { Component, OnInit, OnDestroy } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { PDFDocumentFactory, PDFDocumentWriter, StandardFonts, drawText } from 'pdf-lib';
import { AngularFirestore } from '@angular/fire/firestore';
import { Invoices } from 'src/app/shared/dbinterfaces';

@Component({
  selector: 'app-case-closed-info',
  templateUrl: './case-closed-info.component.html',
  styleUrls: ['./case-closed-info.component.css']
})
export class CaseClosedInfoComponent implements OnInit {
  clientId: string;
  caseId: string;

  caseInvoicesDoc;
  caseInovoicesDocObs: Observable<any>;
  caseInvoicesDocSub: Subscription;

  totalAmountVAT: number;
  invoiceCount: number;

  caseDoc;
  caseDocObs: Observable<any>;
  caseDocSub: Subscription;

  constructor(private fsf: FsService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.caseId = this.route.snapshot.paramMap.get('p2');

    this.caseDocObs = this.fsf.getCase(this.clientId, this.caseId);
    this.caseDocSub = this.caseDocObs.subscribe(res => {
      this.caseDoc = res;
    });

    this.caseInovoicesDocObs = this.fsf.getSortedCaseInvoices(this.clientId, this.caseId);
    this.caseInvoicesDocSub = this.caseInovoicesDocObs.subscribe(res => {
      this.caseInvoicesDoc = res;
      this.invoiceCount = res.length;
      this.totalAmountVAT = 0;
      for (let index = 0; index < this.caseInvoicesDoc.length; index++) {
        const element = this.caseInvoicesDoc[index];
        this.totalAmountVAT = element.iskvat + this.totalAmountVAT;
      }
    });
  }

  moveCaseBacktoSubmitted() {
    this.fsf.moveCaseBackToSubmitted(this.clientId, this.caseId);
  }

  ngOnDestroy() {
    if (this.caseInvoicesDocSub) this.caseInvoicesDocSub.unsubscribe();
    if (this.caseDocSub) this.caseDocSub.unsubscribe();
  }
}
