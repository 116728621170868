import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FsService } from 'src/app/service/fs.service';
import { DatacollectionFormComponent } from '../../components/datacollection-form/datacollection-form.component';
import { Invoices } from 'src/app/shared/dbinterfaces';

@Component({
  selector: 'app-view-datacollection-invoice',
  templateUrl: './view-datacollection-invoice.component.html',
  styleUrls: ['./view-datacollection-invoice.component.css']
})
export class ViewDatacollectionInvoiceComponent implements OnInit, OnDestroy {

  @ViewChild(DatacollectionFormComponent)
  private DatacollectionFormComponent:(DatacollectionFormComponent)

    clientId: string;
    invoiceId: string;
    currentIndex: number;
    maxIndex: number;
    currentInvoice: Invoices;
  
    Docs: Invoices[];

    invoiceCollection: Observable<any>;
  invoiceCollectionSub: Subscription;
  public formMarginTop: string;

  constructor(private route: ActivatedRoute, private fsf: FsService, private router: Router) {}

  @HostListener('window:scroll', ['$event'])
  handleScroll(){
      this.formMarginTop =  String(window.pageYOffset + 50 + 'px')
  }

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.invoiceId = this.route.snapshot.paramMap.get('p2')



    this.invoiceCollection = this.fsf.getInvoicesFromDatacollection(this.clientId, 'ReadyForGmail')
    this.invoiceCollectionSub = this.invoiceCollection.subscribe(res => {
      this.Docs = res;
      this.maxIndex = this.Docs.length;
      if (this.maxIndex == 0) {
        this.router.navigateByUrl('/viewclient/' + this.clientId);
      }
      if (this.currentIndex == this.maxIndex ) {
        this.currentIndex = this.currentIndex - 1 
      }
      if(this.Docs[this.Docs.findIndex(x => x.id == this.invoiceId)] == undefined) {
        this.currentInvoice = this.Docs[this.currentIndex]
      }
      else {
        this.currentInvoice = this.Docs[this.Docs.findIndex(x => x.id == this.invoiceId)];
        this.currentIndex = this.Docs.indexOf(this.currentInvoice);
      }

    })
  }

  onIndexChange(newIndex) {
    this.currentIndex = newIndex;
    this.currentInvoice = this.Docs[this.currentIndex]
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.invoiceCollectionSub)this.invoiceCollectionSub.unsubscribe();
  }
}
