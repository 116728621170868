import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit, OnDestroy {
  @Input() fileUrl: string;
  @Input() Collection: Observable<any>;
  twopage: boolean;

    // Láta invoiceForm vita að pdf sé skannað eða texti
    @Output() pdfHasText = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    this.twopage = false;
  }

  pageRendered(e) {
    if (e.pageNumber == 1) {
      this.twopage = false;
    }
    if (e.pageNumber == 2) {
      this.twopage = true;
    }
  }

  twopages() {
    return this.twopage;
  }

  textLayerRendered(e: any) {
    if (e.path[0].innerText.length > 1 ) {
    const data =  true
    this.pdfHasText.emit(data)
  } else {
    const data = false
    this.pdfHasText.emit(data)}
  }
  ngOnDestroy(): void {}
}
