<app-client-info></app-client-info>

<div fxLayout="row" fxLayoutAlign="space-evenly stretch" fxFlexFill>
  <div class="item" (click)="openInbox()"><app-drop-zone></app-drop-zone></div>
  <div class="item" (click)="openDataCollection()"><app-data-collection-amount></app-data-collection-amount></div>
  <div class="item" (click)="openUnusable()"><app-unusable-amount></app-unusable-amount></div>
</div>

<app-table
  (parentFunc)="viewCase($event)"
  (parentButtonClick)="NewCase()"
  [TitleImageUrl]="TitleImageUrl1"
  [button]="this.button"
  [sizeOptions]="sizeOptions1"
  [Title]="Title1"
  [Collection]="notSubmittedCasesObs"
  [displayedColumns]="displayedColumns1"
></app-table>

<app-table (parentFunc)="viewSubmittedCase($event)" [sizeOptions]="sizeOptions2" [TitleImageUrl]="TitleImageUrl2" [Title]="Title2" [Collection]="this.submittedCasesObs" [displayedColumns]="displayedColumns2"></app-table>

<app-table (parentFunc)="viewClosedCase($event)" [sizeOptions]="sizeOptions3" [TitleImageUrl]="TitleImageUrl3" [Title]="Title3" [Collection]="this.closedCasesObs" [displayedColumns]="displayedColumns3"></app-table>
