import { Component, OnInit, OnDestroy } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EditClientDocumentsComponent } from '../../pages/edit-client-documents/edit-client-documents.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.css']
})
export class ClientInfoComponent implements OnInit, OnDestroy {
  clientId: string;
  client;
  navigationSub: Subscription;
  clientSub: Subscription;

  constructor(private fsf: FsService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) {
    this.navigationSub = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    // Get client name and details
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.clientSub = this.fsf.getClient(this.clientId).subscribe(res => {
      this.client = res;
    });
  }

  openDialog(data): void {
    const editClientDocumentsDialogRef = this.dialog.open(EditClientDocumentsComponent, {
      data: this.clientId
    });
  }
  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSub) {
      this.navigationSub.unsubscribe();
    }
    if (this.clientSub) this.clientSub.unsubscribe();
  }
}
