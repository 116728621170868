import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FsService } from 'src/app/service/fs.service';
import { Invoices } from 'src/app/shared/dbinterfaces';

@Component({
  selector: 'app-view-case-invoice',
  templateUrl: './view-case-invoice.component.html',
  styleUrls: ['./view-case-invoice.component.css']
})
export class ViewCaseInvoiceComponent implements OnInit, OnDestroy {
  caseId: string;
  clientId: string;
  invoiceId: string;
  currentIndex: number;
  maxIndex: number;
  currentInvoice: Invoices;

  Docs: Invoices[];
  caseCollection: Observable<any>;
  caseCollectionSub: Subscription;

  public formMarginTop: string;

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    this.formMarginTop = String(window.pageYOffset + 50 + 'px');
  }

  constructor(private route: ActivatedRoute, private fsf: FsService, private router: Router) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.caseId = this.route.snapshot.paramMap.get('p3');
    this.invoiceId = this.route.snapshot.paramMap.get('p2');

    this.caseCollection = this.fsf.getCaseInvoices(this.clientId, this.caseId);
    this.caseCollection.subscribe(res => {
      this.Docs = res;
      this.maxIndex = this.Docs.length;
      if (this.maxIndex == 0) {
        this.router.navigateByUrl('/viewclient/' + this.clientId);
      }
      if (this.currentIndex == this.maxIndex) {
        this.currentIndex = this.currentIndex - 1;
      }
      if (this.Docs[this.Docs.findIndex(x => x.id == this.invoiceId)] == undefined) {
        this.currentInvoice = this.Docs[this.currentIndex];
      } else {
        this.currentInvoice = this.Docs[this.Docs.findIndex(x => x.id == this.invoiceId)];
        this.currentIndex = this.Docs.indexOf(this.currentInvoice);
      }
    });
  }

  onIndexChange(newIndex) {
    this.currentIndex = newIndex;
    this.currentInvoice = this.Docs[this.currentIndex];
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.caseCollectionSub) this.caseCollectionSub.unsubscribe();
  }
}
