import { Component, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FsService } from 'src/app/service/fs.service';
import { NewFaultComponent } from '../new-fault/new-fault.component';
import { EditFaultComponent } from '../edit-fault/edit-fault.component';
import { Subscription } from 'rxjs';
import { EditDataCollectionDefaultsComponent } from '../../components/edit-data-collection-defaults/edit-data-collection-defaults.component';

@Component({
  selector: 'app-view-faults',
  templateUrl: './view-faults.component.html',
  styleUrls: ['./view-faults.component.css']
})
export class ViewFaultsComponent implements AfterViewInit, OnDestroy {
  displayedColumns = ['name', 'english', 'icelandic', 'edit'];
  dataSource: MatTableDataSource<any>;
  faultsCollectionSub: Subscription;

  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialog: MatDialog, private router: Router, private fsf: FsService) {}

  ngAfterViewInit() {
    this.faultsCollectionSub = this.fsf.getFaultsCollection().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openDialog(data): void {
    const editDialogRef = this.dialog.open(EditFaultComponent, {
      data: data
    });
  }

  openDialog2(): void {
    const editDialogRef = this.dialog.open(EditDataCollectionDefaultsComponent, {});
  }

  deleteFault(data): void {
    this.fsf.deleteFault(data.id);
  }

  NewFault(): void {
    const newDialogRef = this.dialog.open(NewFaultComponent, {});
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.faultsCollectionSub) this.faultsCollectionSub.unsubscribe();
  }
}
