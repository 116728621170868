<div class="container">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skjöl
          <div *ngIf="this.umbod && this.vatcertificate && this.vatagentauthorisation; else docMissing"></div>
          <ng-template #docMissing
            >&nbsp;&nbsp;&nbsp;
            <mat-icon style="color:rgb(100, 0, 0)">error_outline</mat-icon>
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-list>
        <div *ngIf="this.umbod; else noumbod">
          <mat-list-item> <mat-icon style="color:darkgreen">check_circle_outline</mat-icon> <a download href="{{ this.umbod }}"> Umboð til gagnaöflunar</a> </mat-list-item>
        </div>
        <div *ngIf="this.vatcertificate; else novatcertificate">
          <mat-list-item> <mat-icon style="color:darkgreen">check_circle_outline</mat-icon> <a download href="{{ this.vatcertificate }}"> VAT certificate</a> </mat-list-item>
        </div>
        <div *ngIf="this.vatagentauthorisation; else novatagentauthorisation">
          <mat-list-item>
            <mat-icon style="color:darkgreen">check_circle_outline</mat-icon>
            <a download href="{{ this.vatagentauthorisation }}"> VAT Agent authorisation </a>
          </mat-list-item>
        </div>
      </mat-list>

      <ng-template #noumbod>
        <mat-list-item style="color:rgb(100, 0, 0)"> <mat-icon>error_outline</mat-icon> Umboð til gagnaöflunar </mat-list-item>
      </ng-template>

      <ng-template #novatcertificate>
        <mat-list-item style="color:rgb(100, 0, 0)"> <mat-icon>error_outline</mat-icon> VAT certificate </mat-list-item>
      </ng-template>

      <ng-template #novatagentauthorisation>
        <mat-list-item style="color:rgb(100, 0, 0)"> <mat-icon>error_outline</mat-icon> VAT Agent authorisation </mat-list-item>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
