<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlex="auto"><h1>Viðskiptamannaskrá</h1></div>
    <div fxFlex="grow" style="text-align:center">
      <mat-form-field style="width:80%">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Leit" />
      </mat-form-field>
    </div>
    <div fxFlex="auto" style="margin-top:15px">
      <a (click)="NewClient()">
        <button mat-raised-button color="primary"><mat-icon>person_add</mat-icon> Stofna</button></a
      >
    </div>
  </div>

  <mat-table #table [dataSource]="dataSource" class="animate">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Viðskiptavinur </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="country">
      <mat-header-cell *matHeaderCellDef> Land </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.country }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="inbox_counter">
      <mat-header-cell *matHeaderCellDef> Inbox fjöldi</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.inbox_counter }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" class="rightalign">
        <button type="button" (click)="$event.stopPropagation()" mat-icon-button [matMenuTriggerFor]="appMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #appMenu="matMenu">
          <ng-template matMenuContent>
            <button type="button" mat-menu-item (click)="openDialog(element, $event)"><mat-icon>build</mat-icon>Breyta</button>
            <button type="button" mat-menu-item (click)="openDocsDialog(element)"><mat-icon>assignment_turned_in</mat-icon>Skjöl</button>
          </ng-template>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row (click)="viewClient(row.id)" class="highlight" *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[50, 100]" showFirstLastButtons></mat-paginator>
</mat-card>
