<div fxLayout="column">
  <h2>Breyta galla</h2>
  <form [formGroup]="editFaultForm" (ngSubmit)="submitHandler()">
    <mat-form-field class="item">
      <input matInput placeholder="Nafn" formControlName="name" />
    </mat-form-field>
    <mat-form-field class="item">
      <input matInput placeholder="Enska" formControlName="english" />
    </mat-form-field>

    <mat-form-field class="item">
      <input matInput placeholder="Íslenska" formControlName="icelandic" />
    </mat-form-field>

    <div class="button-container">
      <button class="submit-button" mat-raised-button color="primary" type="submit" [disabled]="editFaultForm.invalid">
        Staðfesta
      </button>
    </div>
  </form>
</div>
