import { Component, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { NewAffiliateComponent } from '../new-affiliate/new-affiliate.component';
import { FsService } from 'src/app/service/fs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-affiliates',
  templateUrl: './view-affiliates.component.html',
  styleUrls: ['./view-affiliates.component.css']
})
export class ViewAffiliatesComponent implements AfterViewInit, OnDestroy {
  // Table stuff
  displayedColumns = ['displayName'];
  dataSource: MatTableDataSource<any>;
  clientCollectionSub: Subscription;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private afs: AngularFirestore, private fsf: FsService, public dialog: MatDialog, private router: Router) {}

  ngAfterViewInit() {
    this.clientCollectionSub = this.fsf.getAffiliatesCollection().subscribe(result => {
      console.log(result);
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  NewAffiliate(): void {
    const newDialogRef = this.dialog.open(NewAffiliateComponent, {});
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
