import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../shared/dbinterfaces'; // optional

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User>;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private router: Router) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        // Logged in
        if (user) {
          console.log('user is logged in');
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          console.log('user is not logged in');
          return of(null);
        }
      })
    );
  }
  async googleSignin() {
    console.log('logging in');
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }

  async signOut() {
    console.log('logging out');
    await this.afAuth.auth.signOut();
    return this.router.navigate(['/']);
  }

  /* Sign up */
  SignUp(email: string, password: string) {
    this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(res => {
        console.log('You are Successfully signed up!', res);
      })
      .catch(error => {
        console.log('Something is wrong:', error.message);
      });
  }

  /* Sign in */
  SignIn(email: string, password: string) {
    this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        console.log('You are Successfully logged in!');
        console.log(this.user$);
      })
      .catch(err => {
        console.log('Something is wrong:', err.message);
      });
  }

  isLoggedin() {
    console.log('signed in');
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        // Logged in
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          return of(null);
        }
      })
    );
  }

  private updateUserData({ uid, email, displayName, photoURL, roles }: User) {
    console.log('updating user data');
    const userRef: AngularFirestoreDocument<User> = this.afs.doc('users/${uid}');

    const data = {
      uid,
      email,
      displayName,
      photoURL,
      roles
    };
    return userRef.set(data, { merge: true });
  }
}
