import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { FsService } from 'src/app/service/fs.service';

@Component({
  selector: 'app-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.css']
})
export class DropZoneComponent implements OnInit, OnDestroy {
  clientId: string;
  clientObs;
  client;
  // Main task
  task: AngularFireUploadTask;
  countDoc: Observable<any>;
  count: number;
  result: boolean;
  working: boolean;

  // Progress monitoring
  percentage: Observable<number>;
  perc: number;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;
  downlURL: string;
  fileSize: number;
  currentnumber: number;

  // State for dropzone CSS toggling
  isHovering: boolean;

  promises = [];

  currentinboxCollection: string;

  //Subscriptions
  clientObsSub: Subscription;

  wrongFileType: boolean;

  constructor(private storage: AngularFireStorage, private afs: AngularFirestore, private route: ActivatedRoute, private fsf: FsService) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.clientObs = this.fsf.getClient(this.clientId);
    this.clientObsSub = this.clientObs.subscribe(res => {
      this.client = res;
    });
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }
  async startUpload(event: FileList) {
    function callback() {}

    var itemsProcessed = 0;

    const totalfiles = event.length;
    const each = 100 / event.length;
    this.currentnumber = each;
    this.perc = 0;

    await Array.from(event).forEach((file, index, array) => {
      if (file.type !== 'application/pdf') {
        console.error('unsupported file type :( ');
        this.wrongFileType = true;

        return;
      }
      const size = file.size;
      // Storage path
      const path = '/' + this.client.name + '/invoices/' + new Date().getTime() + ' - ' + file.name;
      const fileRef = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      // Main Task

      // Progress Monitoring
      this.percentage = task.percentageChanges();
      this.snapshot = task.snapshotChanges();
      task
        .snapshotChanges()
        .pipe(
          // tslint:disable-next-line:max-line-length
          finalize(() => {
            fileRef.getMetadata().subscribe(test => test.fileSize);
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(val => {
              const data = {
                path,
                downloadUrl: val,
                size: size,
                issuer: '',
                servicetype: '',
                invoicenumber: '',
                creditinvoice: false,
                date: '',
                amount: '',
                currency: '',
                vatamount: '',
                vatcurrency: '',
                exchangerate: '',
                issuerid: '',
                issueremail: '',
                issuerdocid: ''
              };
              this.fsf.addInvoiceToInbox(this.clientId, data);
              this.perc = this.perc + each;
            });
          })
        )
        .subscribe();
    });
  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.clientObsSub) this.clientObsSub.unsubscribe();
  }
}
