import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { FsService } from 'src/app/service/fs.service';
import { Invoices } from 'src/app/shared/dbinterfaces';
import { InvoiceFormComponent } from '../../components/invoice-form/invoice-form.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit, OnDestroy {
  @ViewChild(InvoiceFormComponent)
  private InvoiceFormComponent: InvoiceFormComponent;

  clientId: string;
  invoiceId: string;
  currentIndex: number;
  maxIndex: number;

  currentInvoice: Invoices;

  Docs: Invoices[];
  inboxObs: Observable<any>;
  inboxSub: Subscription;

  public formMarginTop: string;
  public pdfHasText: Event;

  // Observables for child components

  constructor(private route: ActivatedRoute, private fsf: FsService, private router: Router) {}

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    this.formMarginTop = String(window.pageYOffset + 'px');
  }

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');

    //only used to propogate the first invoice.
    this.invoiceId = this.route.snapshot.paramMap.get('p2');

    this.inboxObs = this.fsf.getInboxCollection(this.clientId);
    this.inboxSub = this.inboxObs.subscribe((res: Invoices[]) => {
      this.Docs = res;
      this.maxIndex = this.Docs.length;
      if (this.maxIndex == 0) {
        this.router.navigateByUrl('/viewclient/' + this.clientId);
      }
      if (this.currentIndex == this.maxIndex) {
        this.currentIndex = this.currentIndex - 1;
      }
      if (this.Docs[this.Docs.findIndex(x => x.id == this.invoiceId)] == undefined) {
        if (this.currentIndex == undefined) {
          this.currentIndex = 0;
        }
        this.currentInvoice = this.Docs[this.currentIndex];
      } else {
        this.currentInvoice = this.Docs[this.Docs.findIndex(x => x.id == this.invoiceId)];
        this.currentIndex = this.Docs.indexOf(this.currentInvoice);
      }
    });
  }



  pdfHasTextEvent(event:any) {
    this.pdfHasText = event;
  }

  onIndexChange(newIndex) {
    this.currentIndex = newIndex;
    this.currentInvoice = this.Docs[this.currentIndex];
    this.InvoiceFormComponent.resetForm();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.inboxSub) this.inboxSub.unsubscribe();
  }
}
