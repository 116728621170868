import { Component, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { NewClientComponent } from '../new-client/new-client.component';
import { EditClientComponent } from '../edit-client/edit-client.component';
import { Router } from '@angular/router';
import { FsService } from 'src/app/service/fs.service';
import { Subscription } from 'rxjs';
import { EditClientDocumentsComponent } from '../edit-client-documents/edit-client-documents.component';

@Component({
  selector: 'app-view-clients',
  templateUrl: './view-clients.component.html',
  styleUrls: ['./view-clients.component.css']
})
export class ViewClientsComponent implements AfterViewInit, OnDestroy {
  // Table stuff
  displayedColumns = ['name', 'country', 'inbox_counter', 'edit'];
  dataSource: MatTableDataSource<any>;
  clientCollectionSub: Subscription;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private afs: AngularFirestore, private fsf: FsService, public dialog: MatDialog, private router: Router) {}

  ngAfterViewInit() {
    this.clientCollectionSub = this.fsf.getClientsCollection().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openDialog(data, event): void {
    event.stopPropagation();
    const editDialogRef = this.dialog.open(EditClientComponent, {
      data: data
    });
  }

  openDocsDialog(data): void {
    event.stopPropagation();
    const editClientDocumentsDialogRef = this.dialog.open(EditClientDocumentsComponent, {
      data: data.id
    });
  }

  deleteClient(data): void {
    this.afs
      .collection('clients')
      .doc(data.id)
      .delete();
  }

  NewClient(): void {
    const newDialogRef = this.dialog.open(NewClientComponent, {});
  }

  viewClient(id) {
    this.router.navigate(['/viewclient/', id]);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.clientCollectionSub) this.clientCollectionSub.unsubscribe();
  }
}
