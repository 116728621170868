import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FsService } from 'src/app/service/fs.service';
import { Invoices } from '../../../shared/dbinterfaces';
import { registerLocaleData } from '@angular/common';
import localeIs from '@angular/common/locales/is';

@Component({
  selector: 'app-view-case',
  templateUrl: './view-case.component.html',
  styleUrls: ['./view-case.component.css']
})
export class ViewCaseComponent implements OnInit, OnDestroy {
  clientId: string;
  caseId: string;

  // First table
  tableTitle = 'Reikningar';
  displayedColumns: { [key: string]: string } = {
    refnumber: 'Vinnslunúmer',
    issuer: 'Seljandi',
    invoicenumber: 'Reikningsnúmer',
    date: 'Dagsetning',
    iskvat: 'Fjárhæð VSK'
  };
  Collection: Observable<any>;
  CollectionSub: Subscription;
  sizeOptions = ['5', '100'];

  caseInfo;
  caseInfoSub: Subscription;

  Invoices: Invoices[];

  constructor(private route: ActivatedRoute, private fsf: FsService, private router: Router) {
    registerLocaleData(localeIs, 'is');
  }

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.caseId = this.route.snapshot.paramMap.get('p2');

    this.Collection = this.fsf.getCaseInvoices(this.clientId, this.caseId);

    this.CollectionSub = this.Collection.subscribe(result => {
      this.Invoices = result;
    });

    this.caseInfoSub = this.fsf.getCaseInfo(this.clientId, this.caseId).subscribe(res => {
      this.caseInfo = res;
    });
  }

  tablerowFunc(row) {
    this.router.navigateByUrl('/viewcaseinvoice/' + this.clientId + '/' + row.id + '/' + this.caseId);
  }

  ngOnDestroy() {
    if (this.CollectionSub) this.CollectionSub.unsubscribe();
    if (this.caseInfoSub) {
      this.caseInfoSub.unsubscribe();
    }
  }
}
