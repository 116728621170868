import { Component, OnInit, Input } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { Invoices } from 'src/app/shared/dbinterfaces';
import * as moment from 'moment';

@Component({
  selector: 'app-datacollectionsent-form',
  templateUrl: './datacollectionsent-form.component.html',
  styleUrls: ['./datacollectionsent-form.component.css']
})
export class DatacollectionsentFormComponent implements OnInit {
  constructor(private fsf: FsService) {}

  @Input() currentIndex: number;
  @Input() clientId: string;
  @Input() currentInvoice: Invoices;

  caseId: string;

  ngOnInit() {}

  restartDatacollectionProcess() {
    this.fsf.restartDatacollection(this.clientId, this.currentInvoice.id);
    this.fsf.updateClientNumbers(this.clientId, 'datacollectionSent_counter', -1);
    this.fsf.updateClientNumbers(this.clientId, 'datacollection_counter', 1);
  }

  dataCollectionUnsucessfull() {
    const reasonenglish = 'Supplier did not send revised invoice.';
    const reasonicelandic = 'Söluaðili sendi ekki reikning';
    const cancelTime = moment(new Date()).format('DD.MM.YYYY');

    this.fsf.datacollectionUnsuccessfull(this.clientId, this.currentInvoice.id, reasonenglish, reasonicelandic, cancelTime);
    this.fsf.updateClientNumbers(this.clientId, 'datacollectionSent_counter', -1);
  }

  dataCollectionSucessfull() {
    const receivedTime = moment(new Date()).format('DD.MM.YYYY');
    this.fsf.datacollectionSuccessfull(this.clientId, this.currentInvoice.id, receivedTime);
    this.fsf.updateClientNumbers(this.clientId, 'datacollectionSent_counter', -1);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
