<div fxLayout="row">
  <div><app-client-docs-info [clientId]="data"></app-client-docs-info></div>
  <div>
    <mat-list>
      <mat-list-item>
        <button type="button" mat-raised-button (click)="umbod.click()">Senda Umboð</button>
        <input hidden type="file" #umbod (change)="upload($event, 'umbod')" accept=".pdf" />
      </mat-list-item>
      <mat-list-item>
        <button type="button" mat-raised-button (click)="vatcertificate.click()">Senda VAT certificate</button>
        <input hidden type="file" #vatcertificate (change)="upload($event, 'vatcertificate')" accept=".pdf" />
      </mat-list-item>
      <mat-list-item>
        <button type="button" mat-raised-button (click)="vatagentauthorisation.click()">Senda VAT Agent authorisation</button>
        <input hidden type="file" #vatagentauthorisation (change)="upload($event, 'vatagentauthorisation')" accept=".pdf" />
      </mat-list-item>
    </mat-list>
  </div>
</div>
