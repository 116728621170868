<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between" fxFlexAlign="baseline">
    <div fxFlex="auto"><h1>Þjónustuveitendur</h1></div>
    <div fxFlex="grow" style="text-align:center">
      <mat-form-field style="width:80%">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Leit" />
      </mat-form-field>
    </div>
    <div style="margin-top:15px">
      <a (click)="NewIssuer()">
        <button mat-raised-button color="primary"><mat-icon>business</mat-icon> Stofna</button></a
      >
    </div>
  </div>

  <mat-table #table [dataSource]="dataSource" matSort class="animate">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Nafn </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Netfang </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="issuerid">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Kennitala </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.issuerid }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="end">
        <button type="button" (click)="$event.stopPropagation()" mat-icon-button [matMenuTriggerFor]="appMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #appMenu="matMenu">
          <ng-template matMenuContent>
            <button type="button" mat-menu-item (click)="openDialog(element)"><mat-icon>build</mat-icon>Breyta</button>
          </ng-template>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row class="highlight" *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="this.sizeOptions" showFirstLastButtons></mat-paginator>
</mat-card>
