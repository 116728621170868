import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray, AbstractControl, ValidatorFn } from '@angular/forms';
import { FsService } from 'src/app/service/fs.service';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Invoices } from 'src/app/shared/dbinterfaces';
import { ConfirmDeleteInboxInvoiceComponent } from '../confirm-delete-inbox-invoice/confirm-delete-inbox-invoice.component';

@Component({
  selector: 'app-invalid-form',
  templateUrl: './invalid-form.component.html',
  styleUrls: ['./invalid-form.component.css']
})
export class InvalidFormComponent implements OnInit {
  @Input() currentIndex: number;
  @Input() clientId: string;
  @Input() currentInvoice: Invoices;

  allIssuers: string[] = [];
  allIssuersObject;
  allFaultsName: string[] = [];
  allFaults;
  filteredOptions: Observable<string[]>;
  issuersCollection: Observable<any>;
  issuersCollection2: Observable<any>;
  faultsCollection: Observable<any>;

  // stuff to check for double invoices
  InvoicesInCases: Invoices[];
  allDatacollection: Invoices[];
  allDatacollectionObs: Observable<any>;
  allDataCollectionSub: Subscription;
  doubleInvoiceId: string;
  doubleInvoiceIndex: number;

  // Form stuff
  InvalidInvoiceForm: FormGroup;
  currentIssuerData: string[] = [];
  maxIndex: number;
  maxIndexSub: Subscription;

  invalidInvoices: Observable<any>;

  selectedIssuer: string;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  filteredFaults: Observable<string[]>;
  faults: string[] = [];
  issuerControl = new FormControl('', Validators.required);
  faultCtrl = new FormControl('', [this.faultsValidator(this.faults)]);

  @ViewChild('faultInput', { static: true }) faultInput: ElementRef<HTMLInputElement>;
  @ViewChild('faultauto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild('issuerauto') issuermatAutocomplete: MatAutocomplete;

  //Subscriptions
  issuersCollectionSub: Subscription;
  faultsCollectionSub: Subscription;
  invalidInvoicesSub: Subscription;

  constructor(private fsf: FsService, private fb: FormBuilder, public dialog: MatDialog) {}

  ngOnInit() {
    // Initialize form
    this.resetForm();

    // Populate issuers
    this.issuersCollection = this.fsf.getIssuersCollection();
    this.issuersCollectionSub = this.issuersCollection.subscribe(res => {
      this.allIssuersObject = res;
      this.allIssuers = [];
      for (let index = 0; index < res.length; index++) {
        const element = res[index].name;
        this.allIssuers.push(element);
      }
    });

    // Populate allfaults
    this.faultsCollection = this.fsf.getFaultsCollection();
    this.faultsCollectionSub = this.faultsCollection.subscribe(res => {
      this.allFaults = res;
      for (let index = 0; index < res.length; index++) {
        this.allFaultsName.push(res[index].name);
      }
    });

    // Populate dataCollection
    this.allDatacollectionObs = this.fsf.getDatacollection(this.clientId);
    this.allDataCollectionSub = this.allDatacollectionObs.subscribe(res => {
      this.allDatacollection = res;
    });

    this.InvoicesInCases = this.fsf.getAllCaseInvoices(this.clientId);
  }

  confirmDeletePdfFromDatacollection() {
    const data = { clientId: this.clientId, collectionName: 'datacollection', docId: this.currentInvoice.id, path: this.currentInvoice.path };
    const newDialogRef = this.dialog.open(ConfirmDeleteInboxInvoiceComponent, { data: data });
  }

  invoiceNumberChange(value) {
    const thisInvoiceno = value;
    const issuer = this.InvalidInvoiceForm.controls['issuer'].value;
    if (issuer == '' || issuer == undefined) {
      this.InvalidInvoiceForm.controls['invoicenumber'].setValue('');
      this.InvalidInvoiceForm.controls['invoicenumber'].setErrors({ noissuer: true });
    } else {
      // search datacollection
      for (let index = 0; index < this.allDatacollection.length; index++) {
        const element = this.allDatacollection[index];

        if (element.issuer == issuer && element.invoicenumber == thisInvoiceno) {
          const issuer = this.InvalidInvoiceForm.controls['issuer'].value;
          this.InvalidInvoiceForm.controls['invoicenumber'].setValue('');
          this.InvalidInvoiceForm.controls['invoicenumber'].setErrors({ exists: true });
          this.doubleInvoiceId = element.id;
          this.doubleInvoiceIndex = index;
          break;
        }
      }

      for (let index = 0; index < this.InvoicesInCases.length; index++) {
        const element = this.InvoicesInCases[index];

        if (element.issuer == issuer && element.invoicenumber == thisInvoiceno) {
          const issuer = this.InvalidInvoiceForm.controls['issuer'].value;
          this.InvalidInvoiceForm.controls['invoicenumber'].setValue('');
          this.InvalidInvoiceForm.controls['invoicenumber'].setErrors({ existsincase: true });
          this.doubleInvoiceId = element.id;
          this.doubleInvoiceIndex = index;
          break;
        }
      }
    }
  }

  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        this.faults.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }
      this.faultCtrl.setValue('');
    }
  }

  remove(fault: string): void {
    const index = this.faults.indexOf(fault);
    if (index >= 0) {
      this.faults.splice(index, 1);
      this.InvalidInvoiceForm.controls['faults'].setValue(['']);
      this.InvalidInvoiceForm.controls['faults'].markAsUntouched();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.faults.push(event.option.viewValue);
    this.faultInput.nativeElement.value = '';
    this.InvalidInvoiceForm.controls['faults'].markAsUntouched();
  }

  private _faultfilter(value: string): string[] {
    const filterValue = value.toString().toLowerCase();
    return this.allFaultsName.filter(
      fault =>
        fault
          .toString()
          .toLowerCase()
          .indexOf(filterValue) === 0
    );
  }

  private _issuerfilter(value: string): string[] {
    const filterValue = value.toString().toLowerCase();

    return this.allIssuers.filter(issuer =>
      issuer
        .toString()
        .toLowerCase()
        .includes(filterValue)
    );
  }

  async submithandler() {
    var data: Invoices = this.InvalidInvoiceForm.value;
    data.faultsenglish = new Array();
    data.faultsicelandic = new Array();
    data.datacollectionStatus = 'ReadyForGmail';

    // Gather faults
    for (let index = 0; index < this.faults.length; index++) {
      const element = this.faults[index];
      data.faults[index] = element;

      for (let index2 = 0; index2 < this.allFaults.length; index2++) {
        if (element == this.allFaults[index2].name) {
          data.faultsenglish[index] = this.allFaults[index2].english;
          data.faultsicelandic[index] = this.allFaults[index2].icelandic;
        }
      }
      data.faults[index] = element;
    }
    const Issuer = data.issuer;

    for (var i = 0; i < this.allIssuersObject.length; i++) {
      if (Issuer == this.allIssuersObject[i].name) {
        data.issuerid = this.allIssuersObject[i].issuerid;
        data.issueremail = this.allIssuersObject[i].email;
        data.issuerdocid = this.allIssuersObject[i].id;
      }
    }
    this.fsf.setInvoiceAsReadyForEmail(this.clientId, this.currentInvoice.id, data);
    this.fsf.updateClientNumbers(this.clientId, 'datacollection_counter', -1);
    this.fsf.updateClientNumbers(this.clientId, 'datacollectionReadyForGmail_counter', 1);
    this.resetForm();
  }

  get faultForms() {
    return this.InvalidInvoiceForm.get('faults') as FormArray;
  }

  issuerClick(value: string) {
    this.selectedIssuer = value.toLowerCase();
    this.InvalidInvoiceForm.controls['issuer'].setValue(value);
    this.InvalidInvoiceForm.controls['invoicenumber'].setValue('');
    this.InvalidInvoiceForm.controls['invoicenumber'].markAsUntouched();
  }

  checkIssuer() {
    if (!this.selectedIssuer || this.selectedIssuer.toLowerCase() !== this.InvalidInvoiceForm.controls['issuer'].value.toLowerCase()) {
      this.InvalidInvoiceForm.controls['issuer'].setValue('');
      this.selectedIssuer = '';
    } else {
      this.InvalidInvoiceForm.controls['invoicenumber'].setValue('');
      this.InvalidInvoiceForm.controls['invoicenumber'].markAsUntouched();
    }
  }

  resetForm() {
    // initialize form
    this.InvalidInvoiceForm = this.fb.group({
      issuer: new FormControl('', Validators.required),
      invoicenumber: new FormControl('', Validators.required),
      faults: this.fb.array([this.faultCtrl])
    });
    this.selectedIssuer = '';
    this.faults = [];
    this.InvalidInvoiceForm.controls['issuer'].markAsUntouched();
    this.InvalidInvoiceForm.controls['invoicenumber'].markAsUntouched();
    this.InvalidInvoiceForm.controls['faults'].markAsUntouched();

    // filter for issuers
    this.filteredOptions = this.InvalidInvoiceForm.get('issuer').valueChanges.pipe(
      startWith(''),
      map(value => this._issuerfilter(value))
    );
    //filter for faults
    this.filteredFaults = this.faultCtrl.valueChanges.pipe(
      startWith(null),
      map((fault: string | null) => (fault ? this._faultfilter(fault) : this.allFaultsName.slice()))
    );
  }

  faultsValidator(faults): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      for (let index = 0; index < this.faults.length; index++) {
        const element = this.faults[index];
        if (this.allFaultsName.indexOf(element) === -1) {
          return { invalidEntry: true };
        }
      }

      if (this.faults.length === 0) {
        return { noFaults: true };
      }
      return null;
    };
  }

  invoiceValid() {
    const clientId = this.clientId;
    const docId = this.currentInvoice.id;
    this.fsf.moveDocFromDatacollectionToInbox(clientId, docId);
    this.fsf.updateClientNumbers(this.clientId, 'datacollection_counter', -1);
  }

  invoiceUnusable() {
    const clientId = this.clientId;
    const docId = this.currentInvoice.id;
    this.fsf.moveDocFromDatacollectionToUnusable(clientId, docId);
    this.fsf.updateClientNumbers(this.clientId, 'datacollection_counter', -1);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.issuersCollectionSub) this.issuersCollectionSub.unsubscribe();
    if (this.faultsCollectionSub) this.faultsCollectionSub.unsubscribe();
    if (this.invalidInvoicesSub) this.invalidInvoicesSub.unsubscribe();
    if (this.allDataCollectionSub) this.allDataCollectionSub.unsubscribe();
  }
}
