<h2>Nýr Galli</h2>
<form [formGroup]="FaultsForm" (ngSubmit)="submitHandler()">
  <mat-form-field class="item">
    <input matInput placeholder="Nafn" formControlName="name" />
    <mat-error *ngIf="this.FaultsForm.get('name').invalid">
      Verður að vera stakt orð - engir íslenskir stafir
    </mat-error>
  </mat-form-field>

  <mat-form-field class="item">
    <input matInput placeholder="Enska" formControlName="english" />
  </mat-form-field>
  <mat-form-field class="item">
    <input matInput placeholder="Íslenska" formControlName="icelandic" />
  </mat-form-field>

  <div class="button-container">
    <button class="submit-button" mat-raised-button color="primary" type="submit" [disabled]="FaultsForm.invalid">
      Staðfesta
    </button>
  </div>
</form>
