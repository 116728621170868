import { Component, OnInit, Input } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { Invoices } from 'src/app/shared/dbinterfaces';

@Component({
  selector: 'app-case-invoice-form',
  templateUrl: './case-invoice-form.component.html',
  styleUrls: ['./case-invoice-form.component.css']
})
export class CaseInvoiceFormComponent implements OnInit {
  constructor(private fsf: FsService) {}

  @Input() currentIndex: number;
  @Input() clientId: string;
  @Input() currentInvoice: Invoices;
  @Input() caseId: string;


  ngOnInit() {
  }

  moveInvoiceToInbox() {
    this.fsf.moveDocFromCaseToInbox(this.clientId, this.caseId, this.currentInvoice.id);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

  }
}
