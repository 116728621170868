import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FsService } from 'src/app/service/fs.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-client-documents',
  templateUrl: './edit-client-documents.component.html',
  styleUrls: ['./edit-client-documents.component.css']
})
export class EditClientDocumentsComponent implements OnInit, OnDestroy {
  editClientDocumentsForm: FormGroup;
  clientObs: Observable<any>;
  clientObsSub: Subscription;
  client;

  // Form state
  loading = false;
  success = false;

  constructor(private fb: FormBuilder, private fsf: FsService, public dialogRef: MatDialogRef<EditClientDocumentsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.editClientDocumentsForm = this.fb.group({
      umbod: ['']
    });
    this.clientObs = this.fsf.getClient(this.data);
    this.clientObsSub = this.clientObs.subscribe(res => {
      this.client = res;
    });
  }

  upload(event, type) {
    const fileName = this.client.name + ' - ' + type + '.pdf';
    this.fsf.upload(event, this.client, this.data, fileName, type);
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.clientObsSub) this.clientObsSub.unsubscribe();
  }
}
