import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FsService } from 'src/app/service/fs.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-unusable-amount',
  templateUrl: './unusable-amount.component.html',
  styleUrls: ['./unusable-amount.component.css']
})
export class UnusableAmountComponent implements OnInit {
  constructor(private route: ActivatedRoute, private fsf: FsService, private router: Router) {
    this.navigationSub = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }
  clientId: string;
  count: number;
  counterDoc: Observable<any>;
  navigationSub: Subscription;
  currentClientIdSub: Subscription;
  counterDocSub: Subscription;

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.counterDoc = this.fsf.getUnusable(this.clientId);
    this.counterDocSub = this.counterDoc.subscribe(res => {
      this.count = res.length;
    });
  }
  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSub) {
      this.navigationSub.unsubscribe();
    }
    if (this.counterDocSub) this.counterDocSub.unsubscribe();
    if (this.currentClientIdSub) this.currentClientIdSub.unsubscribe();
  }
}
