<div class="chart">
  <ngx-charts-bar-vertical-2d
    [scheme]="colorScheme"
    [results]="chartData"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showYAxisLabel]="showYAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [legend]="showLegend"
    (select)="onSelect($event)"
  >
    >
  </ngx-charts-bar-vertical-2d>
</div>
