import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FsService } from 'src/app/service/fs.service';
import { Observable, Subscription } from 'rxjs';
import { Client } from 'src/app/shared/dbinterfaces';

@Component({
  selector: 'app-data-collection-amount',
  templateUrl: './data-collection-amount.component.html',
  styleUrls: ['./data-collection-amount.component.css']
})
export class DataCollectionAmountComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute, private fsf: FsService) {}
  clientId: string;

  // InfoNeeded variables
  clientInfoObs: Observable<any>;
  clientInfoSub: Subscription;

  infoNeededNumber: number;
  readyForGmailNumber: number;
  datacollectionSentNumber: number;

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.clientInfoObs = this.fsf.getClient(this.clientId)
    this.clientInfoSub = this.clientInfoObs.subscribe((res:Client) => {
      this.infoNeededNumber = res.datacollection_counter
      this.readyForGmailNumber = res.datacollectionReadyForGmail_counter
      this.datacollectionSentNumber = res.datacollectionSent_counter
    })

  }




  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.clientInfoSub) this.clientInfoSub.unsubscribe();

  }
}
