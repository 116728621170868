<mat-card>
  <mat-card-header>
    <h1>Staðfestar upplýsingar</h1>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <div *ngIf="this.currentInvoice">
        <mat-list-item>
          <div class="left mat-h3">Seljandi:</div>
          <div class="right mat-h3">{{ this.currentInvoice.issuer }}</div>
        </mat-list-item>
        <mat-list-item>
          <div class="left mat-h3">Dagsetning:</div>
          <div class="right mat-h3">{{ this.currentInvoice.date }}</div>
        </mat-list-item>
        <mat-list-item>
          <div class="left mat-h3">Lýsing:</div>
          <div class="right mat-h3">{{ this.currentInvoice.servicetype }}</div>
        </mat-list-item>
        <mat-list-item>
          <div class="left mat-h3">Reikningsnúmer:</div>
          <div class="right mat-h3">{{ this.currentInvoice.invoicenumber }}</div>
        </mat-list-item>
        <mat-list-item>
            <div class="left mat-h3">Vinnslunúmer:</div>
            <div class="right mat-h3">{{ this.currentInvoice.refnumber }}</div>
          </mat-list-item>
        <mat-list-item>
          <div class="left mat-h3">Heildarfjárhæð:</div>
          <div class="right mat-h3">{{ this.currentInvoice.amount | number: '':'is' }} {{ this.currentInvoice.currency }}</div>
        </mat-list-item>
        <mat-list-item>
          <div class="left mat-h3">VSK fjárhæð:</div>
          <div class="right mat-h3">{{ this.currentInvoice.vatamount | number: '':'is' }} {{ this.currentInvoice.vatcurrency }}</div>
        </mat-list-item>
        <mat-list-item>
          <div class="left mat-h3">Útreiknað VSK:</div>
          <div class="right mat-h3">{{ this.currentInvoice.iskvat | number: '':'is' }} ISK</div>
        </mat-list-item>
        <mat-list-item>
          <div class="left mat-h3">Gengi:</div>
          <div class="right mat-h3">{{ this.currentInvoice.exchangerate }}</div>
        </mat-list-item>
      </div>
    </mat-list>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button type="button" mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent>
    <button type="button" mat-menu-item (click)="moveInvoiceToInbox()"><mat-icon>all_inbox</mat-icon>Færa í Inbox</button>
  </ng-template>
</mat-menu>
