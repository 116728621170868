<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2>Skráningarform</h2>
    <div *ngIf="this.currentInvoice.hakkavel">
      <div *ngIf="this.currentInvoice?.hakkavel?.length == 0; else result">
        <mat-card style=" background-color:rgb(254, 255, 222);">
          <div class="mat-body-strong">Hakkavél:</div>
          <div class="mat-body">
            Gat ekki lesið reikning
          </div>
        </mat-card>
      </div>
      <ng-template #result>
        <div *ngIf="(this.currentInvoice?.hakkavel)[0]?.issuer == 'Afrit' || this.currentInvoice.hakkavel[0].issuer == 'afrit'">
          <mat-card style="background-color:rgb(254, 255, 222)">
            <div class="mat-body-strong">Hakkavél:</div>
            <div class="mat-body">
              Afrit
            </div>
          </mat-card>
        </div>
        <div *ngIf="(this.currentInvoice?.hakkavel)[0]?.issuer == 'Synishorn'">
          <mat-card style="background-color:rgb(254, 255, 222)">
            <div class="mat-body-strong">Hakkavél:</div>
            <div class="mat-body">
              Sýnishorn
            </div>
          </mat-card>
        </div>
        <div *ngIf="this.checkIfKt((this.currentInvoice?.hakkavel)[0]?.issuer)">
          <mat-card style="background-color: beige;">
            <div class="mat-body-strong">Hakkavél:</div>
            <div class="mat-body">Tókst</div>
          </mat-card>
        </div>
      </ng-template>
    </div>
  </mat-card-title>
  <mat-card-content>
    <form class="form-container" [formGroup]="InvoiceForm">
      <mat-checkbox color="primary" formControlName="creditInvoice">Kreditreikningur</mat-checkbox><br />

      <mat-form-field appearance="outline" class="item" style="width:100%">
        <mat-label>Seljandi</mat-label>
        <input type="text" placeholder="Seljandi" aria-label="Number" matInput formControlName="issuer" [matAutocomplete]="auto" (blur)="checkIssuer()" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="issuerClick($event.option.value)">
          <mat-option *ngFor="let option of (filteredOptions | async)" [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="InvoiceForm.get('issuer').invalid">Seljandi ekki til</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="item" style="width:60%">
        <mat-label>Lýsing</mat-label>
        <input matInput placeholder="Lýsing" formControlName="servicetype" />
        <mat-error *ngIf="this.InvoiceForm.controls['date'].hasError('invalid')">Vantar lýsingu</mat-error>
      </mat-form-field>
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxFlex="auto">
          <mat-form-field appearance="outline" class="item">
            <mat-label>Dagsetning</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="Dagsetning" formControlName="date" (dateChange)="datePickerChange($event.value)" [min]="minDate" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="multi-year"></mat-datepicker>
            <mat-error *ngIf="this.InvoiceForm.controls['date'].hasError('noApplication')">Fann ekki umsókn fyrir þessa dags. </mat-error>
            <mat-error *ngIf="this.InvoiceForm.controls['date'].hasError('invalid')">Villa </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="end" style="margin-top:-10px; margin-left:10px;" *ngIf="this.selectedApplication?.viewValue">
          <mat-card style="background-color: beige; text-align: center"> {{ this.selectedApplication?.viewValue }}</mat-card>
        </div>
      </div>

      <mat-form-field appearance="outline" class="item">
        <mat-label>Vinnslunúmer</mat-label>
        <input matInput placeholder="Vinnslunúmer" formControlName="refnumber" />

        <mat-error *ngIf="this.InvoiceForm.controls['refnumber'].hasError('invalid')">Verður að vera Auto eða tala </mat-error>
        <mat-error *ngIf="this.InvoiceForm.controls['refnumber'].hasError('exists')">Þetta vinnslunúmer er þegar skráð. </mat-error>
        <mat-error *ngIf="this.InvoiceForm.controls['refnumber'].hasError('pdfIsScan')">Skannað pdf - þarf vinnslunúmer </mat-error>
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="auto">
          <mat-form-field appearance="outline" class="item">
            <mat-label>Reikningsnúmer</mat-label>
            <input matInput placeholder="Reikningsnúmer" formControlName="invoicenumber" (change)="invoiceNumberChange($event.currentTarget.value)" />
            <mat-error *ngIf="this.InvoiceForm.controls['invoicenumber'].hasError('exists')">Reikningur er þegar skráður í umsókn</mat-error>
            <mat-error *ngIf="this.InvoiceForm.controls['invoicenumber'].hasError('invalid')">Vantar reikningsnúmer </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="end" style="margin-top:-20px; margin-left:10px;" *ngIf="this.datacollectionInvoiceId">
          <mat-card style="background-color: beige; text-align: center"
            ><h2 class="mat-h2">Gagnaöflun:</h2>
            verður fjarlægður
          </mat-card>
        </div>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="outline" class="item">
          <mat-label>Heildar fjárhæð</mat-label>
          <input mask="dot_separator.2" matInput placeholder="Heildar fjárhæð" formControlName="amount" />
          <mat-error *ngIf="InvoiceForm.get('amount').invalid">Er þetta örugglega tala?</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="item">
          <mat-label>Mynt</mat-label>
          <mat-select placeholder="Mynt" formControlName="currency" name="currency">
            <mat-option *ngFor="let cur of currencies" [value]="cur.value">
              {{ cur.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field appearance="outline" class="item">
          <mat-label>VSK fjárhæð</mat-label>
          <input mask="dot_separator.2" matInput placeholder="VSK fjárhæð" formControlName="vatamount" />
          <mat-error *ngIf="InvoiceForm.get('vatamount').invalid">Er þetta örugglega tala?</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="item">
          <mat-label>Mynt</mat-label>
          <mat-select placeholder="Mynt" formControlName="vatcurrency" name="vatcurrency">
            <mat-option *ngFor="let vatcur of currencies" [value]="vatcur.value">
              {{ vatcur.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="showExhangeRate">
        <mat-form-field appearance="outline" class="item">
          <mat-label>Gengi</mat-label>
          <input matInput placeholder="Gengi" formControlName="exchangerate" />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" style="width:100%;">
        <div>
          <button class="submit-button" mat-raised-button color="primary" (click)="submithandler()" [disabled]="!InvoiceForm.valid || InvoiceForm.controls['invoicenumber'].disabled"><mat-icon>folder_open</mat-icon> Senda í umsókn</button>
        </div>
        <div>
          <button type="button" [disabled]="this.invoiceInvalidButtonDisabled" mat-raised-button color="accent" (click)="invoiceInvalid()"><mat-icon>mail_outline</mat-icon> Gagnaöflun</button>

          <button type="button" mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent>
    <a style="text-decoration:none" download href="{{ this.currentInvoice.downloadUrl }}"
      ><button type="button" mat-menu-item><mat-icon>cloud_download</mat-icon>Sækja Pdf</button></a
    >

    <button type="button" mat-menu-item (click)="invoiceUnusable()"><mat-icon>error_outline</mat-icon>Ónothæfur</button>
    <button type="button" mat-menu-item (click)="cutPdf()"><mat-icon>filter_2</mat-icon>Klippa PDF</button>

    <mat-divider></mat-divider>

    <button type="button" mat-menu-item (click)="confirmDeletePdfFromInbox()"><mat-icon>delete</mat-icon>Eyða</button>
  </ng-template>
</mat-menu>
