<div class="container">
  <div class="left">
    <pdf-viewer (after-load-complete)="loadComplete($event)" (page-rendered)="pageRendered($event)" [src]="fileUrl" [show-all]="true"></pdf-viewer>
  </div>
  <div class="right">
    <div *ngIf="this.marginTop">
      <div *ngFor="let page of numPages; let i = index">
        <div *ngIf="i + 1 != numPages.length">
          <div [style.marginTop.px]="this.marginTop" [style.marginBottom.px]="this.marginBottom" [style.marginLeft.px]="this.pageWidth">
            <button mat-raised-button disabled>
              <div *ngIf="!this.buttonDisabled" class="checkbox"><input type="checkbox" id="{{ i }}" /></div>
              <mat-spinner *ngIf="this.buttonDisabled"></mat-spinner>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="fixed">
  <button type="button" mat-raised-button color="primary" (click)="this.cutMultiple()" [disabled]="this.buttonDisabled">
    <div *ngIf="!this.buttonDisabled">Staðfesta</div>
    <mat-spinner *ngIf="this.buttonDisabled"></mat-spinner>
  </button>
</div>
