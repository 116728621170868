<div class="container"  *ngIf="this.currentInvoice">
  <div class="left"><app-pdf-viewer (pdfHasText)="pdfHasTextEvent($event)" [fileUrl]="this.currentInvoice.downloadUrl"></app-pdf-viewer></div>
  <div class="right" [ngStyle]="{'margin-top': formMarginTop }">
    <app-invoice-controls [currentIndex]="this.currentIndex" [maxIndex]="this.maxIndex" (numberChange)="onIndexChange($event)"></app-invoice-controls>
    <app-invoice-form
      [currentInvoice]="this.currentInvoice"
      [currentIndex]="this.currentIndex"
      [clientId]="this.clientId"
      [pdfHasText]="this.pdfHasText"
    ></app-invoice-form>
  </div>
</div>
<div style="background-color:green">
</div>
