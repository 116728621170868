import { Component, OnInit, Input } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { Invoices } from 'src/app/shared/dbinterfaces';

@Component({
  selector: 'app-datacollection-form',
  templateUrl: './datacollection-form.component.html',
  styleUrls: ['./datacollection-form.component.css']
})
export class DatacollectionFormComponent implements OnInit {
  constructor( private fsf: FsService) {}

  @Input() currentIndex: number;
  @Input() clientId: string;
  @Input() currentInvoice: Invoices;
  
  ngOnInit() {}

  restartDatacollection() {
    this.fsf.restartDatacollection(this.clientId, this.currentInvoice.id);
    this.fsf.updateClientNumbers(this.clientId, 'datacollectionReadyForGmail_counter', -1)
    this.fsf.updateClientNumbers(this.clientId, 'datacollection_counter', 1)
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
