
<div class="controlbuttons">
  <button [disabled]="prevdisabled()" mat-raised-button (click)="prevInvoice()">
    <mat-icon>navigate_before</mat-icon>
  </button>
  <h2 style="margin-left:10px;margin-right:10px;"> {{ this?.currentIndex + 1 }} / {{ this?.maxIndex }} </h2>
  <button [disabled]="nextdisabled()" mat-raised-button (click)="nextInvoice()">
    <mat-icon>navigate_next</mat-icon>
  </button>
</div>

