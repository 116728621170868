<h2>Nýr söluaðili</h2>
<form [formGroup]="IssuerForm" (ngSubmit)="submitHandler()">
  <mat-form-field class="item">
    <input matInput placeholder="Kennitala" formControlName="issuerid" />
    <mat-error *ngIf="this.IssuerForm.getError('issuerid')">Kennitala verður að vera xxxxxx-xxxx</mat-error>
    <mat-error *ngIf="this.IssuerForm.controls['issuerid'].hasError('exists')">Þessi kennitala er þegar skráð. </mat-error>
  </mat-form-field>
  <mat-form-field class="item">
    <input matInput placeholder="Nafn" formControlName="name" />
  </mat-form-field>

  <mat-form-field class="item">
    <input matInput placeholder="Netfang" formControlName="email" />
  </mat-form-field>

  <mat-form-field class="item">
    <input matInput placeholder="Lýsing þjónustu" formControlName="servicetype" />
  </mat-form-field>

  <button class="submit-button" mat-raised-button color="primary" type="submit" [disabled]="IssuerForm.invalid">
    Staðfesta
  </button>
</form>
