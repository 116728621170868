import { Component } from '@angular/core';
import { AuthService } from './service/auth.service';
import { Observable } from 'rxjs';
import { User } from './shared/dbinterfaces'; // optional
import { GapiService } from './service/gapi.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Lögmenn Kópavogi - VSK';
  user$: Observable<User>;

  constructor(private authService: AuthService, public gapi: GapiService) {
    this.user$ = this.authService.user$;
    console.log(this.user$);
  }
}
