<div *ngIf="this.twopage" style="margin-bottom: 1cm;">
  <mat-card style="background-color:#f89e9e">
    <mat-card-content style="text-align:center">
      Gættu þín! Það eru fleiri en ein bls. í þessu pdf skjali. Skrollaðu niður til að sjá<br />

      <strong>Athuga! Þetta gæti verið tveir (eða fleiri) reikningar </strong>
    </mat-card-content>
  </mat-card>
</div>
<pdf-viewer (text-layer-rendered)="textLayerRendered($event)" (page-rendered)="pageRendered($event)" [src]="fileUrl" [show-all]="true"></pdf-viewer>
