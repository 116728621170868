import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { Observable, Subscription } from 'rxjs';
import { Client } from 'src/app/shared/dbinterfaces';

@Component({
  selector: 'app-client-docs-info',
  templateUrl: './client-docs-info.component.html',
  styleUrls: ['./client-docs-info.component.css']
})
export class ClientDocsInfoComponent implements OnInit, OnDestroy {
  clientObs: Observable<Client>;
  clientObsSub: Subscription;

  umbod: string;
  vatcertificate: string;
  vatagentauthorisation: string;
  @Input() clientId: string;

  constructor(private fsf: FsService) {}

  ngOnInit() {
    const clientObs = this.fsf.getClient(this.clientId);
    this.clientObsSub = clientObs.subscribe((res: Client) => {
      this.umbod = res.umbod;
      this.vatcertificate = res.vatcertificate;
      this.vatagentauthorisation = res.vatagentauthorisation;
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.clientObsSub) this.clientObsSub.unsubscribe();
  }
}
