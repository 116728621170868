import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { FsService } from '../../../service/fs.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-data-collection-defaults',
  templateUrl: './edit-data-collection-defaults.component.html',
  styleUrls: ['./edit-data-collection-defaults.component.css']
})
export class EditDataCollectionDefaultsComponent implements OnInit, OnDestroy {
  editDefaultsForm: FormGroup;
  defaultEmailTextSub: Subscription;
  currentData;

  // Form state
  loading = false;
  success = false;

  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private fsf: FsService, public dialogRef: MatDialogRef<EditDataCollectionDefaultsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.defaultEmailTextSub = this.fsf.getDefaultEmailText().subscribe(res => {
      this.editDefaultsForm.patchValue(res);
    });

    this.editDefaultsForm = this.fb.group({
      defaulttext: ['', Validators.required]
    });
  }

  async submitHandler() {
    this.loading = true;
    const formValue = this.editDefaultsForm.value;
    try {
      await this.fsf.setDefaultEmailText(formValue);
      this.success = true;
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    if (this.loading === false && this.success === true) {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    if (this.defaultEmailTextSub) this.defaultEmailTextSub.unsubscribe();
  }
}
