import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FsService } from '../../../service/fs.service';

@Component({
  selector: 'app-edit-fault',
  templateUrl: './edit-fault.component.html',
  styleUrls: ['./edit-fault.component.css']
})
export class EditFaultComponent implements OnInit {
  editFaultForm: FormGroup;

  // Form state
  loading = false;
  success = false;

  constructor(private fb: FormBuilder, private fsf: FsService, public dialogRef: MatDialogRef<EditFaultComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.editFaultForm = this.fb.group({
      name: [{ value: this.data.name, disabled: true }, Validators.required],
      english: [this.data.english, Validators.required],
      icelandic: [this.data.english, Validators.required]
    });
    this.editFaultForm.patchValue(this.data);
  }

  async submitHandler() {
    this.loading = true;
    const formValue = this.editFaultForm.value;
    try {
      await this.fsf.updateFault(formValue, this.data.id);
      this.success = true;
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    if (this.loading === false && this.success === true) {
      this.dialogRef.close();
    }
  }
}
