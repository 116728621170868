import { Component, AfterViewInit, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { EditIssuerComponent } from '../edit-issuer/edit-issuer.component';
import { Router } from '@angular/router';
import { NewIssuerComponent } from '../new-issuer/new-issuer.component';
import { FsService } from 'src/app/service/fs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-issuers',
  templateUrl: './view-issuers.component.html',
  styleUrls: ['./view-issuers.component.css']
})
export class ViewIssuersComponent implements OnInit, OnDestroy {
  displayedColumns = ['name', 'email', 'issuerid', 'edit'];
  dataSource: MatTableDataSource<any>;
  issuerSub: Subscription;
  defaultSorting = { id: 'name', start: 'asc' };
  sizeOptions = ['50', '100'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public dialog: MatDialog, private router: Router, private fsf: FsService) {}

  ngOnInit() {
    this.sort.sort(<MatSortable>this.defaultSorting);

    this.issuerSub = this.fsf.getIssuersCollection().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openDialog(data): void {
    const editDialogRef = this.dialog.open(EditIssuerComponent, {
      data: data
    });
  }

  deleteIssuer(data): void {
    this.fsf.deleteIssuer(data.id);
  }

  NewIssuer(): void {
    const newDialogRef = this.dialog.open(NewIssuerComponent, {});
  }

  viewIssuer(id) {
    this.router.navigate(['/viewIssuer/', id]);
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.issuerSub) this.issuerSub.unsubscribe();
  }
}
