<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center"> <h2>Gallaskráning</h2> </mat-card-title>
  <mat-card-content>
    <form autocomplete="off" class="form-container" [formGroup]="InvalidInvoiceForm">
      <div fxLayout="column">
        <mat-form-field appearance="outline" class="item">
          <mat-label>Seljandi</mat-label>
          <input outline="none" type="text" placeholder="Seljandi" id="issuer" aria-label="Number" matInput formControlName="issuer" [matAutocomplete]="auto" (blur)="checkIssuer()" />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="issuerClick($event.option.value)">
            <mat-option *ngFor="let option of (filteredOptions | async)" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            <mat-form-field appearance="outline" class="item">
              <mat-label>Reikningsnúmer</mat-label>
              <input outline="none" type="text" placeholder="Reikningsnúmer" id="invoicenumber" matInput formControlName="invoicenumber" (change)="invoiceNumberChange($event.currentTarget.value)" />
              <mat-error *ngIf="this.InvalidInvoiceForm.controls['invoicenumber'].hasError('exists')">Reikningur er þegar í gagnaöflun</mat-error>
              <mat-error *ngIf="this.InvalidInvoiceForm.controls['invoicenumber'].hasError('noissuer')">Þarf að skrá seljanda fyrst</mat-error>
              <mat-error *ngIf="this.InvalidInvoiceForm.controls['invoicenumber'].hasError('existsincase')">Reikningur er þegar í umsókn</mat-error>
              <mat-error *ngIf="this.InvalidInvoiceForm.controls['invoicenumber'].hasError('invalid')">Vantar reikningsnúmer</mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field appearance="outline" class="item">
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let fault of faults" [selectable]="selectable" [removable]="removable" (removed)="remove(fault)">
              {{ fault }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              outline="none"
              placeholder="Gallar..."
              #faultInput
              matInput
              [formControl]="faultCtrl"
              [matAutocomplete]="faultauto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)"
            />
            <mat-autocomplete #faultauto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let fault of (filteredFaults | async)" [value]="fault">
                {{ fault }}
              </mat-option>
            </mat-autocomplete>
          </mat-chip-list>
          <mat-error *ngIf="this.InvalidInvoiceForm.get('faults').invalid">Galli finnst ekki í gallaskrá</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" style="margin-bottom:20px; height:80px;"></div>
      <div fxLayout="row" fxLayoutAlign="space-between center" style="width:100%">
        <div>
          <button mat-raised-button color="primary" (click)="submithandler()" type="button" [disabled]="InvalidInvoiceForm.invalid">
            Staðfesta
          </button>
        </div>
        <div>
          <button type="button" mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </mat-card-content></mat-card
>
<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent>
    <button type="button" mat-menu-item (click)="invoiceValid()"><mat-icon>all_inbox</mat-icon>Inbox</button>
    <button type="button" mat-menu-item (click)="invoiceUnusable()"><mat-icon>error_outline</mat-icon>Ónothæfur</button>
    <mat-divider></mat-divider>

    <button type="button" mat-menu-item (click)="confirmDeletePdfFromDatacollection()"><mat-icon>delete</mat-icon>Eyða</button>
  </ng-template>
</mat-menu>
