import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { FsService } from '../../../service/fs.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-fault',
  templateUrl: './new-fault.component.html',
  styleUrls: ['./new-fault.component.css']
})
export class NewFaultComponent implements OnInit {
  FaultsForm: FormGroup;

  // Form state
  loading = false;
  success = false;

  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private fsf: FsService, public dialogRef: MatDialogRef<NewFaultComponent>) {}

  ngOnInit() {
    this.FaultsForm = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern(/^\w+$/)])],
      english: ['', Validators.required],
      icelandic: ['', Validators.required]
    });
  }
  async submitHandler() {
    this.loading = true;
    const formValue = this.FaultsForm.value;
    try {
      await this.fsf.newFault(formValue);
      this.success = true;
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    if (this.loading === false && this.success === true) {
      this.dialogRef.close();
    }
  }
}
