import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FsService } from 'src/app/service/fs.service';

@Component({
  selector: 'app-inbox-amount',
  templateUrl: './inbox-amount.component.html',
  styleUrls: ['./inbox-amount.component.css']
})
export class InboxAmountComponent implements OnInit, OnDestroy {
  constructor(private fsf: FsService, private router: Router, private route: ActivatedRoute) {
    this.navigationSub = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }
  clientId: string;
  counterDoc: Observable<any>;
  counterDocSub: Subscription;
  count: number;
  navigationSub: Subscription;

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.counterDoc = this.fsf.getInboxCollection(this.clientId);
    this.counterDocSub = this.counterDoc.subscribe(res => {
      this.count = res.length;
    });
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSub) {
      this.navigationSub.unsubscribe();
    }
    if (this.counterDocSub) this.counterDocSub.unsubscribe();
  }
}
