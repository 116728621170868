<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>

        <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                    Viðskiptavinur: {{ this.client?.name }}
                </mat-panel-title>
    
              </mat-expansion-panel-header>
              <span class="mat-body">{{ this.client?.address1 }} <br /> {{ this.client?.address2 }} <br />
                {{ this.client?.country }}</span>

</mat-expansion-panel>
</mat-accordion>




 
    </div>
    <div>
      <app-client-docs-info [clientId]="this.clientId" class="mat-body"></app-client-docs-info>
    </div>
    <mat-list>
      <div *ngIf="this.client?.hasOwnProperty('contacts')">
        <div *ngFor="let contact of this.client.contacts" fxFlex="end 0 0">

            <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ contact.contactname }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                    {{ contact.contactemail }} 
                    {{ contact.contactphone }}
                
                </mat-expansion-panel>
                </mat-accordion>

        </div>
      </div>
    </mat-list>
  </div>
</mat-card>

<mat-menu #clientMenu="matMenu">
  <ng-template matMenuContent>
    <button type="button" mat-menu-item (click)="openDialog(this.client)"><mat-icon>repeat</mat-icon>Skjöl</button>
  </ng-template>
</mat-menu>
