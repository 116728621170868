import { Component, ViewChild, OnInit, OnDestroy, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { FormControl } from '@angular/forms';
import { FsService } from 'src/app/service/fs.service';
import { User } from 'firebase';

@Component({
  selector: 'nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;
  @Input() user: Observable<User>;
  options: string[] = [];
  clientsCollection: Observable<any>;
  myControl = new FormControl('');
  filteredOptions: Observable<string[]>;
  allClients;
  clientName: string;
  clientId: string;
  clientsCollectionSub: Subscription;
  userId: string;
  userSub: Subscription;

  isHandset$: Observable<any> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches));

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private authService: AuthService, private fsf: FsService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.userSub = this.user.subscribe(res => {
      this.userId = res.uid;
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filter(value);
      })
    );
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.clientsCollection = this.fsf.getClientsCollection();
    this.clientsCollectionSub = this.clientsCollection.subscribe(res => {
      this.options = [];
      this.allClients = res;
      for (let index = 0; index < res.length; index++) {
        const element = res[index].name;
        this.options.push(element);
        if (this.clientId != undefined) {
          if ((res.id = this.clientId)) {
            this.clientName = res.name;
          }
        }
      }
    });
  }

  public logout() {
    this.authService.signOut();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  clientClick(value: string) {
    for (let index = 0; index < this.allClients.length; index++) {
      const element = this.allClients[index];
      if (element.name == value) {
        this.router.navigateByUrl('/viewclient/' + element.id);
      }
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.clientsCollectionSub) this.clientsCollectionSub.unsubscribe();
    if (this.userSub) this.userSub.unsubscribe();
  }
}
