import { Component, OnInit, OnDestroy } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { Subscription, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card2',
  templateUrl: './card2.component.html',
  styleUrls: ['./card2.component.css']
})
export class Card2Component implements OnInit, OnDestroy {
  data;
  dataSub: Subscription;

  // First table
  invalidTitle = 'Forgangslisti';
  displayedColumns: { [key: string]: string } = {
    name: 'Viðskiptavinur',
    inbox_counter: 'Inbox',
    datacollection_counter: 'Gagnaöflun',
    datacollectionReadyForGmail_counter: 'Ósent',
    datacollectionSent_counter: 'Sent'
  };
  Collection: Observable<any>;
  CollectionSub: Subscription;
  sizeOptions = ['5', '10'];
  sortOptions: { [key: string]: string } = { id: 'inbox_counter', start: 'desc' };

  constructor(private fsf: FsService, private router: Router) {}

  ngOnInit() {
    this.Collection = this.fsf.getAllClients2('inbox_counter', 'datacollection_counter');
    this.CollectionSub = this.Collection.subscribe(res => {
      this.data = res;
    });
  }

  tablerowfunc($event) {
    this.router.navigateByUrl('/viewclient/' + $event.id);
  }
  ngOnDestroy() {
    if (this.dataSub) this.dataSub.unsubscribe();
  }
}
