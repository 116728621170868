import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSortable, MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, Subscription } from 'rxjs';
import { TableButton } from 'src/app/shared/dbinterfaces';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnDestroy, OnChanges {
  // Initiate common variables
  dataSource: MatTableDataSource<any>;
  displayedColumnsData: string[];

  // Prepare regular table stuff
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // Get variables from parent
  @Input() Title: String;
  @Input() TitleImageUrl: String;
  @Input() Collection: Observable<any>;
  @Input() displayedColumns: [];
  @Input() sizeOptions: [];
  @Input() button: TableButton;
  @Input() sortOptions: {};

  // send rowFunction to parent
  @Output() parentFunc = new EventEmitter<any>();
  @Output() parentButtonClick = new EventEmitter<any>();

  // Subscriptions
  CollectionSub: Subscription;

  constructor() {}

  ngOnInit() {
    // get keys for *matHeaderRowDef & *matRowDef
    this.displayedColumnsData = Object.keys(this.displayedColumns);
    if (this.sortOptions) {
      this.sort.sort(<MatSortable>this.sortOptions);
    } else {
      const defaultSorting = { id: Object.keys(this.displayedColumns)[0], start: 'asc' };
      this.sort.sort(<MatSortable>defaultSorting);
    }

    // Subscribe to collection
    this.CollectionSub = this.Collection.subscribe(res => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  ngOnChanges() {
    this.resyncDB();
  }

  resyncDB() {
    if (this.CollectionSub) this.CollectionSub.unsubscribe();
    this.CollectionSub = this.Collection.subscribe(res => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  // Emit Row push to parent
  rowFunc(data) {
    this.parentFunc.emit(data);
  }

  // filter
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  // Emit optional button click
  buttonClick() {
    this.parentButtonClick.emit();
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.CollectionSub) this.CollectionSub.unsubscribe();
  }

  isNumber(value) {
    if (typeof value == 'number') {
      return true;
    } else {
      return false;
    }
  }
}
