import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FsService } from 'src/app/service/fs.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-delete-inbox-invoice',
  templateUrl: './confirm-delete-inbox-invoice.component.html',
  styleUrls: ['./confirm-delete-inbox-invoice.component.css']
})
export class ConfirmDeleteInboxInvoiceComponent implements OnInit, OnDestroy {
  constructor(private fsf: FsService, public dialogRef: MatDialogRef<ConfirmDeleteInboxInvoiceComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {}

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    this.fsf.deleteDocFromCollection(this.data.clientId, this.data.collectionName, this.data.docId, this.data.path);
    if (this.data.collectionName == 'datacollection') {
      this.fsf.updateClientNumbers(this.data.clientId, 'datacollection_counter', -1);
    }
    this.dialogRef.close();
  }

  ngOnDestroy() {}
}
