import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FsService } from 'src/app/service/fs.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-awaiting-response-amount',
  templateUrl: './awaiting-response-amount.component.html',
  styleUrls: ['./awaiting-response-amount.component.css']
})
export class AwaitingResponseAmountComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute, private fsf: FsService) {}
  id: string;
  count: number;
  counterDoc: Observable<any>;
  counterDocSub: Subscription;

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('p1');

    this.counterDoc = this.fsf.countDocs(this.id, 'awaitingresponse');
    this.counterDocSub = this.counterDoc.subscribe(res => {
      this.count = res.length;
    });
  }
  ngOnDestroy() {
    if (this.counterDocSub) this.counterDocSub.unsubscribe();
  }
}
