<div style="margin-top:300px" fxFlex fxLayout="row" fxLayoutAlign="center center">
  <div *ngIf="(gapi.user$ | async) as user">
    <h3>Logged in as {{ user.displayName }}</h3>
    <img src="{{ user.photoURL }}" width="50px" />
    <button (click)="gapi.logout()">Logout</button>

    <button (click)="gapi.getCalendar()">Get Google Calendar</button>
    <button (click)="gapi.insertEvent()">Add Event</button>

    <div *ngFor="let item of gapi.calendarItems">
      <h3>{{ item.summary }} - {{ item.status }}</h3>
      <p>
        <em>Created {{ item.created }}</em>
      </p>
      <p>{{ item.description }}</p>
    </div>
  </div>

  <button mat-raised-button (click)="gapi.login()"><mat-icon>cloud_queue</mat-icon> Innskráning</button>
</div>
