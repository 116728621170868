import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { FsService } from '../../../service/fs.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-new-affiliate',
  templateUrl: './new-affiliate.component.html',
  styleUrls: ['./new-affiliate.component.css']
})
export class NewAffiliateComponent implements OnInit {
  newAffiliateForm: FormGroup;

  // Form state
  loading = false;
  success = false;
  newData = { count: 0 };

  // tslint:disable-next-line:max-line-length
  constructor(private afAuth: AngularFireAuth, private fb: FormBuilder, private fsf: FsService, public dialogRef: MatDialogRef<NewAffiliateComponent>) {}

  ngOnInit() {
    this.newAffiliateForm = this.fb.group({
      name: '',
      email: '',
      password: '',
      idnumber: '',
      address1: '',
      address2: '',
      address3: '',
      country: '',
      contacts: this.fb.array([])
    });
  }

  async submitHandler() {
    this.loading = true;

    const formValue = this.newAffiliateForm.value;
    console.log(formValue);

    return this.afAuth.auth
      .createUserWithEmailAndPassword(formValue.email, formValue.password)
      .then(result => {
        console.log('affiliate created');
        this.loading = false;
      })
      .catch(error => {
        console.error(error);
      });
  }

  get contactForms() {
    return this.newAffiliateForm.get('contacts') as FormArray;
  }

  addContact() {
    const contact = this.fb.group({
      contactname: [],
      contactemail: [],
      contactphone: []
    });

    this.contactForms.push(contact);
  }

  deleteContact(i) {
    this.contactForms.removeAt(i);
  }
}
