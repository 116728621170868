import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FsService } from 'src/app/service/fs.service';

@Component({
  selector: 'app-view-unusable',
  templateUrl: './view-unusable.component.html',
  styleUrls: ['./view-unusable.component.css']
})
export class ViewUnusableComponent implements OnInit {
  // shared
  clientId: string;

  // First table
  unusableTitle = 'Ónothæfir';
  displayedColumns: { [key: string]: string } = {
    path: 'Skrá'
  };

  unusableCollection: Observable<any>;
  sizeOptions = ['50', '100'];

  constructor(private route: ActivatedRoute, public router: Router, private fsf: FsService) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');

    //setup First table
    this.unusableCollection = this.fsf.UnusableCollection(this.clientId);

    // Gather Observable of clients with ID
  }

  ngAfterViewInit() {}

  table1rowFunc($event) {
    this.router.navigateByUrl('/viewunusableinvoice/' + this.clientId + '/' + $event.id);
  }

  table2rowFunc($event) {
  }
  callback() {
  }
}
