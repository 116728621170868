import { Component, OnInit, Input } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { Invoices } from 'src/app/shared/dbinterfaces';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteInboxInvoiceComponent } from '../confirm-delete-inbox-invoice/confirm-delete-inbox-invoice.component';

@Component({
  selector: 'app-unusable-form',
  templateUrl: './unusable-form.component.html',
  styleUrls: ['./unusable-form.component.css']
})
export class UnusableFormComponent implements OnInit {
  constructor(private fsf: FsService, public dialog: MatDialog) {}

  @Input() currentIndex: number;
  @Input() clientId: string;
  @Input() currentInvoice: Invoices;

  ngOnInit() {}

  moveInvoiceToDatacollection() {
    this.fsf.moveDocFromUnusableToDatacollection(this.clientId, this.currentInvoice.id);
    this.fsf.updateClientNumbers(this.clientId, 'datacollection_counter', 1);
  }

  moveInvoiceToInbox() {
    this.fsf.moveDocFromUnusableToInbox(this.clientId, this.currentInvoice.id);
  }

  confirmDeletePdfFromUnusable() {
    const data = { clientId: this.clientId, collectionName: 'unusable', docId: this.currentInvoice.id, path: this.currentInvoice.path };
    const newDialogRef = this.dialog.open(ConfirmDeleteInboxInvoiceComponent, { data: data });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
