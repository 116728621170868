<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between" fxFlexAlign="baseline">
    <div fxFlex="auto"><h1>Gallar</h1></div>
    <div fxFlex="grow" style="text-align:center">
      <mat-form-field style="width:80%">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Leit" />
      </mat-form-field>
    </div>
    <div style="margin-top:15px">
      <button type="button" (click)="$event.stopPropagation()" mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #appMenu="matMenu">
        <ng-template matMenuContent>
          <button mat-menu-item color="primary" (click)="NewFault()"><mat-icon>plus_one</mat-icon> Stofna</button>
          <button mat-menu-item color="primary" (click)="openDialog2()"><mat-icon>view_headline</mat-icon> Breyta stöðluðum texta í tölvupóstum</button>
        </ng-template>
      </mat-menu>
    </div>
  </div>

  <mat-table #table [dataSource]="dataSource" class="animate">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Nafn </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="english">
      <mat-header-cell *matHeaderCellDef> Enska </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.english }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="icelandic">
      <mat-header-cell *matHeaderCellDef> Íslenska </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.icelandic }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="end">
        <button type="button" (click)="$event.stopPropagation()" mat-icon-button [matMenuTriggerFor]="rowMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #rowMenu="matMenu">
          <ng-template matMenuContent>
            <button type="button" mat-menu-item (click)="openDialog(element)"><mat-icon>build</mat-icon>Breyta</button>
          </ng-template>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row class="highlight" *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</mat-card>
