import { Component, OnInit, OnDestroy } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { Subscription, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-datacollection',
  templateUrl: './datacollection.component.html',
  styleUrls: ['./datacollection.component.css']
})
export class DatacollectionComponent implements OnInit, OnDestroy {
  chartData = [];
  Collection;
  CollectionSub: Subscription;

  // graph options
  single: any[];
  multi: any[];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;

  showYAxisLabel = true;
  yAxisLabel = 'Fjöldi';

  colorScheme = {
    domain: ['#f44242', '#FAA026', '#FAC51D', '#66BD6D', '#29BB9C', '#E96B56', '#55ACD2', '#B7332F', '#2C83C9', '#9166B8', '#92E7E8']
  };

  constructor(private fsf: FsService, private router: Router) {}

  ngOnInit() {
    this.Collection = this.fsf.getAllClients2('inbox_counter', 'datacollection_counter');
    this.CollectionSub = this.Collection.subscribe((res: any) => {
      this.Collection = res;
      this.chartData = [];
      for (let index = 0; index < this.Collection.length; index++) {
        const element = this.Collection[index];
        this.chartData.push({
          name: element.name,
          series: [
            { name: 'Inbox', value: element.inbox_counter, id: element.id },
            { name: 'Ómerkt', value: element.datacollection_counter, id: element.id },
            { name: 'Merkt', value: element.datacollectionReadyForGmail_counter, id: element.id },
            { name: 'Sent', value: element.datacollectionSent_counter, id: element.id }
          ]
        });
      }
    });
  }
  onSelect(event) {
    if (event.name == 'Inbox') {
      this.router.navigateByUrl('/inbox/' + event.id);
    } else {
      this.router.navigateByUrl('/datacollection/' + event.id);
    }
  }

  ngOnDestroy() {
    if (this.CollectionSub) this.CollectionSub.unsubscribe();
  }
}
