<app-client-info></app-client-info>
<app-table
  (parentFunc)="table1rowFunc($event)"
  [sizeOptions]="sizeOptions"
  [Title]="invalidTitle"
  [Collection]="invalidCollection"
  [displayedColumns]="displayedColumns"
></app-table>

<app-table
  (parentFunc)="table2rowFunc($event)"
  (parentButtonClick)="draftEmails()"
  [button]="this.button"
  [sizeOptions]="sizeOptions2"
  [Title]="invalid2Title"
  [Collection]="readyForGmailCollection"
  [displayedColumns]="displayedColumns2"
></app-table>

<app-table
  (parentFunc)="table3rowFunc($event)"
  [sizeOptions]="sizeOptions2"
  [Title]="invalid3Title"
  [Collection]="dataprocessingSentCollection"
  [displayedColumns]="displayedColumns3"
></app-table>

<app-table
  (parentFunc)="table4rowFunc($event)"
  [sizeOptions]="sizeOptions4"
  [Title]="invalid4Title"
  [Collection]="dataprocessingSuccessfullCollection"
  [displayedColumns]="displayedColumns4"
></app-table>

<app-table
  (parentFunc)="table5rowFunc($event)"
  [sizeOptions]="sizeOptions5"
  [Title]="invalid5Title"
  [Collection]="dataprocessingUnsuccessfullCollection"
  [displayedColumns]="displayedColumns5"
></app-table>
