<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="start 2 0">
      <h1>Umsókn:</h1>
      <mat-list dense>
        <mat-list-item> Tímabil: {{ caseDoc?.periodstart }} - {{ caseDoc?.periodend }} </mat-list-item>
        <mat-list-item> Fjöldi: {{ this.invoiceCount | number: '1.0-0':'is' }} </mat-list-item>
        <mat-list-item> Samtals VSK: {{ this.totalAmountVAT | number: '1.0-0':'is' }} </mat-list-item>
      </mat-list>
    </div>
    <div>
      <mat-list dense>
        <div *ngIf="this.caseDoc?.applicationPdfUrl; else noapplicationPdfUrl">
          <mat-list-item> <mat-icon style="color:darkgreen">check_circle_outline</mat-icon> <a download href="{{ this.caseDoc.applicationPdfUrl }}"> Umsóknareyðublað - PDF </a> </mat-list-item>
        </div>

        <div *ngIf="this.caseDoc?.allInvoicesPdfUrl; else noallInvoicesPdfUrl">
          <div *ngIf="this.caseDoc.allInvoicesPdfUrl == 'none'; else showUrls">
            <mat-list-item> <mat-icon style="color:darkgreen">check_circle_outline</mat-icon> Öll pdf komu skönnuð </mat-list-item>
          </div>
        </div>
      </mat-list>
    </div>
    <div>
      <button type="button" mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>
</mat-card>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent>
    <button type="button" mat-menu-item (click)="prepareUmsokn()" [disabled]="this.caseDoc.applicationPdfUrl"><mat-icon>assignment</mat-icon>Útbúa umsóknareyðublað.</button>
    <button type="button" mat-menu-item (click)="createPdf()" [disabled]="this.caseDoc.allInvoicesPdfUrl"><mat-icon>picture_as_pdf</mat-icon>Sameina alla reikninga í eitt pdf</button>
    <button type="button" mat-menu-item (click)="dlExcel()"><mat-icon>save_alt</mat-icon>Sækja Excel yfirlit</button>
    <mat-divider> </mat-divider><button type="button" mat-menu-item (click)="this.confirmClosed()"><mat-icon>save_alt</mat-icon>Merkja málið sem lokið</button>
  </ng-template>
</mat-menu>

<ng-template #noallInvoicesPdfUrl>
  <mat-list-item style="color:rgb(100, 0, 0)">
    <div *ngIf="!this.caseDoc?.combiningInvoices"><mat-icon>error_outline</mat-icon>Allir reikningar - PDF</div>
    <div *ngIf="this.caseDoc?.combiningInvoices"><mat-spinner [diameter]="20"></mat-spinner></div>
  </mat-list-item>
</ng-template>

<ng-template #noapplicationPdfUrl>
  <mat-list-item style="color:rgb(100, 0, 0)"> <mat-icon>error_outline</mat-icon> Umsóknareyðublað - PDF </mat-list-item>
</ng-template>

<ng-template #showUrls>
  <div *ngFor="let item of this.caseDoc.allInvoicesPdfUrl">
    <mat-list-item>
      <mat-icon style="color:darkgreen">check_circle_outline</mat-icon> <a download href="{{ item.Url }}"> {{ item.Name }} </a>
    </mat-list-item>
  </div>
</ng-template>
