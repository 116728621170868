import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PDFDocumentFactory, PDFDocumentWriter } from 'pdf-lib';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { FsService } from 'src/app/service/fs.service';
import { finalize } from 'rxjs/operators';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';

@Component({
  selector: 'app-pdf-cutter',
  templateUrl: './pdf-cutter.component.html',
  styleUrls: ['./pdf-cutter.component.css']
})
export class PdfCutterComponent implements OnInit, OnDestroy {
  fileUrl: string;
  filePath: string;
  numPages: Array<any>;
  lengthPages: Array<any>;
  marginTop: Number;
  marginBottom: Number;
  pageWidth: Number;
  clientId: string;
  docId: string;
  clientName: string;
  downloadURL: Observable<string>;
  buttonDisabled: boolean;

  invoiceDoc;
  invoiceDocObs: Observable<any>;
  invoiceDocObsSub: Subscription;

  clientDoc;
  clientDocObs: Observable<any>;
  clientDocObsSub: Subscription;

  constructor(private router: Router, private afs: AngularFirestore, private fsf: FsService, private route: ActivatedRoute, private storage: AngularFireStorage) {}

  ngOnInit() {
    this.buttonDisabled = false;
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.docId = this.route.snapshot.paramMap.get('p2');

    this.clientDocObs = this.fsf.getClient(this.clientId);
    this.clientDocObsSub = this.clientDocObs.subscribe(res => {
      this.clientDoc = res;
      this.clientName = this.clientDoc.name;
    });

    this.invoiceDocObs = this.fsf.getInboxInvoiceDoc(this.clientId, this.docId);
    this.invoiceDocObsSub = this.invoiceDocObs.subscribe(res => {
      this.invoiceDoc = res;
      this.fileUrl = this.invoiceDoc.downloadUrl;
      this.filePath = this.invoiceDoc.path;
    });
  }

  pageRendered(e) {
    this.marginTop = Number(e.source.canvas.clientHeight - 35);
    this.marginBottom = Number(e.source.canvas.clientHeight - 34);
    this.pageWidth = -Number(e.source.canvas.clientWidth / 1.5);
  }

  loadComplete(e) {
    this.numPages = new Array();

    for (var i = 1; i <= e._pdfInfo.numPages; i++) {
      this.numPages.push(i);
    }
  }

  getCheckBoxStatus() {
    this.buttonDisabled = true;
    let array = new Array();
    for (let index = 0; index < this.numPages.length - 1; index++) {
      this.numPages;
      const element = document.getElementById(String(index)) as HTMLInputElement;
      array.push(element.checked);
    }
    return array;
  }

  async cutMultiple() {
    const array = this.getCheckBoxStatus();
    const preparedArray = new Array();
    let lastpage = 0;

    for (let index = 0; index < array.length + 1; index++) {
      const element = array[index];
      if (element == true) {
        preparedArray.push({ firstpage: lastpage, lastpage: index });
        lastpage = index + 1;
      }
    }
    preparedArray.push({ firstpage: lastpage, lastpage: this.numPages.length - 1 });
    await fetch(this.fileUrl).then(async response => {
      const buffer = response.arrayBuffer();

      await buffer.then(async res => {
        const test = new Uint8Array(res);
        const firstDonorPdfDoc = PDFDocumentFactory.load(test);
        const Pages = firstDonorPdfDoc.getPages();

        for (let index = 0; index < preparedArray.length; index++) {
          const element = preparedArray[index];
          const newPdf = PDFDocumentFactory.create();

          for (let index2 = element.firstpage; index2 < element.lastpage + 1; index2++) {
            const page = Pages[index2];
            newPdf.addPage(page);
          }

          const length = Number(this.filePath.length - 4);
          const newNumber = index + 1;
          const newpath = this.filePath.substring(0, length) + ' - ' + newNumber + '.pdf';
          const PdfBytes = await PDFDocumentWriter.saveToBytes(newPdf);
          this.uploadPdf(PdfBytes, newpath);
        }
      });
    });
    this.fsf.deleteDocFromInbox(this.clientId, this.docId, this.filePath);
    this.router.navigateByUrl('/inbox/' + this.clientId);
  }

  onCheckboxClick(test) {}

  uploadPdf(file, path) {
    const metaData = {
      contentType: 'applicaton/pdf'
    };
    const fileRef = this.storage.ref(path);
    const task = fileRef.put(file, metaData);
    task
      .snapshotChanges()
      .pipe(
        // tslint:disable-next-line:max-line-length
        finalize(() => {
          fileRef.getMetadata().subscribe(test => test.fileSize);
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(val => {
            this.afs
              .collection('clients')
              .doc(this.clientId)
              .collection('inbox')
              .add({
                path,
                downloadUrl: val,
                size: '',
                issuer: '',
                servicetype: '',
                invoicenumber: '',
                creditinvoice: false,
                date: '',
                amount: '',
                currency: '',
                vatamount: '',
                vatcurrency: '',
                exchangerate: '',
                issuerid: '',
                issueremail: '',
                issuerdocid: ''
              })
              .then((docRef: DocumentReference) => {
                const data = { clientId: this.clientId, docId: docRef.id, done: false };
                this.fsf.addInvoiceToHakkavelWorkList(data);
              });
          });
        })
      )
      .subscribe();
  }

  deletePdf(path) {
    const fileRef = this.storage.ref(path);
    const task = fileRef.delete();
    task
      .pipe(
        // tslint:disable-next-line:max-line-length
        finalize(() => {
          this.fsf.deleteDocFromInbox(this.clientId, this.docId);
        })
      )
      .subscribe();
  }
  ngOnDestroy() {
    if (this.clientDocObsSub) this.clientDocObsSub.unsubscribe();
    if (this.invoiceDocObsSub) this.invoiceDocObsSub.unsubscribe();
  }
}
