<h2>Breyta stöðluðum texta í gagnaöflunar email</h2>
<form [formGroup]="editDefaultsForm" (ngSubmit)="submitHandler()">
  <mat-form-field class="item" style="width: 800px">
    <textarea matTextareaAutosize matAutosizeMinRows=10
matInput placeholder="Staðlaður email texti" formControlName="defaulttext"></textarea>
  </mat-form-field>

  <div class="button-container">
    <button class="submit-button" mat-raised-button color="primary" type="submit" [disabled]="editDefaultsForm.invalid">
      Staðfesta
    </button>
  </div>
</form>
