<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="start 2 0">
      <h1>Umsókn:</h1>
      <mat-list dense>
        <mat-list-item> Tímabil: {{ this.caseDoc?.periodstart }} - {{ caseDoc?.periodend }} </mat-list-item>
        <mat-list-item> Fjöldi: {{ this.caseDoc?.totalInvoices }} </mat-list-item>
        <mat-list-item> Samtals VSK: {{ this.caseDoc?.totalVatAmount | number: '1.0-0':'is' }} </mat-list-item>
      </mat-list>
    </div>
    <div></div>
    <div>
      <div *ngIf="Invoices?.length > 0">
        <button mat-icon-button [matMenuTriggerFor]="appMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>

      <div *ngIf="Invoices?.length == 0">
        <button mat-icon-button [matMenuTriggerFor]="appDeleteMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-card>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="fixCaseInfo()"><mat-icon>check_circle</mat-icon>Lagfæra samtölur eftir Json Import</button>
    <button mat-menu-item (click)="moveCaseToSubmitted()"><mat-icon>check_circle</mat-icon>Staðfesta mál til RSK</button>
  </ng-template>
</mat-menu>

<mat-menu #appDeleteMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="deleteCase()"><mat-icon>get_app</mat-icon>Eyða umsókn</button>
    <button mat-menu-item (click)="openDialog()"><mat-icon>get_app</mat-icon>Import Json</button>
  </ng-template>
</mat-menu>
