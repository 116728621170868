// Core components & Angular specifics
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

//Angular Material Components
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatBadgeModule } from '@angular/material/badge';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// Firebase stuff
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

// 3rd Party components
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxChartsModule } from '@swimlane/ngx-charts';

// Custom components
import { NavComponent } from './ui/nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ViewClientsComponent } from './ui/pages/view-clients/view-clients.component';
import { EditClientComponent } from './ui/pages/edit-client/edit-client.component';
import { ViewClientComponent } from './ui/pages/view-client/view-client.component';
import { NewCaseComponent } from './ui/pages/new-case/new-case.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FileSizePipe } from './pipes/file-size.pipe';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { DropZoneComponent } from './ui/components/drop-zone/drop-zone.component';
import { PdfViewerComponent } from './ui/components/pdf-viewer/pdf-viewer.component';
import { NewClientComponent } from './ui/pages/new-client/new-client.component';
import { ViewIssuersComponent } from './ui/pages/view-issuers/view-issuers.component';
import { NewIssuerComponent } from './ui/pages/new-issuer/new-issuer.component';
import { EditIssuerComponent } from './ui/pages/edit-issuer/edit-issuer.component';
import { InvalidFormComponent } from './ui/components/invalid-form/invalid-form.component';
import { ViewInvalidComponent } from './ui/pages/view-invalid/view-invalid.component';
import { ViewInvalidInvoiceComponent } from './ui/pages/view-invalid-invoice/view-invalid-invoice.component';
import { ViewFaultsComponent } from './ui/pages/view-faults/view-faults.component';
import { NewFaultComponent } from './ui/pages/new-fault/new-fault.component';
import { EditFaultComponent } from './ui/pages/edit-fault/edit-fault.component';
import { InboxComponent } from './ui/pages/inbox/inbox.component';
import { InboxAmountComponent } from './ui/components/inbox-amount/inbox-amount.component';
import { ViewInvoiceComponent } from './ui/pages/view-invoice/view-invoice.component';
import { InvoiceFormComponent } from './ui/components/invoice-form/invoice-form.component';
import { ViewCaseComponent } from './ui/pages/view-case/view-case.component';
import { InvoiceControlsComponent } from './ui/components/invoice-controls/invoice-controls.component';
import { LoginComponent } from './ui/pages/login/login.component';
import { DataCollectionAmountComponent } from './ui/components/data-collection-amount/data-collection-amount.component';
import { AwaitingResponseAmountComponent } from './ui/components/awaiting-response-amount/awaiting-response-amount.component';
import { ClientInfoComponent } from './ui/components/client-info/client-info.component';
import { TableComponent } from './ui/components/table/table.component';

import { registerLocaleData } from '@angular/common';
import localeIs from '@angular/common/locales/is';
import localeIsExtra from '@angular/common/locales/extra/is';
import { EditClientDocumentsComponent } from './ui/pages/edit-client-documents/edit-client-documents.component';
import { UnusableAmountComponent } from './ui/components/unusable-amount/unusable-amount.component';
import { ViewUnusableComponent } from './ui/pages/view-unusable/view-unusable.component';
import { ViewUnusableInvoiceComponent } from './ui/pages/view-unusable-invoice/view-unusable-invoice.component';
import { ClientDocsInfoComponent } from './ui/components/client-docs-info/client-docs-info.component';
import { ViewCaseInvoiceComponent } from './ui/pages/view-case-invoice/view-case-invoice.component';
import { CaseInvoiceFormComponent } from './ui/components/case-invoice-form/case-invoice-form.component';
import { EditDataCollectionDefaultsComponent } from './ui/components/edit-data-collection-defaults/edit-data-collection-defaults.component';
import { ViewDatacollectionInvoiceComponent } from './ui/pages/view-datacollection-invoice/view-datacollection-invoice.component';
import { ViewDatacollectionsentInvoiceComponent } from './ui/pages/view-datacollectionsent-invoice/view-datacollectionsent-invoice.component';
import { DatacollectionFormComponent } from './ui/components/datacollection-form/datacollection-form.component';
import { DatacollectionsentFormComponent } from './ui/components/datacollectionsent-form/datacollectionsent-form.component';
import { UnusableFormComponent } from './ui/components/unusable-form/unusable-form.component';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { PdfCutterComponent } from './ui/pages/pdf-cutter/pdf-cutter.component';
import { ConfirmDeleteInboxInvoiceComponent } from './ui/components/confirm-delete-inbox-invoice/confirm-delete-inbox-invoice.component';
import { ViewSubmittedCaseComponent } from './ui/pages/view-submitted-case/view-submitted-case.component';
import { ViewSubmittedCaseInvoiceComponent } from './ui/pages/view-submitted-case-invoice/view-submitted-case-invoice.component';
import { SubmittedCaseInvoiceFormComponent } from './ui/components/submitted-case-invoice-form/submitted-case-invoice-form.component';
import { DashboardComponent } from './ui/pages/dashboard/dashboard.component';
import { Card1Component } from './ui/pages/dashboard/card1/card1.component';
import { Card2Component } from './ui/pages/dashboard/card2/card2.component';
import { DatacollectionComponent } from './ui/pages/dashboard/datacollection/datacollection.component';
import { CasePdfInfoComponent } from './ui/components/case-pdf-info/case-pdf-info.component';
import { CaseInfoComponent } from './ui/components/case-info/case-info.component';
import { NgArrayPipesModule } from 'angular-pipes';
import { JsonImportComponent } from './ui/components/json-import/json-import.component';
import { AdminComponent } from './ui/pages/admin/admin.component';
import { ViewClosedCaseComponent } from './ui/pages/view-closed-case/view-closed-case.component';
import { ViewClosedCaseInvoiceComponent } from './ui/pages/view-closed-case-invoice/view-closed-case-invoice.component';
import { CaseClosedInfoComponent } from './ui/components/case-closed-info/case-closed-info.component';
import { ViewAffiliatesComponent } from './ui/pages/view-affiliates/view-affiliates.component';
import { NewAffiliateComponent } from './ui/pages/new-affiliate/new-affiliate.component';
import { EmailLoginComponent } from './ui/pages/email-login/email-login.component';
import { ViewUsersComponent } from './ui/pages/view-users/view-users.component';

// the second parameter 'fr' is optional
registerLocaleData(localeIs, 'is', localeIsExtra);

@NgModule({
  declarations: [
    AppComponent,
    NewClientComponent,
    NavComponent,
    ViewClientsComponent,
    EditClientComponent,
    ViewClientComponent,
    NewCaseComponent,
    FileSizePipe,
    DropZoneDirective,
    DropZoneComponent,
    PdfViewerComponent,
    InboxComponent,
    InboxAmountComponent,
    ViewInvoiceComponent,
    InvoiceFormComponent,
    ViewCaseComponent,
    InvoiceControlsComponent,
    LoginComponent,
    ViewIssuersComponent,
    NewIssuerComponent,
    EditIssuerComponent,
    InvalidFormComponent,
    ViewInvalidComponent,
    ViewInvalidInvoiceComponent,
    ViewFaultsComponent,
    NewFaultComponent,
    EditFaultComponent,
    DataCollectionAmountComponent,
    AwaitingResponseAmountComponent,
    ClientInfoComponent,
    TableComponent,
    EditClientDocumentsComponent,
    UnusableAmountComponent,
    ViewUnusableComponent,
    ViewUnusableInvoiceComponent,
    ClientDocsInfoComponent,
    ViewCaseInvoiceComponent,
    CaseInvoiceFormComponent,
    EditDataCollectionDefaultsComponent,
    ViewDatacollectionInvoiceComponent,
    ViewDatacollectionsentInvoiceComponent,
    DatacollectionFormComponent,
    DatacollectionsentFormComponent,
    UnusableFormComponent,
    PdfCutterComponent,
    ConfirmDeleteInboxInvoiceComponent,
    ViewSubmittedCaseComponent,
    ViewSubmittedCaseInvoiceComponent,
    SubmittedCaseInvoiceFormComponent,
    DashboardComponent,
    Card1Component,
    Card2Component,
    DatacollectionComponent,
    CasePdfInfoComponent,
    CaseInfoComponent,
    JsonImportComponent,
    AdminComponent,
    ViewClosedCaseComponent,
    ViewClosedCaseInvoiceComponent,
    CaseClosedInfoComponent,
    ViewAffiliatesComponent,
    NewAffiliateComponent,
    EmailLoginComponent,
    ViewUsersComponent
  ],
  entryComponents: [
    EditClientComponent,
    ViewClientsComponent,
    NewCaseComponent,
    NewIssuerComponent,
    EditIssuerComponent,
    NewFaultComponent,
    EditFaultComponent,
    EditClientDocumentsComponent,
    EditDataCollectionDefaultsComponent,
    ConfirmDeleteInboxInvoiceComponent,
    JsonImportComponent,
    NewAffiliateComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    FlexLayoutModule,
    NativeDateModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatNativeDateModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatBadgeModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    LayoutModule,
    MatMomentDateModule,
    PdfViewerModule,
    HttpClientModule,
    AngularFireFunctionsModule,
    NgxChartsModule,
    NgArrayPipesModule
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'is' },
    { provide: FirestoreSettingsToken, useValue: {} },
    { provide: LOCALE_ID, useValue: 'is' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
