<mat-card>
  <div class="dropzone" dropZone (hovered)="toggleHover($event)" (dropped)="startUpload($event)" [class.hovering]="isHovering" fxLayout="column" fxLayoutAlign="start center">
    <div  style="margin-top:30px; margin-bottom:30px;"><img height="80px" src="assets/images/download.svg"/></div>

      <h2 class="mat-h2">Inbox: <app-inbox-amount></app-inbox-amount></h2>
    <div class="file">
      <input multiple="multiple" class="file-input" type="file" (change)="startUpload($event.target.files)" />
    </div>

    <div fxLayout="column" fxLayoutAlign="end center">
      <div *ngIf="this.perc != 100">
        <div *ngIf="this.perc as pct" class="centertext">
          <mat-progress-bar mode="determinate" class="progressbar" [value]="pct"> </mat-progress-bar>
          {{ this.perc | number }}%
        </div>
      </div>
      <div *ngIf="this.wrongFileType">
        <mat-card style="background-color:red !important">Þú reyndir að senda eitthvað annað en PDF - sleppi þeim</mat-card>
      </div>
    </div>
  </div>
</mat-card>
