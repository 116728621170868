<mat-card
  ><div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div style="margin-top:30px; margin-bottom:30px"><img height="80px" src="assets/images/transfer.svg"/></div>
    <mat-list>
      <mat-list-item
        ><h2 class="mat-h2">Ómerkt: {{ this.infoNeededNumber }}</h2>
      </mat-list-item>
      <mat-list-item>
        <h2 class="mat-h2">Ósent: {{ this.readyForGmailNumber }}</h2>
      </mat-list-item>
      <mat-list-item>
        <h2 class="mat-h2">Bíðum svars: {{ this.datacollectionSentNumber }}</h2>
      </mat-list-item>
    </mat-list>
  </div></mat-card
>
