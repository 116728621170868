<div class="container">
  <div class="filler"></div>
  <div class="form-container">
    <mat-card>
      <mat-card-title>Login</mat-card-title>
      <mat-card-content>
        <div class="col-md-6 col-md-offset-3">
          <form [formGroup]="loginForm" (ngSubmit)="signIn()">
            <mat-form-field class="item">
              <input matInput placeholder="Email" formControlName="email" />
            </mat-form-field>

            <mat-form-field class="item">
              <input matInput type="password" placeholder="Password" formControlName="password" />
            </mat-form-field>
            <button class="submit-button" mat-raised-button color="primary" type="submit" [disabled]="loginForm.invalid">
              Submit
            </button>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="filler"></div>
</div>
