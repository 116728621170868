import { Component, OnInit, OnDestroy } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-card1',
  templateUrl: './card1.component.html',
  styleUrls: ['./card1.component.css']
})
export class Card1Component implements OnInit, OnDestroy {
  Formatting = value => `${value.toLocaleString('DE-DE')}`;

  data;

  dataSub: Subscription;

  // options
  single: any[];
  multi: any[];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  constructor(private fsf: FsService) {}

  ngOnInit() {
    const temp = this.fsf.getAllClients('totalVatAmount');

    this.dataSub = temp.subscribe((res: any) => {
      this.data = [];
      for (let index = 0; index < res.length; index++) {
        this.data.push({ name: res[index].name, value: res[index].totalVatAmount });
      }
    });
  }

  onSelect(event) {}

  ngOnDestroy() {
    if (this.dataSub) this.dataSub.unsubscribe();
  }
}
