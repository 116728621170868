<mat-card>
  <mat-card-header>
    <h1>Staðfestar upplýsingar</h1>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <div *ngIf="this.currentInvoice">
        <mat-list-item>
          <div class="left mat-h3">Seljandi:</div>
          <div class="right mat-h3">{{ this.currentInvoice.issuer }}</div>
        </mat-list-item>
        <mat-list-item>
          <div class="left mat-h3">Reikningsnúmer:</div>
          <div class="right mat-h3">{{ this.currentInvoice.invoicenumber }}</div>
        </mat-list-item>
        <mat-list-item>
          <div class="left mat-h3">Gallar:</div>
          <div class="right mat-h3">
            {{ this.currentInvoice.faults }}
          </div>
        </mat-list-item>
      </div>
    </mat-list>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button type="button" mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent>
    <button type="button" mat-menu-item (click)="restartDatacollection()"> <mat-icon>fast_rewind</mat-icon>Færa tilbaka í óskráð</button>
  </ng-template>
</mat-menu>
