<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlex="auto"><h1>Tilvísunaraðilar</h1></div>
    <div fxFlex="grow" style="text-align:center">
      <mat-form-field style="width:80%">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Leit" />
      </mat-form-field>
    </div>
    <div fxFlex="auto" style="margin-top:15px">
      <a (click)="NewAffiliate()">
        <button mat-raised-button color="primary"><mat-icon>person_add</mat-icon> Stofna</button>
      </a>
    </div>
  </div>

  <mat-table #table [dataSource]="dataSource" class="animate">
    <ng-container matColumnDef="displayName">
      <mat-header-cell *matHeaderCellDef> Tilvísunaraðili</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row class="highlight" *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[50, 100]" showFirstLastButtons></mat-paginator>
</mat-card>
