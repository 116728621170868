import { Component, OnInit } from '@angular/core';

import { GapiService } from 'src/app/service/gapi.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  constructor(public gapi: GapiService) { }

  ngOnInit() {
  }

}
