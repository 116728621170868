import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { Observable, Subscription } from 'rxjs';
import { Application } from 'src/app/shared/dbinterfaces';

import { FsService } from 'src/app/service/fs.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-case',
  templateUrl: './new-case.component.html',
  styleUrls: ['./new-case.component.css']
})
export class NewCaseComponent implements OnInit, OnDestroy {
  newCaseForm: FormGroup;
  periodstart: string;
  periodend: string;
  combined;
  casesCollection: Observable<any>;
  applications: Application[] = [];
  clientId: string;
  currentClientIdSub: Subscription;
  casesCollectionSub: Subscription;

  minDate = new Date(2016, 0, 1);
  maxDate: Date;

  Moment = extendMoment(moment);

  // tslint:disable-next-line:max-line-length
  constructor(private fsf: FsService, private route: ActivatedRoute, private fb: FormBuilder, public dialogRef: MatDialogRef<NewCaseComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    // set maxDate
    const currentDate = new Date();
    var year = currentDate.getFullYear();
    this.maxDate = new Date(year, 11, 31);

    // gather pre-existing cases
    this.clientId = this.data;
    this.casesCollection = this.fsf.getClientNotSubmittedCases(this.clientId);
    this.casesCollectionSub = this.casesCollection.subscribe(res => {
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        const viewValue = element.periodstart + ' - ' + element.periodend;
        this.applications[index] = {
          viewValue: viewValue,
          value: element.id,
          periodStart: element.periodstart,
          periodEnd: element.periodend
        };
      }
    });
  }

  startFilter = (d: moment.Moment): boolean => {
    const day = d.date();
    const month = d.month();

    //Only allow first of every other month.
    return (day == 1 && month == 0) || (day == 1 && month == 2) || (day == 1 && month == 4) || (day == 1 && month == 6) || (day == 1 && month == 8) || (day == 1 && month == 10);
  };

  endFilter = (d: moment.Moment): boolean => {
    const year = d.year();
    const day = d.date();
    const month = d.month();
    const calendaryear = moment(this.newCaseForm.value.periodstart).year();
    if (moment(this.newCaseForm.value.periodstart).month() === 0) {
      // get Last day of Feb (in case of leap year)
      const lastdayOfFeb = Number(
        moment(calendaryear + '0201', 'YYYYMMDD')
          .endOf('month')
          .format('DD')
      );

      return (day == 31 && month == 11 && year == calendaryear) || (day == lastdayOfFeb && month == 1 && year == calendaryear);
    }
    if (moment(this.newCaseForm.value.periodstart).month() === 2) {
      return day == 30 && month == 3 && year == calendaryear;
    } else if (moment(this.newCaseForm.value.periodstart).month() === 4) {
      return day == 30 && month == 5 && year == calendaryear;
    } else if (moment(this.newCaseForm.value.periodstart).month() === 6) {
      return day == 31 && month == 7 && year == calendaryear;
    } else if (moment(this.newCaseForm.value.periodstart).month() === 8) {
      return day == 31 && month == 9 && year == calendaryear;
    } else if (moment(this.newCaseForm.value.periodstart).month() === 10) {
      return day == 31 && month == 11 && year == calendaryear;
    }
  };

  ngOnInit() {
    this.newCaseForm = this.fb.group({
      periodstart: ['', Validators.required],
      periodend: ['', Validators.required]
    });
  }

  async submitHandler() {
    let formValue = this.newCaseForm.value;
    formValue.periodstart = moment(formValue.periodstart).format('L');
    formValue.periodend = moment(formValue.periodend).format('L');
    formValue.totalInvoices = 0;
    formValue.totalVatAmount = 0;
    formValue.totalAmount = 0;
    formValue.status = 'notSubmitted';
    try {
      this.fsf.createCase(this.clientId, formValue);
    } finally {
      this.dialogRef.close();
    }
  }

  filter = (d: Date): boolean => {
    const day = d.getDay();
    return day !== 0 && day !== 6;
  };

  dateChanged($event) {
    this.newCaseForm.controls['periodend'].setValue('');
    this.testIfAlreadyExists($event);
  }

  testIfAlreadyExists(date) {
    for (let index = 0; index < this.applications.length; index++) {
      const startdate = moment(this.applications[index].periodStart, 'DD.MM.YYYY').toDate();
      const enddate = moment(this.applications[index].periodEnd, 'DD.MM.YYYY').toDate();
      const range = this.Moment.range(startdate, enddate);
      if (range.contains(date)) {
        this.newCaseForm.controls['periodstart'].setValue('');
        this.newCaseForm.controls['periodstart'].setErrors({ exists: true });
      } else {
      }
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.casesCollectionSub) this.casesCollectionSub.unsubscribe();
    if (this.currentClientIdSub) this.currentClientIdSub.unsubscribe();
  }
}
