<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="false">
    <mat-nav-list>
      <div *ngIf="this.userId == 'iFjz5TrgpwUuZGYYGqw5YvDCkVP2'">
        <a mat-list-item routerLink="Admin"><i class="material-icons"> settings</i>&nbsp; Admin</a>
      </div>
      <a mat-list-item routerLink="Dashboard"><i class="material-icons"> dashboard </i>&nbsp; Stjórnborð</a>
      <a mat-list-item routerLink="ClientList"><i class="material-icons"> person </i>&nbsp; Viðskiptavinir</a>
      <a mat-list-item routerLink="IssuersList"><i class="material-icons"> domain </i>&nbsp; Þjónustuveitendur</a>
      <a mat-list-item routerLink="faults"><i class="material-icons"> warning </i>&nbsp; Gallar</a>
      <a mat-list-item routerLink="ViewUsers"><i class="material-icons"> local_atm </i>&nbsp; Notendur</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar style="background-color: white">
      <div fxLayout="row" fxLayoutAlign="space-between center" style="width:100%">
        <div>
          <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
            <mat-icon aria-label="Side nav toggle icon"> menu </mat-icon>
          </button>
        </div>
        <div fxFlex="start" style="margin-left:10px;">
          <mat-form-field style="max-width:350px;width:100%">
            <input type="text" matInput [matAutocomplete]="auto" [formControl]="myControl" />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="clientClick($event.option.value)">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        {{ this.clientName }}

        <div fxFlex="start"><img src="assets/images/logko_logo_hlid.png" /></div>

        <div class="hand">
          <button mat-icon-button [matMenuTriggerFor]="appMenu"><mat-icon>account_circle</mat-icon></button>
        </div>
      </div>
    </mat-toolbar>
    <div class="outer-container"><router-outlet></router-outlet></div>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="logout()">Útskrá</button>
</mat-menu>
