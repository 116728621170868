import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewClientsComponent } from './ui/pages/view-clients/view-clients.component';
import { ViewClientComponent } from './ui/pages/view-client/view-client.component';
import { NewClientComponent } from './ui/pages/new-client/new-client.component';
import { InboxComponent } from './ui/pages/inbox/inbox.component';
import { ViewInvoiceComponent } from './ui/pages/view-invoice/view-invoice.component';
import { ViewCaseComponent } from './ui/pages/view-case/view-case.component';
import { ViewIssuersComponent } from './ui/pages/view-issuers/view-issuers.component';
import { ViewInvalidComponent } from './ui/pages/view-invalid/view-invalid.component';
import { ViewInvalidInvoiceComponent } from './ui/pages/view-invalid-invoice/view-invalid-invoice.component';
import { ViewFaultsComponent } from './ui/pages/view-faults/view-faults.component';
import { ViewUnusableComponent } from './ui/pages/view-unusable/view-unusable.component';
import { ViewUnusableInvoiceComponent } from './ui/pages/view-unusable-invoice/view-unusable-invoice.component';
import { ViewCaseInvoiceComponent } from './ui/pages/view-case-invoice/view-case-invoice.component';
import { ViewDatacollectionInvoiceComponent } from './ui/pages/view-datacollection-invoice/view-datacollection-invoice.component';
import { ViewDatacollectionsentInvoiceComponent } from './ui/pages/view-datacollectionsent-invoice/view-datacollectionsent-invoice.component';
import { PdfCutterComponent } from './ui/pages/pdf-cutter/pdf-cutter.component';
import { ViewSubmittedCaseComponent } from './ui/pages/view-submitted-case/view-submitted-case.component';
import { ViewSubmittedCaseInvoiceComponent } from './ui/pages/view-submitted-case-invoice/view-submitted-case-invoice.component';
import { DashboardComponent } from './ui/pages/dashboard/dashboard.component';
import { AdminComponent } from './ui/pages/admin/admin.component';
import { ViewClosedCaseComponent } from './ui/pages/view-closed-case/view-closed-case.component';
import { ViewClosedCaseInvoiceComponent } from './ui/pages/view-closed-case-invoice/view-closed-case-invoice.component';
import { ViewUsersComponent } from './ui/pages/view-users/view-users.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'Dashboard', component: DashboardComponent },
  { path: 'Admin', component: AdminComponent },
  { path: 'IssuersList', component: ViewIssuersComponent },
  { path: 'ViewUsers', component: ViewUsersComponent },
  { path: 'ClientList', component: ViewClientsComponent },
  { path: 'viewclient/:p1', component: ViewClientComponent, runGuardsAndResolvers: 'paramsChange' },
  { path: 'newclient', component: NewClientComponent },
  { path: 'inbox/:p1', component: InboxComponent },
  { path: 'viewinvoice/:p1/:p2', component: ViewInvoiceComponent },
  { path: 'viewcaseinvoice/:p1/:p2/:p3', component: ViewCaseInvoiceComponent },
  { path: 'viewsubmittedcaseinvoice/:p1/:p2/:p3', component: ViewSubmittedCaseInvoiceComponent },
  { path: 'case/:p1/:p2', component: ViewCaseComponent },
  { path: 'submittedcase/:p1/:p2', component: ViewSubmittedCaseComponent },
  { path: 'datacollection/:p1', component: ViewInvalidComponent },
  { path: 'unusable/:p1', component: ViewUnusableComponent },
  { path: 'viewinvalidinvoice/:p1/:p2', component: ViewInvalidInvoiceComponent },
  { path: 'viewunusableinvoice/:p1/:p2', component: ViewUnusableInvoiceComponent },
  { path: 'faults', component: ViewFaultsComponent },
  { path: 'viewdatacollectioninvoice/:p1/:p2', component: ViewDatacollectionInvoiceComponent },
  { path: 'viewdatacollectionsentinvoice/:p1/:p2', component: ViewDatacollectionsentInvoiceComponent },
  { path: 'pdfcutter/:p1/:p2', component: PdfCutterComponent },
  { path: 'closedcase/:p1/:p2', component: ViewClosedCaseComponent },
  { path: 'viewclosedcaseinvoice/:p1/:p2/:p3', component: ViewClosedCaseInvoiceComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
