import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FsService } from 'src/app/service/fs.service';
import { registerLocaleData } from '@angular/common';
import localeIs from '@angular/common/locales/is';

@Component({
  selector: 'app-view-closed-case',
  templateUrl: './view-closed-case.component.html',
  styleUrls: ['./view-closed-case.component.css']
})
export class ViewClosedCaseComponent implements OnInit {
  clientId: string;
  caseId: string;
  closedCaseCollection: Observable<any>;
  closedCaseCollectionSub: Subscription;
  closedCases: {};

  Title = 'Reikningar í umsókn';
  displayedColumns: { [key: string]: string } = {
    finalrefnumber: 'Vinnslunúmer',
    date: 'Dagsetning',
    issuer: 'Þjónustuveitandi',
    invoicenumber: 'Nr. reiknings',
    iskvat: 'Fjárhæð vsk ISK'
  };
  sizeOptions = ['50', '100'];
  sortOptions: { [key: string]: string } = { id: 'finalrefnumber', start: 'asc' };

  constructor(private route: ActivatedRoute, private fsf: FsService, private router: Router) {
    registerLocaleData(localeIs, 'is');
  }

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.caseId = this.route.snapshot.paramMap.get('p2');

    this.closedCaseCollection = this.fsf.getSortedCaseInvoices(this.clientId, this.caseId);
    this.closedCaseCollectionSub = this.closedCaseCollection.subscribe(res => {
      this.closedCases = res;
    });
  }

  table1rowFunc(row) {
    this.router.navigateByUrl('/viewclosedcaseinvoice/' + this.clientId + '/' + row.id + '/' + this.caseId);
  }

  ngOnDestroy() {
    if (this.closedCaseCollectionSub) {
      this.closedCaseCollectionSub.unsubscribe();
    }
  }
}
