<form [formGroup]="newClientForm" (ngSubmit)="submitHandler()">
  <mat-form-field class="item">
    <input matInput placeholder="Client Name" formControlName="name" />
  </mat-form-field>

  <mat-form-field class="item">
    <input matInput placeholder="Netfang" formControlName="email" />
  </mat-form-field>

  <mat-form-field class="item">
    <input matInput placeholder="Kennitala / Id Númer" formControlName="idnumber" />
  </mat-form-field>

  <mat-form-field class="item">
    <input matInput placeholder="Heimilisfang" formControlName="address1" />
  </mat-form-field>

  <mat-form-field class="item">
    <input matInput placeholder="Póstnúmer" formControlName="address2" />
  </mat-form-field>

  <mat-form-field class="item">
    <input matInput placeholder="Bæjarfélag" formControlName="address3" />
  </mat-form-field>

  <mat-form-field class="item">
    <input matInput placeholder="Land" formControlName="country" />
  </mat-form-field>

  <div formArrayName="contacts">
    <div *ngFor="let contact of contactForms.controls; let i = index" [formGroupName]="i" class="contacts-container">
      <mat-form-field class="contacts-item">
        <input matInput placeholder="Contact Name" formControlName="contactname" />
      </mat-form-field>

      <mat-form-field class="contacts-item">
        <input matInput placeholder="Contact Email" formControlName="contactemail" />
      </mat-form-field>

      <mat-form-field class="contacts-item">
        <input matInput placeholder="Contact Phone" formControlName="contactphone" />
      </mat-form-field>
      <button mat-raised-button color="warn" (click)="deleteContact(i)" class="contact-button">
        Delete contact
      </button>
    </div>
  </div>
  <div class="button-container">
    <button mat-raised-button color="accent" type="button" (click)="addContact()">
      Add Contact
    </button>
    <button class="submit-button" mat-raised-button color="primary" type="submit" [disabled]="newClientForm.invalid">
      Submit
    </button>
  </div>
</form>
