import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { FsService } from '../../../service/fs.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.css']
})
export class NewClientComponent implements OnInit {
  newClientForm: FormGroup;

  // Form state
  loading = false;
  success = false;
  newData = { count: 0 };

  // tslint:disable-next-line:max-line-length
  constructor(private fns: AngularFireFunctions, private fb: FormBuilder, private fsf: FsService, public dialogRef: MatDialogRef<NewClientComponent>) {}

  ngOnInit() {
    this.newClientForm = this.fb.group({
      name: '',
      email: '',
      idnumber: '',
      address1: '',
      address2: '',
      address3: '',
      country: '',
      contacts: this.fb.array([])
    });
  }

  async submitHandler() {
    this.loading = true;

    const formValue = this.newClientForm.value;
    formValue.inbox_counter = 0;
    formValue.totalInvoices = 0;
    formValue.totalVatAmount = 0;
    formValue.unusable_counter = 0;
    formValue.datacollection_counter = 0;
    formValue.datacollectionReadyForGmail_counter = 0;
    formValue.datacollectionSent_counter = 0;
    formValue.umbod = '';
    formValue.vatcertificate = '';
    formValue.vatagentauthorisation = '';
    formValue.displayName = formValue.name;
    formValue.password = '12345';
    try {
      console.log(formValue);
      await this.fsf.push('clients', formValue);
      await this.callCloudFunctionCreateUser(formValue);
      this.success = true;
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    if (this.loading === false && this.success === true) {
      this.dialogRef.close();
    }
  }

  get contactForms() {
    return this.newClientForm.get('contacts') as FormArray;
  }

  addContact() {
    const contact = this.fb.group({
      contactname: [],
      contactemail: [],
      contactphone: []
    });

    this.contactForms.push(contact);
  }

  deleteContact(i) {
    this.contactForms.removeAt(i);
  }

  callCloudFunctionCreateUser(body) {
    const callable = this.fns.httpsCallable('createUser');
    const Obs = callable({ email: 'borkur@gmail.com', password: '123', displayName: 'name' });
    const Sub = Obs.subscribe(res => {
      console.log(res);
    });
  }
}
