import { Component, OnInit, Input } from '@angular/core';
import { FsService } from 'src/app/service/fs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Invoices, Issuer } from 'src/app/shared/dbinterfaces';
import { MatDialog } from '@angular/material/dialog';
import { JsonImportComponent } from '../json-import/json-import.component';

@Component({
  selector: 'app-case-info',
  templateUrl: './case-info.component.html',
  styleUrls: ['./case-info.component.css']
})
export class CaseInfoComponent implements OnInit {
  clientId: string;
  caseId: string;

  caseDoc;
  caseDocObs: Observable<any>;
  caseDocSub: Subscription;

  issuerDoc: Issuer[];
  issuerDocObs: Observable<any>;
  issuerDocSub: Subscription;

  totalAmountVAT: number;
  invoiceCount: number;

  caseInvoicesDoc;
  caseInovoicesDocObs: Observable<any>;
  caseInvoicesDocSub: Subscription;

  @Input() Invoices: Invoices[];

  constructor(private fsf: FsService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');
    this.caseId = this.route.snapshot.paramMap.get('p2');

    this.caseDocObs = this.fsf.getCase(this.clientId, this.caseId);
    this.caseDocSub = this.caseDocObs.subscribe(res => {
      this.caseDoc = res;
    });
    this.issuerDocObs = this.fsf.getIssuersCollection();
    this.issuerDocSub = this.issuerDocObs.subscribe(res => (this.issuerDoc = res));

    this.caseInovoicesDocObs = this.fsf.getCaseInvoices(this.clientId, this.caseId);
    this.caseInvoicesDocSub = this.caseInovoicesDocObs.subscribe(res => {
      this.caseInvoicesDoc = res;
      this.invoiceCount = this.caseInvoicesDoc.length;
      this.totalAmountVAT = 0;
      for (let index = 0; index < this.caseInvoicesDoc.length; index++) {
        const element = this.caseInvoicesDoc[index];
        this.totalAmountVAT = element.iskvat + this.totalAmountVAT;
      }
    });
  }

  fixCaseInfo() {
    const newData = { totalInvoices: this.invoiceCount, totalVatAmount: this.totalAmountVAT };
    this.fsf.updateCase(this.clientId, this.caseId, newData);
  }

  deleteCase() {
    this.fsf.deleteCase(this.clientId, this.caseId);
    this.router.navigateByUrl('/viewclient/' + this.clientId);
  }

  openDialog(): void {
    const data = { caseId: this.caseId, clientId: this.clientId };
    const editDialogRef = this.dialog.open(JsonImportComponent, {
      data: data
    });
  }

  moveCaseToSubmitted() {
    let setNumbers = [];
    let finishedObject = {};
    for (let index = 0; index < this.Invoices.length; index++) {
      const element = this.Invoices[index];
      if (element.refnumber == 'Auto') {
      } else {
        const number = Number(element.refnumber);
        const data = { finalrefnumber: number };
        this.fsf.updateInvoiceInCase(this.clientId, this.caseId, element.id, data);
        finishedObject[element.refnumber] = this.Invoices[index];
        finishedObject[element.refnumber].finalrefnumber = Number(element.refnumber);
      }
    }
    loop1: for (let index = 0; index < this.Invoices.length; index++) {
      const element = this.Invoices[index];
      if (element.refnumber == 'Auto') {
        loop2: for (let i = 1; i < this.Invoices.length + 1; i++) {
          if (finishedObject[i] == undefined) {
            finishedObject[i] = this.Invoices[index];
            const data = { finalrefnumber: i };
            this.fsf.updateInvoiceInCase(this.clientId, this.caseId, element.id, data);
            break loop2;
          }
        }
      } else {
      }
    }

    this.fsf.moveCaseToSubmitted(this.clientId, this.caseId);
    this.router.navigateByUrl('/viewclient/' + this.clientId);
  }
}
