import { Injectable } from '@angular/core';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var gapi: any;

@Injectable({
  providedIn: 'root'
})
export class GapiService {
  user$: Observable<firebase.User>;
  calendarItems: any[];

  constructor(public afAuth: AngularFireAuth, private snackBar: MatSnackBar) {
    this.initClient();
    this.user$ = afAuth.authState;
  }

  initClient() {
    gapi.load('client', () => {
      // It's OK to expose these credentials, they are client safe.
      gapi.client.init({
        apiKey: 'AIzaSyAY--_U64KjMf1xFEhbUuQGgqO_BBA_pIU',
        clientId: '598266465576-5848etiipm8313f5ktakcnijbqv8e6i5.apps.googleusercontent.com',
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'],
        scope: 'https://mail.google.com/'
      });

      gapi.client.load('gmail', 'v1', () => {});
    });
  }

  async login() {
    console.log('logging in');
    const googleAuth = gapi.auth2.getAuthInstance();
    console.log(googleAuth);
    const googleUser = await googleAuth.signIn();
    console.log(googleUser);
    const token = googleUser.getAuthResponse().id_token;

    const credential = auth.GoogleAuthProvider.credential(token);
    console.log(credential);

    await this.afAuth.auth.signInWithCredential(credential);
  }

  logout() {
    this.afAuth.auth.signOut();
  }

  async createDraft(body, issueremail, callback, clientName, umbod) {
    const userId = 'me';
    const subject = 'Gagnaöflun - ' + clientName;
    const message = body;

    this.fetchAsBlob(umbod)
      .then(this.convertBlobToBase64)
      .then((res: string) => {
        var base64 = res.split(',');

        var base64EncodedEmail = btoa(
          'Content-Type: multipart/mixed; boundary="splitter"\r\n' +
            'From: me\r\n' +
            'To:' +
            issueremail +
            '\r\nSubject: ' +
            subject +
            '\r\n\r\n' +
            '--splitter\r\n' +
            'Content-Type: text; charset="UTF-8"\r\n\r\n' +
            message +
            '\r\n\r\n' +
            '--splitter\r\n' +
            'Content-Type: application/pdf; name=umbod.pdf\r\n' +
            'Content-Transfer-Encoding: base64\r\n' +
            'Content-Disposition: attachment\r\n' +
            base64[1] +
            '\r\n\r\n' +
            '--splitter--'
        )
          .replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/\=+$/, '');

        var request = gapi.client.gmail.users.drafts.create({
          userId: userId,
          resource: {
            message: {
              raw: base64EncodedEmail
            }
          }
        });
        request.execute(callback);
        this.openSnackBar('Bjó til email Draft', 'OK');
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000
    });
  }

  // ... helper function

  hoursFromNow = n => new Date(Date.now() + n * 1000 * 60 * 60).toISOString();

  fetchAsBlob = url => fetch(url).then(response => response.blob());

  convertBlobToBase64 = blob =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
}
