import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.css']
})
export class EmailLoginComponent implements OnInit {
  constructor(private authenticationService: AuthService, private fb: FormBuilder) {}
  loginForm: FormGroup;

  // Form state
  loading = false;
  success = false;
  newData = { count: 0 };

  email: string;
  password: string;

  signUp() {
    const formValue = this.loginForm.value;
    this.authenticationService.SignUp(formValue.email, formValue.password);
  }

  signIn() {
    const formValue = this.loginForm.value;
    this.authenticationService.SignIn(formValue.email, formValue.password);
  }

  signOut() {
    this.authenticationService.signOut();
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: '',
      password: ''
    });
  }
}
