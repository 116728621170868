import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Client } from '../../../shared/dbinterfaces';
import { FsService } from 'src/app/service/fs.service';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {
  editClientForm: FormGroup;

  // Form state
  loading = false;
  success = false;

  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private fsf: FsService, private afs: AngularFirestore, public dialogRef: MatDialogRef<EditClientComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.editClientForm = this.fb.group({
      name: this.data.name,
      idnumber: this.data.idnumber,
      address1: this.data.address1,
      address2: this.data.address2,
      address3: this.data.address3,
      country: this.data.country,
      contacts: this.fb.array([])
    });

    for (let index = 0; index < this.data.contacts.length; index++) {
      const element = this.data.contacts[index];
      const contact = this.fb.group({
        contactname: element.contactname,
        contactemail: element.contactemail,
        contactphone: element.contactphone
      });
      this.contactForms.push(contact);
    }
  }

  async submitHandler() {
    this.loading = true;
    const formValue = this.editClientForm.value;
    try {
      await this.fsf.editClient(formValue, this.data.id);
      this.success = true;
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    if (this.loading === false && this.success === true) {
      this.dialogRef.close();
    }
  }

  updateClient() {
    delete this.editClientForm.value.id;
    this.afs
      .collection('clients')
      .doc(this.data.id)
      .update(this.editClientForm.value);
  }

  get contactForms() {
    return this.editClientForm.get('contacts') as FormArray;
  }

  addContact() {
    const contact = this.fb.group({
      contactname: [],
      contactemail: [],
      contactphone: []
    });

    this.contactForms.push(contact);
  }

  deleteContact(i) {
    this.contactForms.removeAt(i);
  }
}
