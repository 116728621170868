import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { FsService } from '../../../service/fs.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-issuer',
  templateUrl: './edit-issuer.component.html',
  styleUrls: ['./edit-issuer.component.css']
})
export class EditIssuerComponent implements OnInit {
  editIssuerForm: FormGroup;

  // Form state
  loading = false;
  success = false;
  ktregex = '^[0-9]{6}-[0-9]{4}';

  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private fsf: FsService, public dialogRef: MatDialogRef<EditIssuerComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.editIssuerForm = this.fb.group({
      name: [this.data.name, Validators.required],
      email: [this.data.email, Validators.email],
      servicetype: ['', Validators.required],
      issuerid: [{ value: this.data.issuerid, disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[0-9]{6}-[0-9]{4}')])]
    });
    this.editIssuerForm.patchValue(this.data);
  }

  async submitHandler() {
    this.loading = true;
    const formValue = this.editIssuerForm.value;
    try {
      await this.fsf.updateIssuer(formValue, this.data.id);
      this.success = true;
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    if (this.loading === false && this.success === true) {
      this.dialogRef.close();
    }
  }
}
