<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlex="auto"><h1>Notendur</h1></div>
    <div fxFlex="grow" style="text-align:center">
      <mat-form-field style="width:80%">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Leit" />
      </mat-form-field>
    </div>
  </div>

  <mat-table #table [dataSource]="dataSource" class="animate">
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef> Netfang</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="displayName">
      <mat-header-cell *matHeaderCellDef> Nafn</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.displayName }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
      <mat-header-cell *matHeaderCellDef> Sími</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.phoneNumber }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="uid">
      <mat-header-cell *matHeaderCellDef> User Id</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.uid }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef> Réttindi</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.role }} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row class="highlight" *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[50, 100]" showFirstLastButtons></mat-paginator>
</mat-card>
