import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { Fault, Issuer, Case, Invoices } from '../shared/dbinterfaces';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class FsService implements OnDestroy {
  constructor(private afs: AngularFirestore, private storage: AngularFireStorage, private http: HttpClient) {}

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  // Subscriptions
  setInvoiceAsInvalidSub: Subscription;
  setInvoiceAsUnusableSub: Subscription;
  copyDocFromInvalidSub: Subscription;
  moveDocToUnusableSub: Subscription;
  prepareInvalidInvoiceForEmailSub: Subscription;
  setInvoiceasEmailedSub: Subscription;
  uploadSub: Subscription;
  moveCaseToSubmittedSub: Subscription;
  uploadTaskSub: Subscription;
  moveDocFromUnusableToInboxSub: Subscription;
  moveDocFromUnusableToDatacollectionSub: Subscription;
  setInvoiceInInboxAsInvalidSub: Subscription;
  moveDocFromDatacollectionsentToUnsuccessfullSub: Subscription;
  moveDocFromDatacollectionToInvalidSub: Subscription;
  moveDocFromDatacollectionSentToInvalidSub: Subscription;
  moveDocFromDatacollectionSentToDatacollectionSuccesfullSub: Subscription;
  docFromDatacollectionSub: Subscription;

  async push(db, data) {
    try {
      await this.afs.collection(db).add(data);
    } catch (error) {
      console.error(error);
    }
  }

  createXlsx(data, name) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');

    const opts: XLSX.WritingOptions = {
      type: 'buffer',
      cellDates: false,
      bookSST: false,
      bookType: 'xlsx',
      sheet: 'Sheet1',
      compression: false,
      Props: {
        Title: name,
        Author: 'Lögmenn Kópavogi'
      }
    };

    /* save to file */
    XLSX.writeFile(wb, name + '.xlsx', opts);
  }

  updateClientNumbers(clientId: string, property: string, change: number) {
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .valueChanges();
    const doc = obs.pipe(take(1));
    doc.subscribe(res => {
      const currentvalue = res[property];
      const newValue = currentvalue + change;
      const value = {};
      value[property] = newValue;
      this.afs
        .collection('clients')
        .doc(clientId)
        .update(value);
    });
  }

  async deleteCase(clientId: string, caseId: string) {
    try {
      await this.afs
        .collection('clients')
        .doc(clientId)
        .collection('cases')
        .doc(caseId)
        .delete();
    } catch (error) {}
  }

  async createCase(clientId: string, data: Case) {
    try {
      await this.afs
        .collection('clients')
        .doc(clientId)
        .collection('cases')
        .add(data);
    } catch (error) {
      console.error(error);
    }
  }

  //Issuer data connections

  getFile(url) {
    return this.http.get(url);
  }

  async newIssuer(data: Issuer) {
    try {
      await this.afs.collection('issuers').add(data);
    } catch (error) {}
  }

  async updateIssuer(data: Issuer, id: string) {
    try {
      await this.afs
        .collection('issuers')
        .doc(id)
        .update(data);
    } catch (error) {}
  }

  async updateFault(data: Issuer, id: string) {
    try {
      await this.afs
        .collection('faults')
        .doc(id)
        .update(data);
    } catch (error) {}
  }

  getInboxCollection(clientId: string) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('inbox')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getIssuersCollection() {
    return this.afs
      .collection('issuers')
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  UnusableCollection(clientId) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('unusable')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getClientsCollection() {
    return this.afs
      .collection('clients', ref => ref.orderBy('name'))
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  getAffiliatesCollection() {
    return this.afs
      .collection('users', ref => ref.orderBy('displayName'))
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  async newFault(data: Fault) {
    try {
      await this.afs.collection('faults').add(data);
    } catch (error) {}
  }

  getFaultsCollection() {
    return this.afs
      .collection('faults')
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  getSubmittedCasesCollection(clientId) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases', ref => ref.where('status', '==', 'submittedCase').orderBy('periodstart'))
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  getClosedCasesCollection(clientId) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases', ref => ref.where('status', '==', 'closedCase').orderBy('periodstart'))
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  deleteIssuer(id: string) {
    this.afs
      .collection('issuers')
      .doc(id)
      .delete();
  }

  deleteFault(id: string) {
    this.afs
      .collection('faults')
      .doc(id)
      .delete();
  }

  async editClient(data: any, id: string) {
    try {
      await this.afs
        .collection('clients')
        .doc(id)
        .set(data, { merge: true });
    } catch (error) {}
  }

  getClientNotSubmittedCases(clientId: string) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases', ref => ref.where('status', '==', 'notSubmitted'))
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  getAllClients2(searchString1, searchString2?) {
    return this.afs
      .collection('clients', ref =>
        ref
          .orderBy(searchString1, 'desc')
          .orderBy(searchString2, 'desc')
          .limit(5)
      )
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  getAllClients(searchString1) {
    return this.afs
      .collection('clients', ref => ref.orderBy(searchString1, 'desc'))
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  addInvoiceToHakkavelWorkList(data: { clientId: string; docId: string; done: boolean }) {
    this.afs
      .collection('hakkavel')
      .add(data)
      .then((docRef: DocumentReference) => {});
  }

  removeInvoiceFromHakkavelWorkList(InboxId) {
    const hakkaveldoc = this.findHakkavelDoc(InboxId);
    hakkaveldoc.pipe(take(1)).subscribe((res: any) => {
      this.afs
        .collection('hakkavel')
        .doc(res[0].id)
        .delete();
    });
  }

  updateInvoiceInHakkavelWorkList(id, data) {
    this.afs
      .collection('hakkavel')
      .doc(id)
      .update(data);
  }

  getHakkavelWorkList() {
    return this.afs
      .collection('hakkavel', ref => ref.where('done', '==', false))
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  getHakkavelCount() {
    return this.afs
      .collection('hakkavel', ref => ref.where('done', '==', false))
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.length;
        })
      );
  }

  getClient(clientId: string) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .valueChanges();
  }

  getInboxInvoiceDoc(clientId: string, docId: string) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('inbox')
      .doc(docId)
      .valueChanges();
  }

  getCase(clientId: string, caseId: string) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .valueChanges();
  }

  updateCase(clientId: string, caseId: string, data: any) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .update(data);
  }

  addInvoiceToCase(clientId: string, caseId: string, data: any) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .collection('invoices')
      .add(data);
  }

  addInvoiceToInbox(clientId: string, data: any) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('inbox')
      .add(data)
      .then((docRef: DocumentReference) => {
        const data = { clientId: clientId, docId: docRef.id, done: false };
        this.addInvoiceToHakkavelWorkList(data);
      });
  }

  updateAllInvoicesPdf(clientId: string, caseId: string, data: any) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .update({ allInvoicesPdfUrl: firebase.firestore.FieldValue.arrayUnion(data) });
  }

  setWorkingOnCombiningInvoices(clientId, caseId, array: Array<any>) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .update({ combiningInvoices: array });
  }

  async updateInboxInvoice(clientId: string, docId: string, data: Invoices) {
    try {
      await this.afs
        .collection('clients')
        .doc(clientId)
        .collection('inbox')
        .doc(docId)
        .update(data);
    } catch (error) {
      console.error(error);
    }
  }

  async updateInboxInvoiceHakkavel(clientId: string, docId: string, data: any) {
    const update = { hakkavel: data };
    try {
      await this.afs
        .collection('clients')
        .doc(clientId)
        .collection('inbox')
        .doc(docId)
        .update(update);
    } catch (error) {
      console.error(error);
    }
  }

  async confirmInboxInvoice(clientId, docId, data, caseId) {
    try {
      await this.afs
        .collection('clients')
        .doc(clientId)
        .collection('cases')
        .doc(caseId)
        .collection('invoices')
        .add(data);
    } catch (error) {
      console.error(error);
    }
    try {
      await this.afs
        .collection('clients')
        .doc(clientId)
        .collection('inbox')
        .doc(docId)
        .delete();
    } catch (error) {
      console.error(error);
    }
  }

  sendInvoiceFromInboxToDatacollection(clientId, docId) {
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .collection('inbox')
      .doc(docId)
      .valueChanges();
    const doc = obs.pipe(take(1));
    this.setInvoiceInInboxAsInvalidSub = doc.subscribe((res: Invoices) => {
      res.datacollectionStatus = 'InfoNeeded';
      res.invoicenumber = '';
      this.afs
        .collection('clients')
        .doc(clientId)
        .collection('datacollection')
        .add(res)
        .then(value => {
          this.afs
            .collection('clients')
            .doc(clientId)
            .collection('inbox')
            .doc(docId)
            .delete();
        });
    });
  }

  getInvoicesFromDatacollection(clientId, status) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection', ref => ref.where('datacollectionStatus', '==', status).orderBy('issuer'))
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  getInvoicesFromDatacollectionSent(clientId, status) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection', ref => ref.where('datacollectionStatus', '==', status).orderBy('replyBeforeDate'))
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  setInvoiceInInboxAsUnusable(clientId, docId) {
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .collection('inbox')
      .doc(docId)
      .valueChanges();
    const doc = obs.pipe(take(1));
    this.setInvoiceAsUnusableSub = doc.subscribe(res => {
      this.afs
        .collection('clients')
        .doc(clientId)
        .collection('unusable')
        .add(res)
        .then(value => {
          this.afs
            .collection('clients')
            .doc(clientId)
            .collection('inbox')
            .doc(docId)
            .delete();
        });
    });
  }

  moveDocFromDatacollectionToInbox(clientId, docId) {
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection')
      .doc(docId)
      .valueChanges();
    const doc = obs.pipe(take(1));
    this.copyDocFromInvalidSub = doc.subscribe(res => {
      this.afs
        .collection('clients')
        .doc(clientId)
        .collection('inbox')
        .add(res)
        .then((docRef: DocumentReference) => {
          const data = { clientId: clientId, docId: docRef.id, done: false };
          this.addInvoiceToHakkavelWorkList(data);
          this.afs
            .collection('clients')
            .doc(clientId)
            .collection('datacollection')
            .doc(docId)
            .delete();
        });
    });
  }

  async datacollectionSuccessfull(clientId, docId, date) {
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection')
      .doc(docId)
      .valueChanges();
    const doc = obs.pipe(take(1));
    this.docFromDatacollectionSub = await doc.subscribe((res: any) => {
      if (res.datacollectionStatus == 'InfoNeeded') {
        this.updateClientNumbers(clientId, 'datacollection_counter', -1);
      }
      if (res.datacollectionStatus == 'datacollectionSent') {
        this.updateClientNumbers(clientId, 'datacollectionSent_counter', -1);
      }
      if (res.datacollectionStatus == 'ReadyForGmail') {
        this.updateClientNumbers(clientId, 'datacollectionReadyForGmail_counter', -1);
      }
    });

    this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection')
      .doc(docId)
      .update({ receivedTime: date, datacollectionStatus: 'DatacollectionSuccessfull' });
  }

  datacollectionUnsuccessfull(clientId, docId, reasonenglish, reasonicelandic, cancelTime) {
    this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection')
      .doc(docId)
      .update({
        unusablereasonenglish: reasonenglish,
        unusablereasonicelandic: reasonicelandic,
        datacollectionStatus: 'DatacollectionUnsuccessfull',
        cancelTime: cancelTime
      });
  }

  moveDocFromDatacollectionToInvalid(clientId, docId) {
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection')
      .doc(docId)
      .valueChanges();
    const doc = obs.pipe(take(1));
    this.moveDocFromDatacollectionToInvalidSub = doc.subscribe(res => {
      this.afs
        .collection('clients')
        .doc(clientId)
        .collection('invalid')
        .add(res)
        .then(value => {
          this.afs
            .collection('clients')
            .doc(clientId)
            .collection('datacollection')
            .doc(docId)
            .delete();
        });
    });
  }

  restartDatacollection(clientId, docId) {
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection')
      .doc(docId)
      .update({ datacollectionStatus: 'InfoNeeded' });
  }

  moveDocFromCaseToInbox(clientId, caseId, docId) {
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .collection('invoices')
      .doc(docId)
      .valueChanges();
    const doc = obs.pipe(take(1));
    this.copyDocFromInvalidSub = doc.subscribe(res => {
      this.afs
        .collection('clients')
        .doc(clientId)
        .collection('inbox')
        .add(res)
        .then((docRef: DocumentReference) => {
          const data = { clientId: clientId, docId: docRef.id, done: false };
          this.addInvoiceToHakkavelWorkList(data);
          this.afs
            .collection('clients')
            .doc(clientId)
            .collection('cases')
            .doc(caseId)
            .collection('invoices')
            .doc(docId)
            .delete();
        });
    });
  }

  moveDocFromUnusableToInbox(clientId, docId) {
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .collection('unusable')
      .doc(docId)
      .valueChanges();
    const doc = obs.pipe(take(1));
    this.moveDocFromUnusableToInboxSub = doc.subscribe(res => {
      this.afs
        .collection('clients')
        .doc(clientId)
        .collection('inbox')
        .add(res)
        .then((docRef: DocumentReference) => {
          const data = { clientId: clientId, docId: docRef.id, done: false };
          this.addInvoiceToHakkavelWorkList(data);
          this.afs
            .collection('clients')
            .doc(clientId)
            .collection('unusable')
            .doc(docId)
            .delete();
        });
    });
  }

  moveDocFromUnusableToDatacollection(clientId, docId) {
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .collection('unusable')
      .doc(docId)
      .valueChanges();
    const doc = obs.pipe(take(1));
    this.moveDocFromUnusableToDatacollectionSub = doc.subscribe((res: Invoices) => {
      res.datacollectionStatus = 'InfoNeeded';
      this.afs
        .collection('clients')
        .doc(clientId)
        .collection('datacollection')
        .add(res)
        .then(value => {
          this.afs
            .collection('clients')
            .doc(clientId)
            .collection('unusable')
            .doc(docId)
            .delete();
        });
    });
  }

  moveDocFromDatacollectionToUnusable(clientId, docId) {
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection')
      .doc(docId)
      .valueChanges();
    const doc = obs.pipe(take(1));
    this.moveDocToUnusableSub = doc.subscribe(res => {
      this.afs
        .collection('clients')
        .doc(clientId)
        .collection('unusable')
        .add(res)
        .then(value => {
          this.afs
            .collection('clients')
            .doc(clientId)
            .collection('datacollection')
            .doc(docId)
            .delete();
        });
    });
  }

  getDefaultEmailText() {
    return this.afs
      .collection('settings')
      .doc('settings')
      .valueChanges();
  }

  async setDefaultEmailText(data) {
    try {
      await this.afs
        .collection('settings')
        .doc('settings')
        .set(data);
    } catch (error) {}
  }

  async setInvoiceAsReadyForEmail(clientId, docId, data: Invoices) {
    this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection')
      .doc(docId)
      .set(data, { merge: true });
  }

  async setDataCollectionEmailAsSent(clientId, docId, date, replydate) {
    await this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection')
      .doc(docId)
      .update({ sentTime: date, replyBeforeDate: replydate, datacollectionStatus: 'DatacollectionSent' });
  }

  deleteDocFromInbox(clientId, docId, path?) {
    this.afs
      .collection('clients')
      .doc(clientId)
      .collection('inbox')
      .doc(docId)
      .delete();

    this.removeInvoiceFromHakkavelWorkList(docId);

    if (path) {
      const fileRef = this.storage.ref(path);
      fileRef.delete();
    }
  }

  findHakkavelDoc(refdoc) {
    return this.afs
      .collection('hakkavel', ref => ref.where('docId', '==', refdoc))
      .snapshotChanges()
      .pipe(
        map(docArray => {
          return docArray.map(doc => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as {})
            };
          });
        })
      );
  }

  deleteDocFromCollection(clientId, collectionName, docId, path) {
    this.afs
      .collection('clients')
      .doc(clientId)
      .collection(collectionName)
      .doc(docId)
      .delete();

    const fileRef = this.storage.ref(path);
    fileRef.delete();
  }

  countDocs(clientId, collection) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection(collection)
      .valueChanges();
  }

  getCases(clientId) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getCaseInvoices(clientId, caseId) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .collection('invoices')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getSortedCaseInvoices(clientId, caseId) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .collection('invoices', ref => ref.orderBy('finalrefnumber'))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getAllCaseInvoices(clientId) {
    var allCases = [];
    var counter = 0;
    const obs = this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .snapshotChanges();
    obs.subscribe((a: any) => {
      for (let index = 0; index < a.length; index++) {
        const caseId = a[index].payload.doc.id;
        const temp = this.afs
          .collection('clients')
          .doc(clientId)
          .collection('cases')
          .doc(caseId)
          .collection('invoices')
          .snapshotChanges();
        temp.subscribe((b: any) => {
          for (let i = 0; i < b.length; i++) {
            const data = b[i].payload.doc.data();
            const id = b[i].payload.doc.id;
            const tempIndex = i;
            const test = caseId;
            const endresult = { id, caseId, tempIndex, ...data };
            allCases.push(endresult);
          }
        });
      }
    });
    return allCases;
  }

  getCaseInfo(clientId, caseId) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .valueChanges()
      .pipe(
        map(result => {
          return result;
        })
      );
  }

  async upload(event, data, clientId, filename, type) {
    const file = event.target.files[0];
    const filePath = '/' + data.name + '/documents/' + filename;
    const task = this.storage.upload(filePath, file);
    const fileRef = this.storage.ref(filePath);
    this.uploadPercent = task.percentageChanges();
    this.uploadTaskSub = task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.uploadSub = this.downloadURL.subscribe(res => {
            data[type] = res;
            this.afs
              .collection('clients')
              .doc(clientId)
              .set(data, { merge: true });
          });
        })
      )
      .subscribe();
  }

  getDatacollection(clientId) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('datacollection')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getUnusable(clientId) {
    return this.afs
      .collection('clients')
      .doc(clientId)
      .collection('unusable')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  updateInvoiceInCase(clientId, caseId, docId, data) {
    this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .collection('invoices')
      .doc(docId)
      .update(data);
  }

  updateClientDoc(clientId, data) {
    this.afs
      .collection('clients')
      .doc(clientId)
      .update(data);
  }

  updateCaseDoc(clientId, caseId, data) {
    this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .update(data);
  }

  async moveCaseToSubmitted(clientId, caseId) {
    const submitDate = moment(new Date()).format('DD.MM.YYYY');
    this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .update({ status: 'submittedCase', submitDate: submitDate, pdfProcessed: 0 });
  }

  async moveCaseToClosed(clientId, caseId) {
    const closedDate = moment(new Date()).format('DD.MM.YYYY');
    this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .update({ status: 'closedCase', closedDate: closedDate });
  }

  async moveCaseBackToSubmitted(clientId, caseId) {
    this.afs
      .collection('clients')
      .doc(clientId)
      .collection('cases')
      .doc(caseId)
      .update({ status: 'submittedCase' });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.uploadSub) this.uploadSub.unsubscribe();
    if (this.moveCaseToSubmittedSub) this.moveCaseToSubmittedSub.unsubscribe();
    if (this.moveDocToUnusableSub) this.moveDocToUnusableSub.unsubscribe();
    if (this.copyDocFromInvalidSub) this.copyDocFromInvalidSub.unsubscribe();
    if (this.setInvoiceAsInvalidSub) this.setInvoiceAsInvalidSub.unsubscribe();
    if (this.setInvoiceAsUnusableSub) this.setInvoiceAsInvalidSub.unsubscribe();
    if (this.prepareInvalidInvoiceForEmailSub) this.prepareInvalidInvoiceForEmailSub.unsubscribe();
    if (this.uploadTaskSub) this.uploadTaskSub.unsubscribe();
    if (this.setInvoiceasEmailedSub) this.setInvoiceasEmailedSub.unsubscribe();
    if (this.moveDocFromUnusableToInboxSub) this.moveDocFromUnusableToInboxSub.unsubscribe();
    if (this.moveDocFromUnusableToDatacollectionSub) this.moveDocFromUnusableToDatacollectionSub.unsubscribe();
    if (this.setInvoiceInInboxAsInvalidSub) this.setInvoiceInInboxAsInvalidSub.unsubscribe();
    if (this.moveDocFromDatacollectionsentToUnsuccessfullSub) this.moveDocFromDatacollectionsentToUnsuccessfullSub.unsubscribe();
    if (this.moveDocFromDatacollectionToInvalidSub) this.moveDocFromDatacollectionToInvalidSub.unsubscribe();
    if (this.moveDocFromDatacollectionSentToInvalidSub) this.moveDocFromDatacollectionSentToInvalidSub.unsubscribe();
    if (this.moveDocFromDatacollectionSentToDatacollectionSuccesfullSub) this.moveDocFromDatacollectionSentToDatacollectionSuccesfullSub.unsubscribe();
    if (this.docFromDatacollectionSub) this.docFromDatacollectionSub.unsubscribe();
  }
}
