import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { FsService } from 'src/app/service/fs.service';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent implements OnInit, OnDestroy {
  displayedColumns: { [key: string]: string } = {
    path: 'Skrá'
  };
  dataSource: MatTableDataSource<any>;
  clientId: string;
  inboxCollection: Observable<any>;

  tableTitle = 'Inbox';
  sizeOptions = ['25', '50'];

  constructor(private route: ActivatedRoute, public router: Router, private fsf: FsService) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('p1');

    this.inboxCollection = this.fsf.getInboxCollection(this.clientId);

    // Gather Observable of clients with ID
  }

  tableRowFunc(data) {
    this.router.navigate(['viewinvoice/', this.clientId, data.id]);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
