import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { AngularFirestore } from '@angular/fire/firestore';
import { NewCaseComponent } from '../new-case/new-case.component';
import { Observable } from 'rxjs/Observable';
import { FsService } from 'src/app/service/fs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-client',
  templateUrl: './view-client.component.html',
  styleUrls: ['./view-client.component.css']
})
export class ViewClientComponent implements OnInit, OnDestroy {
  clientId: string;
  client: {};
  clientDoc: Observable<any>;
  kidid = 'test';
  navigationSub: Subscription;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Firsttable
  Title1 = 'Opnar Umsóknir';
  displayedColumns1: { [key: string]: string } = {
    periodstart: 'Tímabil hefst',
    periodend: 'Tímabil lýkur',
    totalInvoices: 'Fjöldi reikninga',
    totalVatAmount: 'Samtals VSK ISK'
  };
  sizeOptions1 = ['5', '100'];
  TitleImageUrl1 = './assets/images/logko-logo.png';
  notSubmittedCases;
  notSubmittedCasesObs: Observable<any>;
  notSubmittedCasesSub: Subscription;
  button = { buttontext: ' Stofna', icon: 'email' };

  // Second table
  Title2 = 'Hjá RSK';
  displayedColumns2: { [key: string]: string } = {
    periodstart: 'Tímabil hefst',
    periodend: 'Tímabil lýkur',
    totalInvoices: 'Fjöldi reikninga',
    totalVatAmount: 'Samtals VSK ISK',
    submitDate: 'Innsent Dags'
  };
  submittedCasesObs: Observable<any>;
  submittedCasesSub: Subscription;
  submitted: {};
  sizeOptions2 = ['5', '10'];
  TitleImageUrl2 = './assets/images/rsk-logo.jpg';
  // optional button

  // Third table
  Title3 = 'Vinnslu lokið';
  TitleImageUrl3 = './assets/images/checkmark.png';
  displayedColumns3: { [key: string]: string } = {
    periodstart: 'Tímabil hefst',
    periodend: 'Tímabil lýkur',
    totalInvoices: 'Fjöldi reikninga',
    totalVatAmount: 'Samtals VSK ISK',
    submitDate: 'Innsent Dags',
    closingDate: 'Lokið Dags'
  };
  closedCasesObs: Observable<any>;
  closedCasesSub: Subscription;

  closed: {};
  sizeOptions3 = ['5', '10'];

  constructor(private route: ActivatedRoute, private afs: AngularFirestore, public dialog: MatDialog, public router: Router, private fsf: FsService) {
    this.navigationSub = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.clientId = e.url.substring(12);
        if (this.notSubmittedCasesSub) this.notSubmittedCasesSub.unsubscribe();
        if (this.submittedCasesSub) this.submittedCasesSub.unsubscribe();
        if (this.closedCasesSub) this.closedCasesSub.unsubscribe();
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    // Get id from Url
    this.clientId = this.route.snapshot.paramMap.get('p1');

    // Gather Observable of client with ID
    this.notSubmittedCasesObs = this.fsf.getClientNotSubmittedCases(this.clientId);
    this.notSubmittedCasesSub = this.notSubmittedCasesObs.subscribe(res => {
      this.notSubmittedCases = res;
    });

    this.submittedCasesObs = this.fsf.getSubmittedCasesCollection(this.clientId);
    this.submittedCasesSub = this.submittedCasesObs.subscribe(res => {
      this.submitted = res;
    });

    this.closedCasesObs = this.fsf.getClosedCasesCollection(this.clientId);
    this.closedCasesSub = this.submittedCasesObs.subscribe(res => {
      this.closed = res;
    });
  }

  viewSubmittedCase(row) {
    this.router.navigateByUrl('/submittedcase/' + this.clientId + '/' + row.id);
  }

  viewClosedCase(row) {
    this.router.navigateByUrl('/closedcase/' + this.clientId + '/' + row.id);
  }

  NewCase(): void {
    const newDialogRef = this.dialog.open(NewCaseComponent, { data: this.clientId });
  }

  viewCase(row): void {
    this.router.navigate(['case/', this.clientId, row.id]);
  }

  openInbox() {
    this.router.navigate(['inbox/', this.clientId]);
  }

  openUnusable() {
    this.router.navigate(['unusable/', this.clientId]);
  }

  openDataCollection() {
    this.router.navigate(['datacollection/', this.clientId]);
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.

    if (this.notSubmittedCasesSub) this.notSubmittedCasesSub.unsubscribe();
    if (this.submittedCasesSub) this.submittedCasesSub.unsubscribe();
    if (this.closedCasesSub) this.closedCasesSub.unsubscribe();
  }
}
