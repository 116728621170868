<div fxLayout="column">
  <h2>Breyta söluaðila</h2>
  <form [formGroup]="editIssuerForm" (ngSubmit)="submitHandler()">
    <mat-form-field class="item">
      <input matInput placeholder="Nafn" formControlName="name" />
    </mat-form-field>

    <mat-form-field class="item">
      <input matInput placeholder="Netfang" formControlName="email" />
    </mat-form-field>

    <mat-form-field class="item">
      <input matInput placeholder="Lýsing þjónustu" formControlName="servicetype" />
    </mat-form-field>

    <mat-form-field class="item">
      <input matInput placeholder="Kennitala" formControlName="issuerid" />
    </mat-form-field>

    <button class="submit-button" mat-raised-button color="primary" type="submit" [disabled]="editIssuerForm.invalid">
      Staðfesta
    </button>
  </form>
</div>
