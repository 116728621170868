<button mat-raised-button color="primary" (click)="fixCaseInfo()">fixCaseInfo()</button><br />
<button mat-raised-button color="primary" (click)="resetClientInfo()">resetClientInfo()</button><br />
<button mat-raised-button color="primary" (click)="unsubscribe()">unsubscribe()</button><br />
<button mat-raised-button color="primary" (click)="fixClientInfo()">fixClientInfo()</button>

<mat-card>
  <button mat-raised-button (click)="this.StartHakkavel()">Start hakkavel</button>

  Fjöldi eftir fyrir Hakkavel

  {{ this.counter | async }}
</mat-card>
