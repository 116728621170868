import { Component, OnInit, OnDestroy, Input, AfterViewInit, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { Currency, Invoices, Application, SelectedApplication, Issuer } from '../../../shared/dbinterfaces';
import { map, startWith, take } from 'rxjs/operators';
import { FsService } from 'src/app/service/fs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfirmDeleteInboxInvoiceComponent } from '../confirm-delete-inbox-invoice/confirm-delete-inbox-invoice.component';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.css']
})
export class InvoiceFormComponent implements OnInit, OnDestroy, OnChanges {
  // FormControls

  //Inputs

  @Input() currentIndex: number;
  @Input() clientId: string;
  @Input() currentInvoice: Invoices;
  @Input() pdfHasText: any;

  showExhangeRate: boolean;
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  storagePath: string;
  invoiceform: Invoices;
  InvoiceForm: FormGroup;
  currentValuesDoc: AngularFirestoreDocument<any>;
  currentValues: Observable<any>;

  currentInvoiceDoc: Observable<any>;
  inboxCollection: Observable<any>;
  casesCollection: Observable<any>;
  newData: Observable<any>;

  selectedIssuer: string;
  InvoiceFormSub: Subscription;

  invoiceInvalidButtonDisabled: boolean;
  formValue: Invoices;
  disableInvoiceNo: boolean = true;

  // Issuers collection
  issuersCollectionSub: Subscription;
  issuersCollection: Observable<any>;
  issuersArray: Array<Issuer>;

  // Found Invoice in datacollection
  datacollectionInvoiceId: String;
  datacollectionStatus: string;
  datacollectionInvoiceFound: boolean;

  // Found Invoice in Case
  doubleInvoiceId: string;
  doubleInvoiceIndex: number;
  doubleInvoiceCaseId: string;

  // Invalid Invoices collection
  datacollectionCollection: Observable<any>;
  datacollectionCollectionSub: Subscription;
  datacollection: Array<Invoices>;

  //Invoice Numbers from selected Application
  invoiceCollectionFromSelectedApplication: Observable<any>;
  invoiceCollectionFromSelectedApplicationSub: Subscription;
  invoiceNoArrayFromSelectedApplication: Array<any>;

  //Gather invoices from all cases
  invoicesFromAllCases: Array<any>;

  // Form state
  loading = false;
  success = false;

  // Datepicker Filter
  minDate = new Date(2016, 0, 1);
  maxDate: Date;

  //Subscriptions
  inboxCollectionSub: Subscription;

  casesCollectionSub: Subscription;

  currencies: Currency[] = [
    { value: 'ISK', viewValue: 'ISK' },
    { value: 'USD', viewValue: 'USD' },
    { value: 'EUR', viewValue: 'EUR' },
    { value: 'GBP', viewValue: 'GBP' },
    { value: 'SEK', viewValue: 'SEK' },
    { value: 'NOK', viewValue: 'NOK' },
    { value: 'DKK', viewValue: 'DKK' }
  ];

  applications: Application[] = [];
  selectedApplication: SelectedApplication;

  Moment = extendMoment(moment);

  constructor(private http: HttpClient, private fb: FormBuilder, private fsf: FsService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog, private snackBar: MatSnackBar) {
    // set maxDate
    const currentDate = new Date();
    var year = currentDate.getFullYear();
    this.maxDate = new Date(year, 11, 31);
    // Get all issuers and push into array
    this.issuersCollection = this.fsf.getIssuersCollection();
    this.issuersCollectionSub = this.issuersCollection.subscribe(res => {
      this.issuersArray = res;
      this.options = [];
      for (let index = 0; index < res.length; index++) {
        const element = res[index].name;
        this.options.push(element);
      }
    });
  }

  ngOnInit() {
    this.selectedIssuer = '';

    // pull all invalid invoices to check if we are processing a invoices that we have in datacollection process
    this.datacollectionCollection = this.fsf.getDatacollection(this.clientId);
    this.datacollectionCollectionSub = this.datacollectionCollection.subscribe(res => {
      this.datacollection = res;
    });

    // Náum í mögulegar umsóknir
    this.casesCollection = this.fsf.getClientNotSubmittedCases(this.clientId);
    this.casesCollectionSub = this.casesCollection.subscribe(res => {
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        const viewValue = element.periodstart + ' - ' + element.periodend;
        this.applications[index] = {
          viewValue: viewValue,
          value: element.id,
          periodStart: element.periodstart,
          periodEnd: element.periodend
        };
      }
    });

    // Get invoices from all applications for comparison
    this.invoicesFromAllCases = this.fsf.getAllCaseInvoices(this.clientId);

    // Formgroup Setup
    this.initiateForm();

    // Enable/disable Exchangerate input based on VAT Currency selection
    this.InvoiceFormSub = this.InvoiceForm.get('vatcurrency').valueChanges.subscribe(res => {
      if (res != 'ISK') {
        this.showExhangeRate = true;
        this.InvoiceForm.controls['exchangerate'].enable();
        this.InvoiceForm.controls['exchangerate'].setValidators(Validators.compose([Validators.required, Validators.pattern(/^\d+\,?\d*$/)]));
      } else {
        this.showExhangeRate = false;
        this.InvoiceForm.controls['exchangerate'].disable();
      }
    });

    this.filteredOptions = this.InvoiceForm.get('issuer').valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filter(value);
      })
    );
  }

  ngOnChanges() {
    if (this.InvoiceForm) {
      if (this.pdfHasText) {
        this.InvoiceForm.controls['refnumber'].setValue('Auto');
      } else {
        this.InvoiceForm.controls['refnumber'].setValue('');
      }
    }
    this.updateForm();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  vatcurChange(event) {
    if (event.value != 'ISK') {
      this.showExhangeRate = true;
      this.InvoiceForm.controls['exchangerate'].enable();
      this.InvoiceForm.controls['exchangerate'].setValidators(Validators.compose([Validators.required, Validators.pattern(/^\d+\,?\d*$/)]));
    } else {
      this.showExhangeRate = false;
      this.InvoiceForm.controls['exchangerate'].disable();
    }
  }

  confirmDeletePdfFromInbox() {
    const data = { clientId: this.clientId, collectionName: 'inbox', docId: this.currentInvoice.id, path: this.currentInvoice.path };
    const newDialogRef = this.dialog.open(ConfirmDeleteInboxInvoiceComponent, { data: data });
  }

  datePickerChange(date) {
    this.InvoiceForm.controls['invoicenumber'].setValue('');
    if (this.applications.length == 0) {
      this.InvoiceForm.controls['date'].setErrors({ noApplication: true });
    } else {
      let success = false;
      for (let index = 0; index < this.applications.length; index++) {
        const startdate = moment(this.applications[index].periodStart, 'DD.MM.YYYY').toDate();
        const enddate = moment(this.applications[index].periodEnd, 'DD.MM.YYYY').toDate();
        const range = this.Moment.range(startdate, enddate);
        if (range.contains(date)) {
          const viewValue = this.applications[index].periodStart + ' - ' + this.applications[index].periodEnd;
          this.selectedApplication = { viewValue: viewValue, value: this.applications[index].value };
          success = true;
          this.InvoiceForm.controls['date'].setErrors({ noApplication: null });
          this.InvoiceForm.controls['date'].updateValueAndValidity();
          this.InvoiceForm.controls['invoicenumber'].enable();
          this.InvoiceForm.controls['refnumber'].enable();
          this.invoiceCollectionFromSelectedApplication = this.fsf.getCaseInvoices(this.clientId, this.selectedApplication.value);
          this.invoiceNoArrayFromSelectedApplication = [];
          this.invoiceCollectionFromSelectedApplicationSub = this.invoiceCollectionFromSelectedApplication.subscribe(res => {
            for (let index = 0; index < res.length; index++) {
              const element = res[index];
              this.invoiceNoArrayFromSelectedApplication = res;
            }
          });
        }
        if (success === false) {
          this.InvoiceForm.controls['invoicenumber'].disable();
          //this.InvoiceForm.controls['amount'].disable();
          //this.InvoiceForm.controls['currency'].disable();
          //this.InvoiceForm.controls['vatamount'].disable();
          //this.InvoiceForm.controls['vatcurrency'].disable();
          //this.InvoiceForm.controls['exchangerate'].disable();
          this.InvoiceForm.controls['refnumber'].disable();
          this.InvoiceForm.controls['date'].setErrors({ noApplication: true });
          this.selectedApplication = null;
        }
      }
    }
  }

  async submithandler() {
    if (this.InvoiceForm.valid) {
      // Get all values
      let formValue = this.InvoiceForm.value;

      // Change numbers into US format
      formValue.amount = Math.round(Number(formValue.amount.replace(',', '.')) * 100) / 100;
      formValue.vatamount = Math.round(Number(formValue.vatamount.replace(',', '.')) * 100) / 100;
      if (formValue.exchangerate != undefined && formValue.exchangerate != null) {
        formValue.exchangerate = Math.round(Number(formValue.exchangerate.replace(',', '.')) * 100) / 100;
      }

      // fix date value
      formValue.date = moment(formValue.date).format('DD.MM.YYYY');

      // calculate iskvat
      if (formValue.vatcurrency != 'ISK') {
        formValue.iskvat = Math.floor(Math.round(formValue.vatamount * formValue.exchangerate * 100) / 100);
      } else {
        formValue.iskvat = formValue.vatamount;
      }

      // Make sure amounts are negative if CreditInvoice is selected
      if (formValue.creditInvoice == true) {
        if (formValue.amount > 0) {
          formValue.amount = formValue.amount * -1;
        }
        if (formValue.vatamount > 0) {
          formValue.vatamount = formValue.vatamount * -1;
        }
        if (formValue.iskvat > 0) {
          formValue.iskvat = formValue.iskvat * -1;
        }
      }

      // Get stuff that did not get stored into the form.
      formValue.downloadUrl = this.currentInvoice.downloadUrl;
      formValue.path = this.currentInvoice.path;

      // Check if the invoice is in datacollection and if so confirm as successfull
      if (this.datacollectionInvoiceId) {
        const receivedTime = moment(new Date()).format('DD.MM.YYYY');
        this.fsf.datacollectionSuccessfull(this.clientId, this.datacollectionInvoiceId, receivedTime);

        const message = 'Staðfesti að gagnaöflun á reikningi nr: ' + formValue.invoicenumber + ' tókst';
        this.openSnackBar(message, 'OK');
      }

      // submit to application
      this.fsf.confirmInboxInvoice(this.clientId, this.currentInvoice.id, formValue, this.selectedApplication.value);

      // reset Form
      this.resetForm();
    }
  }

  async invoiceInvalid() {
    this.invoiceInvalidButtonDisabled = true;
    this.fsf.sendInvoiceFromInboxToDatacollection(this.clientId, this.currentInvoice.id);
    this.fsf.updateClientNumbers(this.clientId, 'datacollection_counter', 1);
    this.resetForm();
    setTimeout(() => {
      this.invoiceInvalidButtonDisabled = false;
    }, 1500);
  }

  async invoiceUnusable() {
    this.fsf.setInvoiceInInboxAsUnusable(this.clientId, this.currentInvoice.id);
    this.resetForm();
  }

  issuerClick(value: string) {
    this.selectedIssuer = value.toLowerCase();
    this.InvoiceForm.controls['issuer'].setValue(value);
    this.InvoiceForm.controls['invoicenumber'].setValue('');
  }

  updateForm() {
    if (this.currentInvoice && this.currentInvoice.hakkavel && this.currentInvoice.hakkavel.length != 0) {
      if (this.issuersArray == undefined || this.currentInvoice.hakkavel[0].issuer == undefined || this.datacollection == undefined) {
        setTimeout(() => {
          this.runFormUpdate();
        }, 1750);
      } else {
        this.runFormUpdate();
      }
    }
  }

  runFormUpdate() {
    if (this.checkIfKt(this.currentInvoice.hakkavel[0].issuer) && this.currentInvoice.hakkavel[0].issuer != 'Afrit' && this.currentInvoice.hakkavel[0].issuer != 'Synishorn') {
      const issuerData = this.searchArray(this.currentInvoice.hakkavel[0].issuer, this.issuersArray);
      this.InvoiceForm.controls['issuer'].setValue(issuerData.name);
      this.InvoiceForm.controls['servicetype'].setValue(issuerData.servicetype);
      this.InvoiceForm.controls['date'].enable();
      if (this.currentInvoice.hakkavel[0].date) {
        const momentDate = moment(this.currentInvoice.hakkavel[0].date, 'DD/MM/YYYY');
        this.InvoiceForm.controls['date'].setValue(momentDate);
        this.datePickerChange(momentDate);
        this.InvoiceForm.controls['invoicenumber'].enable();
        if (this.currentInvoice.hakkavel[0].invoice_number) {
          this.InvoiceForm.controls['invoicenumber'].setValue(this.currentInvoice.hakkavel[0].invoice_number);
          this.invoiceNumberChange(this.currentInvoice.hakkavel[0].invoice_number);
        }
      }
      this.InvoiceForm.controls['amount'].setValue(String(this.currentInvoice.hakkavel[0].amount).replace('.', ','));
      this.InvoiceForm.controls['vatamount'].setValue(String(this.currentInvoice.hakkavel[0].amount_vat).replace('.', ','));
      this.InvoiceForm.controls['currency'].enable();
      this.InvoiceForm.controls['currency'].setValue(this.currentInvoice.hakkavel[0].currency);
      this.InvoiceForm.controls['vatcurrency'].enable();
      this.InvoiceForm.controls['vatcurrency'].setValue(this.currentInvoice.hakkavel[0].currency);

      if (this.currentInvoice.hakkavel[0].currency != 'ISK') {
        this.InvoiceForm.controls['exchangerate'].enable();
        this.InvoiceForm.controls['exchangerate'].setValue(String(this.currentInvoice.hakkavel[0].amount_vatExchange).replace('.', ','));
      } else {
        this.InvoiceForm.controls['exchangerate'].disable();
      }
      const event = { value: this.currentInvoice.hakkavel[0].currency };
      this.vatcurChange(event);
    }
  }

  searchArray(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].issuerid === nameKey) {
        return myArray[i];
      }
    }
  }

  sendPdf(serverUrl, pdfUrl): Observable<any> {
    const httpOptions = { reportProgress: true, headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/json', 'Access-Control-Allow-Origin': '*' }) };
    return this.http.post<any>(serverUrl, pdfUrl, httpOptions);
  }

  checkIssuer() {
    setTimeout(res => {
      if (!this.selectedIssuer || this.selectedIssuer.toLowerCase() !== this.InvoiceForm.controls['issuer'].value.toLowerCase()) {
        this.InvoiceForm.controls['issuer'].setValue('');
        this.selectedIssuer = '';
        this.InvoiceForm.controls['date'].disable();
        this.InvoiceForm.controls['invoicenumber'].setValue('');
      } else {
        this.InvoiceForm.controls['date'].enable();
        this.InvoiceForm.controls['invoicenumber'].setValue('');
        for (let index = 0; index < this.issuersArray.length; index++) {
          if (this.InvoiceForm.controls['issuer'].value.toLowerCase() == this.issuersArray[index].name.toLowerCase()) {
            this.InvoiceForm.controls['servicetype'].setValue(this.issuersArray[index].servicetype);
          }
        }
      }
    }, 150);
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.InvoiceForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  initiateForm() {
    this.InvoiceForm = this.fb.group({
      creditInvoice: new FormControl(''),
      refnumber: new FormControl({ value: 'Auto', disabled: true }, Validators.compose([Validators.required, Validators.pattern('([0-9]{1,5}|Auto)'), this.refNumberValidator.bind(this)])),
      issuer: new FormControl('', Validators.required),
      servicetype: new FormControl({ value: '', disabled: false }, Validators.required),
      invoicenumber: new FormControl({ value: '', disabled: true }, Validators.required),
      date: new FormControl({ value: moment, disabled: true }, Validators.required),
      amount: new FormControl({ value: '', disabled: false }, Validators.required),
      currency: new FormControl({ value: '', disabled: false }, Validators.required),
      vatamount: new FormControl({ value: '', disabled: false }, Validators.required),
      vatcurrency: new FormControl({ value: '', disabled: false }, Validators.required),
      exchangerate: new FormControl({ value: '', disabled: false }, Validators.compose([Validators.required, Validators.pattern('(?:[0-9]+,[0-9]{1,2}|[0-9]+)')]))
    });
  }

  public resetForm() {
    this.InvoiceForm.controls['creditInvoice'].setValue('');
    this.InvoiceForm.controls['refnumber'].setValue({ value: 'Auto', disabled: true });
    this.InvoiceForm.controls['refnumber'].setValidators(Validators.compose([Validators.required, Validators.pattern('([0-9]{1,5}|Auto)'), this.refNumberValidator.bind(this)]));
    this.InvoiceForm.controls['issuer'].setValue('');
    this.InvoiceForm.controls['issuer'].setValidators(Validators.required);
    this.InvoiceForm.controls['servicetype'].setValue('');
    this.InvoiceForm.controls['servicetype'].setValidators(Validators.required);
    this.InvoiceForm.controls['invoicenumber'].setValue('');
    this.InvoiceForm.controls['invoicenumber'].disable();
    this.InvoiceForm.controls['invoicenumber'].setValidators(Validators.required);
    this.InvoiceForm.controls['date'].setValue('');
    this.InvoiceForm.controls['date'].disable();
    this.InvoiceForm.controls['date'].setValidators(Validators.required);
    this.InvoiceForm.controls['amount'].setValue('');
    this.InvoiceForm.controls['amount'].setValidators(Validators.required);
    this.InvoiceForm.controls['currency'].setValue('');
    this.InvoiceForm.controls['currency'].setValidators(Validators.required);
    this.InvoiceForm.controls['vatamount'].setValue('');
    this.InvoiceForm.controls['vatamount'].setValidators(Validators.required);
    this.InvoiceForm.controls['vatcurrency'].setValue('');
    this.InvoiceForm.controls['vatcurrency'].setValidators(Validators.required);
    this.InvoiceForm.controls['exchangerate'].setValue('');
    this.InvoiceForm.controls['exchangerate'].setValidators(Validators.compose([Validators.required, Validators.pattern('(?:[0-9]+,[0-9]{1,2}|[0-9]+)')]));
    this.InvoiceForm.markAsPristine();
    this.InvoiceForm.markAsUntouched();
    this.selectedIssuer = '';
    this.datacollectionInvoiceId = null;
    this.selectedApplication = null;
    this.invoiceNoArrayFromSelectedApplication = [];
    this.showExhangeRate = true;
  }

  invoiceNumberChange(value) {
    const InvoiceNo = value;
    const issuer = this.InvoiceForm.controls['issuer'].value;

    for (let index = 0; index < this.datacollection.length; index++) {
      const element = this.datacollection[index];
      if (element.issuer == issuer && element.invoicenumber == InvoiceNo) {
        this.datacollectionInvoiceId = element.id;
        this.datacollectionInvoiceFound = true;
      }
    }
    if (this.datacollectionInvoiceFound == false) {
      this.datacollectionInvoiceId = null;
    }
    this.datacollectionInvoiceFound = false;

    for (let index = 0; index < this.invoicesFromAllCases.length; index++) {
      const element = this.invoicesFromAllCases[index];

      if (element.issuer == issuer && element.invoicenumber == InvoiceNo) {
        this.InvoiceForm.controls['invoicenumber'].setValue('');
        this.InvoiceForm.controls['invoicenumber'].setErrors({ exists: true });
        this.doubleInvoiceIndex = element.tempIndex;
        this.doubleInvoiceCaseId = element.caseId;
      }
    }
  }

  checkIfKt(input: string) {
    const re = new RegExp('[0-9]{6}-[0-9]{4}');
    return re.test(input);
  }

  refNumberValidator(control: AbstractControl) {
    if (control.value == '') {
      return null;
    }
    if (control.value) {
      if (this.pdfHasText == false && control.value == 'Auto') {
        return { pdfIsScan: true };
      }
      for (let index = 0; index < this.invoiceNoArrayFromSelectedApplication.length; index++) {
        const element = this.invoiceNoArrayFromSelectedApplication[index];
        if (element.refnumber == control.value && control.value != 'Auto') {
          return { exists: true };
        }
      }
    }
  }

  cutPdf() {
    this.router.navigateByUrl('pdfcutter/' + this.clientId + '/' + this.currentInvoice.id);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000
    });
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors = control instanceof FormGroup || control instanceof FormArray ? this.getAllErrors(control) : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.inboxCollectionSub) {
      this.inboxCollectionSub.unsubscribe();
    }
    if (this.InvoiceFormSub) {
      this.InvoiceFormSub.unsubscribe();
    }
    if (this.issuersCollectionSub) {
      this.issuersCollectionSub.unsubscribe();
    }
    if (this.casesCollectionSub) {
      this.casesCollectionSub.unsubscribe();
    }
    if (this.invoiceCollectionFromSelectedApplicationSub) {
      this.invoiceCollectionFromSelectedApplicationSub.unsubscribe();
    }
    if (this.datacollectionCollectionSub) this.datacollectionCollectionSub.unsubscribe();
  }
}
